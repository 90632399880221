.body-font {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
}
.body-font-light {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
}
.body-font-small {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
}
.initial-load-error,
.initial-load-error-widget {
  opacity: 0;
  display: none;
  visibility: hidden;
  transition: all .6s ease;
  width: 80%;
  margin: 5% auto;
  text-align: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 15px;
  max-width: 600px;
}
.initial-load-error p,
.initial-load-error-widget p {
  padding: 2.63157895% 0;
}
.initial-load-error.active,
.initial-load-error-widget.active {
  display: block;
  visibility: visible;
  opacity: 1;
}
.mission-heading {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}
.mission-title {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  font-size: 1rem;
  text-transform: none;
  color: #9ba1b3;
  letter-spacing: 0;
  margin: 10px 0 0;
  font-weight: 100;
}
.h1-font {
  font: 600 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
  font-size: 1.5rem;
}
.h2-font {
  font: 300 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
  font-size: 1.25rem;
}
.h3-font {
  font: 300 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
}
.h4-font {
  font: 600 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
}
.h5-font {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
}
.h6-font {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
}
.module-header-font {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
}
h1 {
  font: 600 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
  font-size: 1.5rem;
}
h2 {
  font: 300 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
  font-size: 1.25rem;
}
h3 {
  font: 300 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
}
h4 {
  font: 600 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
}
h5 {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
}
h6 {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
}
small {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
}
html {
  margin: 0 auto;
  font-size: 100%;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  background-color: white;
}
body {
  background-color: transparent;
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
* {
  outline: 0 !important;
}
p {
  font-weight: 300;
}
input::-ms-clear {
  display: none;
}
a:focus,
a:link {
  text-decoration: none;
}
a {
  text-decoration: none;
  color: #1ea182;
  cursor: pointer;
}
a:hover {
  color: #1ea182;
  text-decoration: none;
}
*[ng-click] {
  cursor: pointer;
}
.widget-margins {
  margin: 1rem 0.75rem !important;
}
.widget-margins.close-frame {
  margin-top: 58px !important;
}
.relative-position {
  position: relative !important;
}
#app-wrapper {
  margin: auto;
}
#app-wrapper.fullscreen {
  box-shadow: none;
}
#beacon-container {
  opacity: 0;
}
.max-width {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}
.compensated-margins {
  margin-top: 1.5rem;
}
.no-left {
  left: 0 !important;
}
.auto-width {
  width: auto !important;
}
.align-left {
  text-align: left !important;
}
.primary-link {
  color: #ffc411 !important;
}
.secondary-link {
  color: #1ea182;
}
div#loading {
  transition: opacity .5s;
  opacity: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 11111111;
  left: 0;
  top: 0;
  background-image: url('../imgs/working.svg');
  background-size: 256px, 128px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f0f0f6;
}
div#loading.loading {
  opacity: 1;
}
div#loading.no-widget {
  background-image: url('../imgs/working.svg'), url('../imgs/monj-icon.svg');
}
.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.6);
  left: 0;
  top: 0;
}
.loading-overlay .loading-icon {
  position: relative;
  margin: 0 auto;
  text-align: center;
  top: 45%;
  z-index: 9;
}
.transparent {
  opacity: 0.2;
}
.error-msg {
  color: #ff0000;
}
.dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 194, 217, 0.25);
  z-index: 1 !important;
  background-image: url(../imgs/working.svg);
  background-size: 128px;
  background-position: center center;
  background-repeat: no-repeat;
}
.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.text-ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*
.aspect-content {
  position: absolute;
  z-index: 3;
  top:0px;
  bottom:0;
  left:0;
  right:0;
}*/
.aspect:before {
  content: "";
  display: block;
}
.ratio1_1:before {
  padding-top: 100%;
}
.ratio2_1:before {
  padding-top: 50%;
}
.ratio8_1:before {
  padding-top: 12.25%;
}
.ratio1_2:before {
  padding-top: 200%;
}
.ratio4_3:before {
  padding-top: 75%;
}
.ratio16_9:before {
  padding-top: 56.25%;
}
.ratioHero:before {
  padding-top: 75%;
}
article h1 {
  margin-bottom: 1rem;
}
article h2 {
  margin-bottom: 1rem;
}
article h3 {
  margin-bottom: .25rem;
}
article h4 {
  margin-bottom: .5rem;
}
article h5 {
  margin-bottom: .5rem;
}
article h6 {
  margin-bottom: 0;
}
article p {
  margin-bottom: 1rem;
}
article ol {
  margin-left: 48px;
  margin-bottom: 1rem;
  list-style: decimal;
}
article ul {
  margin-left: 48px;
  margin-bottom: 1rem;
  list-style: disc;
}
strong {
  font-weight: 600;
  color: #363b47;
}
em {
  font-weight: 300;
  font-style: italic;
}
em.light {
  color: #9ba1b3;
}
.padding-right {
  padding-right: 1rem;
}
.action-container {
  position: relative;
  text-align: center;
  display: inline-block;
  padding: 0 0.5rem;
}
.action-container:first-child {
  padding-left: 0;
}
.action-container:last-child {
  padding-right: 0;
}
.action-container .action-icon {
  position: relative;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.action-container .action-icon .selected {
  width: 32px;
  height: 32px;
  visibility: hidden;
  animation: quick-fade-out 0.8s ease;
  transition: all .8s ease;
}
.action-container .action-icon .selected.active {
  visibility: visible;
  animation: quick-fade-in 0.2s ease;
}
.action-container .action-icon .selected.animate-up {
  animation: move-up 0.4s ease;
  transform: translate(0px, 0px);
  opacity: 0;
  transition: all .4s ease;
}
.action-container .action-icon.thin-share-icon {
  width: 44px;
}
.action-container .action-icon p {
  color: #1ea182;
}
.action-container p {
  margin: 0;
  padding-top: 0.25rem;
  color: #1ea182;
}
.action-list {
  display: inline-block;
  float: right;
  /* I don't like floats, but it seems like the best way to pull this one off - JBB */
  position: relative;
  top: -8px;
}
.action-list .action-container {
  text-align: right;
}
.action-list .action-container .action-icon {
  vertical-align: middle;
  text-align: center;
}
.action-list .action-container p {
  vertical-align: middle;
  padding-right: 0;
  padding-top: 0;
  text-align: center;
  min-width: 48px;
}
.back-arrow {
  height: 1.2rem !important;
  width: 1.2rem !important;
}
.back-text {
  padding-left: 0px;
  padding-top: 0px;
  font-size: 1rem;
  line-height: 1rem;
  display: inline-block;
  position: relative;
  right: 15px;
  vertical-align: top;
  top: 1px;
}
.back-text.black {
  color: black;
}
/*************************************************
 * Is this necessary?
 */
.img-flip-hor {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.container-out {
  box-shadow: none;
  margin: 1.5rem .75rem;
  margin-top: 0;
  padding: 0;
  background-color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.container-out-flipper {
  background-color: transparent;
  overflow: visible;
}
.container-out .container-in {
  padding: 2.63157895%;
  padding-bottom: 0;
  margin-bottom: 2.63157895%;
}
.container-out article.container-in {
  padding: 5.26315789%;
  padding-bottom: 0;
}
.container-out.full-bleed {
  margin: 0 0 2.5% 0;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.container-out.aspect .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.container-out header {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
  font-size: 1rem;
  background-color: #f0f0f6;
  padding: 1rem;
}
.container-out header a {
  display: block;
}
.container-out header a .material-icons {
  font-size: 1.5rem;
  float: left;
  margin: -0.15rem 0 0 -0.25rem;
}
.container-out > h1 {
  padding: 2.63157895%;
  margin: 0;
}
.container-out.first {
  margin-top: 2.63157895%;
}
.container-out.resouorce-library header {
  font: 400 0.75rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
  background-color: #f0f0f6;
  padding: 2.63157895%;
  text-align: left;
  height: inherit;
}
.container-out.resouorce-library footer {
  text-align: left;
  background-color: #ffffff;
}
.container-out.resouorce-library footer a {
  background-image: url('../imgs/plus-grey.svg');
  background-position: left center;
  padding-right: inherit;
  padding-left: 32px;
  background-size: auto 24px;
  line-height: 2rem;
}
.container-out.resouorce-library footer a#show-all {
  display: inherit;
}
.container-out.resouorce-library footer a#show-less {
  display: none;
}
.container-out.resouorce-library.expanded footer > a {
  background-image: url('../imgs/minus-grey.svg');
}
.container-out.resouorce-library.expanded footer > a#show-all {
  display: none;
}
.container-out.resouorce-library.expanded footer > a#show-less {
  display: inherit;
}
.container-out.resouorce-library .container-in {
  padding-top: 0;
  padding-bottom: 0;
}
.container-out.resouorce-library .container-in .dict-entry {
  padding-bottom: 0;
}
/*.help-form {
  margin-bottom: 10px;

  input {
    border-radius: 4px !important;
  }

  textarea {
    border-radius: 4px !important;
    min-height: 89px;
    width: 100% !important;
  }
}

.select-a-topic {
  height: 40px;
  border: 1px solid @mid-gray;
  border-radius: 4px !important;
  margin-bottom: 10px;
  cursor: pointer;

  .help-dd-icon {
    position: absolute;
    right: 1rem;
    top: .75rem;
    color: #a9a9a9;
  }

  p {
    margin: 0;
    padding: 9px;
    color: #a9a9a9;
  }

  &.dd {
    margin-bottom: 0px;

    p {
      padding-left: ~"calc(1rem + 9px)";
    }

    p:hover {
      background-color: @monj-green;
      color: white;
    }
  }

  &.dd:last-child {
    margin-bottom: 10px;
  }
}

.select-dd {
  max-height: 0px;
  transition: all .4s;
  opacity: 0;
  z-index: -100;
  margin-top: -10px;

  &.expanded {
    max-height: 1000px;
    opacity: 1;
    z-index: 1;
  }
}

.right-all {
  text-align: right;

  .attach-file-link {
    position: relative;
    top: 10px;
  }

  .butt {
    position: relative !important;
    left: 7px !important;
  }
}*/
.ugc-like p {
  text-align: center;
  top: 6px;
}
.ugc-carousel .ugc-moderators-comment p {
  height: 34px;
}
.widget .drilldown,
.widget.drilldown {
  margin: 1rem;
}
.widget .internal-container-margins {
  margin: 1.5rem;
}
.journey-post-tools {
  display: inline-block;
  vertical-align: bottom;
  width: 59%;
}
.journey-post-tools div,
.journey-post-tools p {
  display: inline-block;
  vertical-align: middle;
}
.journey-post-tools p {
  margin-right: 2rem;
  color: #9ba1b3;
}
.journey-post-tools p.insp-cam {
  margin-right: 0;
}
.journey-post-tools .md-button {
  cursor: pointer;
}
.drilldown {
  margin: 58px 2.63157895% 0;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.drilldown footer {
  text-align: center;
  padding-bottom: 4px;
}
.drilldown footer .material-icons {
  float: none;
  margin: 0;
}
.drilldown > header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4000;
  /*.drop-shadow();*/
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.25;
  text-align: center;
  background-color: #1ea182;
  height: 48px;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0 48px !important;
}
.drilldown > header span {
  display: block !important;
  padding: 0.8rem;
}
.drilldown > header a {
  color: #ffffff;
  display: block;
  text-decoration: none;
}
.drilldown > header a:hover {
  text-decoration: none;
}
/* contains content within #app-wrapper
.scroll-content {
  overflow: hidden;
}*/
.go-back-btn-container {
  width: 300px;
  position: relative;
  padding: 1rem 1rem calc(0.5rem);
  color: #1ea182;
}
.go-back-btn-container .back-btn {
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom-right-radius: 4px;
}
.go-back-btn-container.fixed {
  position: fixed;
}
.close-btn,
.back-btn {
  width: 48px;
  height: 48px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0;
  opacity: .9;
  pointer-events: auto;
}
.close-btn:hover,
.back-btn:hover {
  opacity: 1;
}
.close-btn.align-right,
.back-btn.align-right {
  top: 10px;
  right: 10px;
  left: auto;
}
.close-btn.search-box,
.back-btn.search-box {
  top: 0px;
  width: 40px;
  height: 40px;
}
.completed-container {
  width: 36px;
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  border-radius: 0 0 6px 0;
}
.completed-container.with-drop-shadow {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}
.completed-container.smaller {
  width: 20px;
}
/*.filled {
  background-size: 100% !important;
  background-color: transparent !important;
}*/
.badge-small {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: white;
  margin-right: 5px;
  margin-left: 1px;
  /*&.ss {
    height: 28px;
    width: 30px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;
    position: relative;
    top: 5px;left: 6px;
    margin-right: 0;
  }*/
}
.badge-small p {
  margin-bottom: 0px !important;
}
ul.filter-list {
  list-style-type: none;
}
ul.filter-list li {
  display: inline-block;
  width: 100%;
  padding: 4px 0;
  min-height: 32px;
}
ul.filter-list li:hover p {
  color: #1ea182;
}
ul.filter-list .small-list-icon {
  height: 40px;
  width: 40px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  left: -40px;
  top: 0px;
  background-color: white;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}
ul.filter-list .list-label {
  margin: 0;
  color: #9ba1b3;
  position: absolute;
  top: 12px;
  left: 8px;
}
ul.filter-list .list-label.active-filter {
  color: #1ea182;
}
ul.filter-list .list-label.no-icon {
  left: -40px;
  top: 4px;
}
.filter-x {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  background-image: url('../imgs/x-black.svg');
  top: 8px;
  right: 0;
  display: none;
}
.filter-x.active-filter {
  text-decoration: underline;
  color: black;
  display: inline-block;
}
.filter-x.prefs {
  top: 0px;
}
.view-by {
  display: none;
  margin-bottom: 8px;
}
.view-by p {
  color: #a9a9a9;
  height: 19px;
  margin-top: 8px;
  margin-left: 1rem;
}
.view-by .icon {
  float: right;
  top: -1.75rem;
  position: relative;
  right: 0.75rem;
  color: #9ba1b3;
  width: 1rem;
  height: .5rem;
}
.view-by .icon.down {
  top: -1.75rem;
}
.angle-up {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: .7rem;
  height: 0.5rem;
  display: none;
}
.ff-header-container {
  height: 18px;
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
.users-level-badge {
  background-color: #fff;
  border-radius: 100%;
  background-size: 50% !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}
.badge-level-shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}
.game-values {
  border-radius: 8px 0 0 8px;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 6px;
  display: flex;
  transition: all .5s ease;
  transform: scale(0.75);
}
.game-values .game-points {
  background-color: #ffc411;
  border-radius: 80px;
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  font-weight: lighter;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 8px 4px 0px 0px;
  text-align: center;
  display: inline-block;
  margin: 5px;
  transition: all .5s ease;
}
.game-values .game-points.animate-experience {
  opacity: 0;
  transform: translateY(-250px);
}
.game-values .game-icon {
  height: 40px;
  width: 40px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin: 5px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
}
.badge-placeholder {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 2;
}
.badge-placeholder.animate-challenge-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 1;
  transition: all 1s ease;
}
.badge-placeholder.animate-challenge-badge.activate-animation {
  transform: translate(440px, -120px);
  opacity: 0;
}
.badge-placeholder.user-image {
  width: 100%;
  border-radius: 100%;
  margin-bottom: 3%;
  background-color: #f0f0f6;
  background-image: url('../imgs/usericon-green.svg');
}
.badge-placeholder.small {
  width: 50px;
  height: 50px;
}
.badge-placeholder.medium {
  width: 75px;
  height: 75px;
}
.badge-placeholder.normal {
  width: 100px;
  height: 100px;
}
.badge-placeholder.large {
  width: 150px;
  height: 150px;
}
.badge-placeholder p {
  font-weight: 400;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
/***********************************************
Inspiration Styling
***********************************************/
.inspHashtags {
  color: #9ba1b3;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
.inspWrapper {
  padding-bottom: 10%;
}
.inspWrapper .inspQuoteWrapper {
  text-align: center;
  font-size: 1rem;
  font-weight: lighter;
}
.inspWrapper .inspQuoteWrapper .inspQuote {
  color: #9ba1b3;
  display: inline-block;
  margin-bottom: 0;
}
.inspWrapper .instaImgWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
.inspWrapper .instaImgWrapper .instaImg {
  display: inline-block;
  width: 22%;
  height: 22%;
  border-radius: 7px 7px 7px 7px;
}
.inline-block {
  display: inline-block;
  vertical-align: top;
}
.table-row {
  display: table-row;
  vertical-align: top;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.page-title {
  position: relative;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
}
.page-title h1 {
  margin-bottom: 0;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 2rem;
  bottom: 0;
  display: inline-block;
  vertical-align: top;
}
.page-title h2 {
  margin: 0;
  bottom: 0;
  vertical-align: top;
  text-transform: capitalize;
  color: black;
  font-weight: 400;
  letter-spacing: 2px;
}
/*.page-title-desc-container {
  max-width: calc(@phone-max-width / 1.396);
}*/
.info-box {
  background-color: #ffc411;
  border-radius: 100%;
  position: relative;
  width: 16px;
  height: 16px;
  color: white;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.info-box p {
  text-align: center;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 500;
}
.back-btn-n-title-wrapper {
  margin-bottom: 1rem;
}
.page-title-description {
  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms ease;
  position: absolute;
  margin-bottom: 1rem;
  top: 38px;
  left: -142px;
  width: 300px;
  z-index: 10;
  background-color: #f0f0f6;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.page-title-description.active {
  visibility: visible;
  opacity: 1;
}
.page-title-description .triangle {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  left: 130px;
  transform: rotate(45deg);
  background-color: #f0f0f6;
  z-index: -1;
}
.page-title-description p {
  font-size: 1rem;
  color: black;
  margin: 0 auto;
  display: inline-block;
  padding: 0.75rem;
  font-weight: 100;
  z-index: 2;
  text-align: left;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.horizontal-rule-wrapper {
  text-align: center;
  height: 25px;
  margin: 1rem 0 1rem;
}
.horizontal-rule-wrapper .horizontal-rule {
  color: #9ba1b3;
  border-bottom: 1px solid;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}
.horizontal-rule-wrapper .title {
  width: auto;
  margin: auto;
  position: relative;
  top: -31px;
  padding: 0 20px;
  text-align: center;
  background-color: white;
  display: inline-block;
  max-width: 90%;
}
.simple-hr-rule {
  color: #f0f0f6;
  margin-top: 5px;
  margin-bottom: 15px;
}
.center-and-fill-space {
  text-align: center;
  width: 100%;
  display: block;
}
.center-and-fill-space.large {
  margin-top: .5rem !important;
}
.center-and-fill-space.small {
  margin-top: .15rem !important;
}
/*******************
NEW TAG FOR RECIPIES, POWERUPS, AND DEEP DIVES
*******************/
.new-tag {
  text-transform: uppercase;
  color: #ffca29;
}
.item-grid.with-filters {
  width: 79%;
  display: inline-block;
  vertical-align: top;
}
.item-grid .blueprint-item:nth-child(1) {
  margin-top: 0;
}
.item-grid .blueprint-item:nth-child(2) {
  margin-top: 0;
}
.item-grid .blueprint-item {
  width: 40%;
  margin: 1rem;
  padding-bottom: 50px;
  box-shadow: 2px 2px 5px rgba(155, 155, 155, 0.7);
  background-color: #f0f0f6;
  height: 36.4vw;
  max-height: 480px;
  overflow: hidden;
  z-index: 20;
}
.item-grid .blueprint-item.expanded {
  max-height: initial;
  height: auto;
  width: 88.5%;
}
.item-grid .blueprint-item.expanded .recipe-item {
  width: 25%;
}
.item-grid .blueprint-item.expanded .recipe-item p {
  opacity: 1;
}
.item-grid .blueprint-item.expanded .recipe-item p.transparent {
  opacity: 0.4;
}
.item-grid .blueprint-item.expanded .recipe-item.odd-last-selectable-blueprint-item {
  width: calc(25% + 2px);
}
.item-grid .blueprint-item.expanded .recipe-item:nth-child(odd).odd-last-selectable-blueprint-item {
  width: calc(25% + 2px);
}
.item-grid .blueprint-item .recipe-item:nth-child(even) {
  border-left: 0.125rem solid white;
}
.item-grid .blueprint-item .recipe-item:nth-child(odd) {
  border-right: 0.125rem solid white;
}
.item-grid .blueprint-item .recipe-item:nth-child(odd).odd-last-selectable-blueprint-item {
  border-right: 0.25rem solid white;
  width: calc(50% + 2px);
}
.item-grid .blueprint-item .recipe-item {
  margin: 0;
  width: 50%;
  border: 0.25rem solid white;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -4px;
  position: relative;
  /*&.odd-last-selectable-blueprint-item {
        border-right: 0.125rem solid white;
        width: 50%;
      }*/
}
.item-grid .blueprint-item .recipe-item p {
  /*background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 1%, rgba(0,0,0,0.65) 85%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 1%,rgba(0,0,0,0.65) 85%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom, rgba(0,0,0,0.65) 1%,rgba(0,0,0,0.65) 85%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );*/
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  display: inline-block;
  transition: opacity 200ms ease;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: auto !important;
  color: white;
  padding: 0.75rem;
}
.item-grid .blueprint-item .recipe-item p.transparent {
  opacity: 0;
}
.item-grid .blueprint-item .recipe-item .item-image {
  border-radius: 0px;
}
.item-grid .blueprint-item .recipe-item .completed-container {
  position: relative;
  height: 0px;
}
.item-grid .blueprint-item .recipe-item.with-transparencies {
  margin-right: -0.26rem;
}
.item-grid .blueprint-item .blueprint-name {
  padding: 0.85rem 1rem 1rem 1rem;
  font-weight: 900;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 10;
}
.item-grid .blueprint-item .blueprint-count {
  opacity: 0.8;
  position: absolute;
  z-index: 11;
  bottom: 1rem;
  right: 1rem;
}
.item-grid .blueprint-item .blueprint-count p {
  display: inline-block;
  color: #1ea182;
  padding-right: 0.15rem;
  height: auto !important;
}
.item-grid .recipe-item {
  width: calc(25% - 0.5rem);
  margin: .25rem;
}
.item-grid .recipe-item .item-image {
  padding-bottom: 100%;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}
.item-grid p,
.item-grid p:hover,
.item-grid p:active,
.item-grid p:link,
.item-grid p:visited {
  margin: 0;
  text-decoration: none;
  color: black;
}
.filters {
  width: 20%;
  display: inline-block;
  vertical-align: top;
}
.filters h2 {
  margin-top: 0;
}
.filters .simple-hr-rule {
  margin-right: 1rem;
}
.bottom-fade {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 90%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 90%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 90%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}
.mobile-tag-display {
  display: none;
}
/****************************
STYLES FOR PAGE TABS IE POWER UPS, HOW-TOs,
and GUIDED MEALS INDEX
****************************/
/*.tabs-container {
  width: 78%;
  float: right;
  &.no-search {
    width: 100%;
    float: none;
  }
}

.tab-nav-wrapper {
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  padding: 0.5rem 16px;
  -ms-overflow-style: none;
  position: relative;
  .scroll-cover {
    visibility: hidden;
  }
}

.tab-nav-wrapper::-webkit-scrollbar {
    display: none;
}

.tab-left-shade {
  pointer-events:none;
  position: absolute;
  height: 57px;
  width: 50px;
  z-index: 10;
  left: 15px;
  background: -webkit-linear-gradient(right, rgba(255,255,255,0), rgba(255,255,255,1));
  background: -o-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,1));
  background: -moz-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,1));
  background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));
}

.tab-right-shade {
  pointer-events:none;
  position: absolute;
  height: 57px;
  width: 50px;
  z-index: 10;
  right: 15px;
  top: 0;
  background: -webkit-linear-gradient(left, rgba(255,255,255,0), rgba(255,255,255,1));
  background: -o-linear-gradient(right, rgba(255,255,255,0), rgba(255,255,255,1));
  background: -moz-linear-gradient(right, rgba(255,255,255,0), rgba(255,255,255,1));
  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
}*/
.scroll-cover {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 20px;
  height: 36px;
  width: 2rem;
  z-index: 10;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #ffffff);
  background: -o-linear-gradient(right, rgba(255, 255, 255, 0), #ffffff);
  background: -moz-linear-gradient(right, rgba(255, 255, 255, 0), #ffffff);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
}
.tabs-container {
  position: relative;
}
.page-tabs {
  text-align: left;
  position: relative;
  margin: 3rem 0 1rem 0;
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  padding: 0;
  -ms-overflow-style: none;
}
.page-tabs::-webkit-scrollbar {
  display: none;
}
.page-tabs li {
  display: inline-table;
  position: relative;
  text-align: center;
  height: 65px;
  margin-left: -4px;
  padding: 1rem 0;
  border-bottom: 4px solid rgba(0, 0, 0, 0.05);
}
.page-tabs li.active {
  border-bottom: 4px solid #1ea182;
}
.page-tabs li a {
  text-decoration: none;
  text-transform: uppercase;
  padding: 1.25rem 0px;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
  letter-spacing: 0.15rem;
  color: #9ba1b3;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.page-tabs li a.active {
  color: #1ea182;
}
.page-tabs li a:hover.active {
  text-decoration: none;
}
.page-tabs li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.page-tabs li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.page-tabs.multi-menu {
  padding: 0;
  padding: 0.5rem 4.5rem;
  position: relative;
  text-align: left;
  justify-content: space-between;
  display: flex;
  margin-right: 0;
}
.page-tabs.col-3 li {
  width: 33%;
}
.page-tabs.col-4 li {
  width: 25%;
}
/****************************
STYLING FOR VIDEO SECTIONS OF POWER UPS,
HOW-TOS, AND EXPANDED RECIPES
****************************/
.video-container {
  width: 67%;
  display: inline-block;
}
.video-container .play-button {
  background-image: url('../imgs/play-white-noring.svg');
  background-size: 25%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
}
.video-container.video-embed {
  background-size: cover;
  background-position: center center;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.video-container.video-embed .video-player {
  opacity: 0.000001;
  transition: opacity 400ms ease;
  transform: scale(1.8);
}
.video-container.video-embed .video-player.play {
  opacity: 1;
  transform: scale(1);
}
.video-container .video-icon {
  background-image: url('../imgs/PlayArrow_GreenCircle.svg') !important;
  background-size: 116%;
}
.video-container .vimeo-video > div {
  border-radius: 4px;
  overflow: hidden;
}
.video-container .sixteen-by-nine-image {
  width: 100%;
  padding-bottom: 56%;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
}
.content-section-container {
  padding-top: 24px;
  *zoom: 1;
}
.content-section-container:before,
.content-section-container:after {
  content: "";
  display: table;
}
.content-section-container:after {
  clear: both;
}
.content-section-left {
  float: left;
}
.content-section-left.vertical-middle {
  width: 100%;
}
.content-section-right {
  width: 31%;
  position: relative;
  float: right;
}
.share-popup-container .form-container {
  background-color: #ffffff;
  padding: 1rem 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.share-popup-container .form-container form input {
  width: 100% !important;
}
.share-copy {
  text-align: left;
  position: relative;
  left: 2px;
}
.progress {
  background-color: #f0f0f6;
}
.progress .progress-bar {
  background-color: #30c2d9;
  height: 100%;
  border-radius: 36px;
  transition: all 800ms ease;
  display: inline-block;
}
/***********************************************
  PROGRESS CIRCLE STYLES
  ***********************************************/
.progress-circle-partial.level-1 .progress-circle > svg > circle {
  stroke: #30c2d9;
}
.progress-circle-partial.level-2 .progress-circle > svg > circle {
  stroke: #ffc411;
}
.progress-circle-partial.level-3 .progress-circle > svg > circle {
  stroke: #1ea182;
}
.progress-circle-partial.master {
  display: none;
}
.progress-circle-partial .progress-circle svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}
.progress-circle-partial .progress-circle svg circle {
  stroke-dasharray: 278.675%;
  stroke-dashoffset: 278.675%;
  transition: stroke-dashoffset 0.5s linear;
  stroke-width: 11.25%;
  opacity: .7;
}
.progress-circle-partial .progress-circle svg #bg {
  stroke-dashoffset: 0;
  stroke: #ffffff !important;
}
.progress-circle-partial .progress-circle svg #curr {
  opacity: 1;
}
.progress-circle-partial .progress-circle svg #curr.badge-complete {
  stroke-width: 0%;
}
.inline-teal-color {
  color: #1ea182;
}
.no-padding {
  padding: 0px !important;
}
.no-padding-top {
  padding-top: 0px !important;
}
.no-padding-bottom {
  padding-bottom: 0px !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin span {
  margin: 0 !important;
}
.small-badge-drop-shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
}
.make-round {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
/***********************************
GENERAL POPUP CONTAINERS
************************************/
.center-all {
  text-align: center;
  font-weight: 300;
}
.popup-container {
  width: 35%;
}
.popup-container .title {
  font-size: 1.25rem;
  color: white;
  background-color: #1ea182;
  text-align: center;
  padding: 2% 5%;
  width: 100%;
  line-height: 1rem;
  font-weight: 400;
}
.popup-container .secondary-title {
  font-size: 1rem;
  line-height: 1rem;
  color: black;
  text-align: center;
  padding: 2.5%;
}
.popup-container .line {
  font-size: 1rem;
  line-height: 1rem;
  padding-bottom: 2.5%;
}
.popup-container .line.smaller {
  font-size: 1rem;
}
.popup-container .line.less-pad {
  padding: 1.25%;
}
.popup-container .undrln {
  text-decoration: underline;
}
.popup-container .bold {
  font-weight: 500;
}
.popup-container .green-colored {
  color: #1ea182;
}
.popup-container .text {
  font-size: 1rem;
  color: black;
  text-align: center;
  margin-bottom: 15px;
  margin-left: 50px;
  margin-right: 50px;
}
.popup-container .button-container {
  position: relative;
  width: 20%;
  margin-left: 70.5%;
}
.popup-container .text-form {
  margin: auto;
  margin-bottom: 0px;
}
.popup-container .popup-btn {
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 300px;
}
.popup-container .img {
  width: 75px;
  height: 75px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto;
}
.popup-container .overview-steps-icon.img {
  background-size: contain;
}
.popup-container .button-length-link-padding {
  padding: 0 3rem;
}
.progress-circle {
  position: relative;
  /*top: -4px;
  left: -4px;*/
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}
.progress-circle.small {
  width: 68px;
}
.progress-circle.large {
  width: 158px;
}
.clickable {
  cursor: pointer !important;
}
.unclickable {
  cursor: default !important;
}
.absolute-position {
  position: absolute !important;
}
.challenge-tab-item-container .challenge-tab-item {
  top: 34px;
}
.challenge-tab-item-container .tab-link div {
  opacity: 0.25;
  transition: all 400ms ease;
}
.challenge-tab-item-container .tab-link.active div {
  opacity: 1;
}
.ae-challenge-container .max-width {
  padding: 0 1.75rem;
}
.user-image {
  background-size: cover;
  border-radius: 100%;
  background-position: center;
  margin-bottom: 3%;
  background-color: #f0f0f6;
}
.user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  /*&.small {
    width: 60px;
  }
  &.medium {
    width: 80px !important;
  }
  &.large {
    width: 180px;
  }*/
}
.align-right {
  text-align: right;
}
.progress-width {
  max-width: 700px;
}
.progress-width .progress {
  height: 30px !important;
}
.uppercase {
  text-transform: uppercase;
}
.teammates {
  position: relative;
  left: 0;
  right: 0;
  margin: 1rem auto;
  text-align: left;
}
.teammates .teammate {
  display: inline-block;
  vertical-align: top;
  margin: 0.5rem;
  position: relative;
  text-align: center;
  width: 110px;
}
.teammates .teammate .progress-circle-partial .progress-circle svg circle {
  stroke-width: 5%;
  stroke-dasharray: 170%;
  opacity: 1;
}
.teammates .teammate p {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*display: inline-block;
      vertical-align: top;*/
}
.teammates .teammate span {
  color: #9ba1b3;
  font-size: 0.75rem;
  font-weight: 100;
}
.teammates .progress-circle-partial.blue .progress-circle svg #bg {
  stroke: #30c2d9 !important;
}
.teammates .progress-circle-partial.purple .progress-circle svg #bg {
  stroke: #6de770 !important;
}
.teammates .progress-circle-partial.red .progress-circle svg #bg {
  stroke: #ff3f3f !important;
}
.teammates .progress-circle-partial.yellow .progress-circle svg #bg {
  stroke: #ffca29 !important;
}
.teammates .progress-circle-partial.orange .progress-circle svg #bg {
  stroke: #ffc411 !important;
}
.teammates .progress-circle-partial.green .progress-circle svg #bg {
  stroke: #1ea182 !important;
}
.ae-poll {
  overflow: hidden;
}
.ae-poll .poll-heading {
  font-size: 26px;
  font-weight: 900;
  position: relative;
  border-radius: 8px 8px 0 0;
  padding: 11px 18px 0;
}
.ae-poll .poll-heading .flag-heading {
  position: relative;
  display: inline-block;
  left: -18px;
  padding: 8px 18px;
  background-color: #1ea182;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1px;
  vertical-align: top;
  height: 40px;
}
.ae-poll .poll-heading .flag-heading .flag-tip {
  background-color: transparent;
  position: absolute;
  background-image: url('../imgs/Poll_Triangles.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  height: 40px;
  width: 16px;
  vertical-align: top;
  right: -15px;
  top: 0;
}
.ae-poll .game-values {
  top: 0px;
  right: 0px;
}
.ae-poll .game-icon {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
.ae-poll-question-container {
  border-radius: 8px 8px 0 0;
  padding-bottom: 2.63157895% !important;
  margin-bottom: 0!important;
  border-bottom: 1px solid #f0f0f6;
}
.ae-poll-question-container .game-values {
  top: 0px;
  right: 0px;
  transform: scale(1.25);
}
.ae-poll-question-container .game-values .game-icon,
.ae-poll-question-container .game-values .game-points {
  height: 25px;
  width: 25px;
}
.ae-poll-question-container .game-values .game-points {
  padding: 3px 2px 0px 0px;
  font-size: 13px;
}
.ae-poll-question {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  font-size: 1rem;
  text-transform: none;
  color: #9ba1b3;
  letter-spacing: 0;
  margin: 10px 0 0;
  font-weight: 100;
  color: #000 !important;
  margin-bottom: 16px;
}
.ae-poll-answer-option:hover .ae-poll-answer-button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.ae-poll-answer-option:hover .ae-poll-answer-button.selected {
  background-color: #1ea182;
  color: #fff;
}
.ae-poll-answer-button {
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;
  text-align: center;
  background-color: #f0f0f6;
}
.ae-poll-answer-button.selected {
  background-image: url('../imgs/didit-selected.svg');
  color: transparent!important;
  background-size: cover;
}
.ae-poll-answer-label {
  display: inline-block;
  width: calc(100% - 126px);
  vertical-align: middle;
  margin-top: 0.75rem;
}
.ae-poll-answer-option {
  position: relative;
}
.ae-poll-submit {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  right: 0px;
  color: #fff;
  background-color: #1ea182;
  padding: 4px 10px;
  border-radius: 8px;
  margin-left: 4px;
}
.ae-poll-submit:hover {
  cursor: pointer;
}
.ae-poll-back {
  border-radius: 8px 8px 0 0;
  padding-bottom: 2.63157895% !important;
  margin-bottom: 0!important;
}
.ae-poll-result-text {
  text-align: center;
  margin-bottom: 8px;
  color: #1ea182;
}
.ae-poll-user-answer {
  text-align: center;
  margin-bottom: 8px;
}
.ae-poll-explanation.non-quiz {
  text-align: center;
}
.ae-poll-close-back {
  position: absolute;
  height: 30px;
  right: 10px;
  top: 10px;
}
.ae-poll-close-back:hover {
  cursor: pointer;
}
/***********************************
Pagination - Step Circles
***********************************/
.step-circles {
  margin: 1rem auto;
  position: relative;
  display: block;
}
.circle {
  background-color: transparent;
  border: 1.5px solid white;
  height: 15px;
  width: 15px;
  margin: 0px 5px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
}
.circle.current,
.circle.active {
  background-color: white;
}
.pagination.step-circles {
  width: 100%;
  max-width: 400px;
}
.pagination.step-circles a {
  color: #ffffff;
  float: left;
}
@media screen and (max-width: 500px) {
  .pagination.step-circles a {
    float: none;
  }
}
/***********************************
 Smaller than Desktop View
************************************/
/*@media screen and (max-width: @desktop-max-width) {

  .tag-tab-wrapper{
    .img-and-title-wrapper{
      float: left;
      .tabPlaceHolderImg {
        width: 100%;
      }

      p {
        color: black;
        display: block;
        font-size: @normal-font-size;
        padding: 0px 5px;
        min-height: 20px;
      }
    }
  }
}*/
/***********************************
 Smaller than tablet View
************************************/
@media screen and (max-width: 1023px) {
  #app-wrapper {
    max-width: 1023px;
  }
  .item-grid .recipe-item {
    width: calc(33% - 0.5rem);
  }
  .search-bar {
    width: 100%;
    position: relative;
  }
  .search-bar .form-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .search-bar .form-container section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .video-container {
    width: 100%;
    display: block;
    margin-left: 0px;
  }
  .video-container .vimeo-video > div {
    border-radius: 4px;
    overflow: hidden;
  }
  .content-section-right {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .content-section-right .generic-description-container {
    text-align: center;
  }
}
/***********************************
 Smaller than phone landscape View
************************************/
@media screen and (max-width: 768px) {
  /*.user-name {
    &.medium {
      width: 72px !important;
    }
  }*/
  .mobile-align-left {
    text-align: left !important;
  }
  .mobile-float-left {
    float: left;
  }
  .page-tabs li {
    padding: 1rem 2rem;
  }
  .page-tabs li:first-child {
    padding-left: 2rem;
  }
  .page-tabs li:last-child {
    padding-right: 2rem;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .scroll-cover {
    visibility: visible;
  }
  ul.filter-list {
    list-style-type: none;
  }
  ul.filter-list li {
    width: calc(49% - 50px);
    margin-right: 50px;
  }
  .mobile-filter-menu {
    border: 1px solid #f0f0f6;
    border-radius: 4px;
    padding: 0 1rem;
    display: none;
  }
  .mobile-filter-menu.active {
    display: block;
  }
  .mobile-filter-menu .simple-hr-rule {
    margin-right: 1rem;
  }
  .popover {
    max-width: 90%;
    left: 0 !important;
    margin-left: 5%;
  }
  .view-by {
    display: block;
    width: 100%;
    border: 1px solid #f0f0f6;
    border-radius: 4px;
    height: 39px;
  }
  .angle-up {
    display: block;
    position: absolute;
    top: -8px;
    right: 14px;
    border-bottom: 1px solid white;
    color: #f0f0f6;
  }
  .item-grid.with-filters {
    width: 100%;
  }
  .item-grid .blueprint-item {
    width: calc(100% - 2rem);
    height: calc(92vw - 2px);
    max-height: initial;
  }
  .filters {
    width: 100%;
  }
  .filters h2 {
    margin-top: 1rem;
  }
  .mobile-tag-display {
    display: block;
    width: 100%;
    margin: 10px 0px;
  }
  .mobile-tag-display .mobile-tag {
    background-color: #1ea182;
    border-radius: 4px;
    display: inline-block;
    margin: 2px;
    position: relative;
  }
  .mobile-tag-display .mobile-tag p {
    color: white;
    margin: 0;
    padding: .5rem;
    padding-right: 2.5rem;
  }
  .mobile-tag-display .mobile-tag .mobile-tag-x {
    background-image: url(../imgs/x-white.svg);
    height: 30px;
    width: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 3px;
    right: 0px;
  }
  .mobile-tag-display .pref:first-child {
    margin-left: 0px;
  }
  .popover.bottom > .arrow.index-mobile {
    background-image: url('../imgs/Orientation Bubble Arrow_up_left.svg') !important;
    height: 45px !important;
    width: 50px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    border: 0 !important;
    top: -45px !important;
  }
  .page-title-desc-container {
    max-width: 99%;
  }
  #app-wrapper {
    max-width: 768px;
  }
  .hide-on-mobile {
    display: none !important;
  }
  .page-title h1 {
    font-size: 1.5rem;
  }
  .action-list {
    width: 100%;
    text-align: right;
    float: none;
    top: 0;
    margin: 0.75rem 0 0;
  }
  .video-container {
    width: 100%;
    display: block;
    margin-left: 0px;
  }
  .video-container .vimeo-video > div {
    border-radius: 4px;
    overflow: hidden;
  }
  .content-section-right {
    width: 100%;
    margin-bottom: 1rem;
  }
  .content-section-right .generic-description-container {
    text-align: center;
  }
}
/***********************************
 Smaller than phone portrait View
************************************/
@media screen and (max-width: 500px) {
  #app-wrapper {
    max-width: 100vw;
  }
  .badge-placeholder.medium {
    width: 50px;
    height: 50px;
  }
  .item-grid .recipe-item {
    width: calc(50% - 0.5rem);
  }
  .header {
    margin-bottom: 5px;
  }
  .go-back-btn-container {
    left: 0px;
  }
  ul.filter-list li {
    width: 100%;
    margin-right: 0;
  }
  .slider-container.onboarding .slider-content .info-block .finished {
    position: absolute;
    width: 100% !important;
    float: none !important;
    height: 100% !important;
    top: inherit;
    transform: translate(-10px, 300px);
  }
  .slider-container.onboarding .slider-content .info-block .allset {
    position: absolute;
    width: 375px !important;
    float: none !important;
    padding-left: 0 !important;
    height: 300px;
    overflow: hidden;
    transform: translate(-16px, -16px);
  }
  .pagination.step-circles a {
    padding-left: 15px;
  }
  a.button-length-link-padding.primary-btn.md-button.backbtn {
    min-width: 48px;
    padding: 16px;
  }
  .circle {
    margin: 0px 2px;
  }
  .slide6 {
    margin-bottom: -1rem !important;
  }
  .slide6-main {
    display: flex;
    flex-direction: column;
  }
  .slide6-order-1 {
    order: 1;
  }
  .slide6-order-2 {
    order: 2;
    margin-top: 3rem;
    padding-top: 1rem;
  }
  .slide6-order-3 {
    order: 3;
  }
  .slide6-order-2,
  .slide6-order-3 {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media screen and (min-width: 100px) {
  .game-values {
    transform: scale(1);
    top: 0px;
    right: 0px;
  }
}
.hsds-beacon .IeCXW {
  bottom: -100px !important;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.px-50 {
  padding: 0 50px;
}
.left-right-section {
  flex-direction: column;
  padding-top: 50px !important;
}
@media screen and (max-width: 779px) {
  .left-right-section {
    padding-top: 20px !important;
  }
}
.left-right-section .left-right {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  max-width: 1120px;
}
@media screen and (max-width: 779px) {
  .left-right-section .left-right {
    flex-direction: column;
  }
}
.left-right-section .left-right .info-block {
  background: #1ea182;
  padding: 20px 30px;
  width: 50%;
}
@media screen and (max-width: 779px) {
  .left-right-section .left-right .info-block {
    margin-bottom: 35px !important;
  }
}
.left-right-section .left-right .info-block p,
.left-right-section .left-right .info-block h1 {
  color: #fff !important;
}
.left-right-section .left-right .right-side {
  width: 50%;
  padding-top: 25px;
}
.left-right-section .left-right .right-side p.progress-badge-labels {
  color: #363b47 !important;
}
.left-right-section .left-right .right-side .progress-page-container .progress-section > div {
  width: 140px;
}
.left-right-section .left-right .right-side .progress-badge-labels {
  min-width: 160px;
}
.left-right-section .left-right .right-side .progress-badge-labels .form-container,
.left-right-section .left-right .right-side .progress-badge-labels .horizontal-rule-wrapper .title {
  background-color: #f0f0f6;
}
.left-right-section .left-right .right-side .form-container {
  background-color: transparent;
}
@media screen and (max-width: 779px) {
  .left-right-section .left-right .info-block,
  .left-right-section .left-right .right-side {
    width: 100%;
  }
  .left-right-section .left-right .info-block.px-50,
  .left-right-section .left-right .right-side.px-50 {
    padding: 0 10px;
  }
  .left-right-section .left-right .right-side {
    padding-top: inherit;
  }
}
.left-right-section .d-flex.mt-auto.flex-column.align-items-center {
  margin-top: 30px !important;
}
.left-right-section .horizontal-rule-wrapper .title,
.left-right-section .form-container.ng-scope {
  background: #f0f0f6;
}
.left-right-section .pagination.step-circles.buttonbox.ng-scope {
  display: flex;
  bottom: 1rem;
  min-width: 350px;
  justify-content: center;
  align-items: center;
}
.left-right-section .pagination.step-circles.buttonbox.ng-scope a.button-length-link-padding.primary-btn.md-button.backbtn {
  margin-bottom: -10px;
}
@media screen and (max-width: 779px) {
  .left-right-section .pagination.step-circles.buttonbox.ng-scope {
    min-width: 230px;
  }
  .left-right-section .pagination.step-circles.buttonbox.ng-scope a.button-length-link-padding.primary-btn.md-button.backbtn {
    left: -50px;
  }
}
.left-right-section select#build {
  box-shadow: 0px 0px 10px #ababab;
  border-radius: 5px;
}
@media screen and (max-width: 450px) {
  .left-right-section select#build {
    width: auto;
  }
}
.left-right-section .statement {
  background: #60c0d6;
  padding: 20px;
  box-shadow: 0px 0px 10px #ababab;
  border-radius: 5px;
  max-width: 300px;
  margin: 0 auto;
}
.left-right-section .statement p {
  color: #fff !important;
  text-transform: uppercase;
}
.left-right-section .statement p::after {
  content: "";
  width: 25px;
  height: 2px;
  background: white;
  display: block;
  margin: 0 auto;
}
.left-right-section .statement .form-container {
  padding: 0;
}
.left-right-section .statement .form-container .form-group {
  margin: 0;
}
.left-right-section .statement .form-container .form-group textarea {
  width: 100%;
  background-color: #79ccde;
  border: none;
  min-height: 250px;
}
strong.swap-symbol,
span.swap-symbol {
  font-size: 1.5rem;
  position: relative;
  line-height: 15px;
  color: #1ea182;
}
strong.swap-symbol {
  top: 8px;
  left: -2px;
}
span.swap-symbol {
  top: 7px;
}
.text-italic {
  font-style: italic;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mt-10 {
  margin-top: 10px;
}
.p-10 {
  padding: 10px;
}
.align-items-center {
  align-items: center;
}
.sub-desc {
  border-radius: 8px;
}
.profile-image-blok {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 100%;
}
.bg-gray-1 {
  background: #f5f5f5;
}
.info-icon {
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-image: url(../imgs/info-grey.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-right: 10px;
}
/***********************************************
  PAGE TO PAGE TRANSITIONS
***********************************************/
.page {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page.ng-enter,
.page.ng-leave {
  -webkit-transition: all .6s ease;
  -moz: all .6s ease;
  -o: all .6s ease;
  transition: all .6s ease;
}
.page.ng-enter.ng-enter-active {
  -webkit-animation: moveFromRight .6s ease both;
  animation: moveFromRight .6s ease both;
}
.page.ng-leave.ng-leave-active {
  -webkit-animation: moveToLeft .6s ease both;
  animation: moveToLeft .6s ease both;
}
.pop-in {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  animation: pop-in 400ms ease both;
}
.soft-pop-in {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  animation: bubble-out 400ms ease both;
}
/*-webkit-animation: moveFromRight .6s ease both;
  animation: moveFromRight .6s ease both;
}*/
@-webkit-keyframes moveToLeft {
  to {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes moveToLeft {
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes moveFromRight {
  from {
    -webkit-transform: translateX(100%);
  }
}
@keyframes moveFromRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
/***********************************************
  GENERAL
***********************************************/
.animate-all {
  -webkit-transition: all .6s ease;
  -moz-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}
.animate-all-short {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
}
.animate-dim {
  -webkit-animation: fade .6s ease both;
  animation: fade .6s ease both;
}
@keyframes pop-in {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pop-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes bubble-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes bubble-out {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.025);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bubble-in-opaque {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  75% {
    opacity: 1;
    transform: scale(1.075);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes explode {
  0% {
    transform: scale(0.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes explode-and-spin {
  0% {
    transform: translate(-50%, -50%) scale(0.05) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(405deg);
  }
}
@keyframes background-flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  100% {
    background-color: transparent;
  }
}
@keyframes mild-background-flash-w-border {
  0% {
    background-color: transparent;
    border-bottom: solid 5px rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.25);
    border-bottom: solid 5px rgba(255, 255, 255, 0.05);
  }
  100% {
    background-color: transparent;
    border-bottom: solid 5px rgba(255, 255, 255, 0);
  }
}
@keyframes mild-background-flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(255, 255, 255, 0.25);
  }
  100% {
    background-color: transparent;
  }
}
@keyframes bounce {
  0% {
    top: 0px;
    animation-timing-function: ease-in;
  }
  10% {
    top: -5px;
    animation-timing-function: ease-in;
  }
  30% {
    top: 0px;
    animation-timing-function: ease-out;
  }
  50% {
    top: -3px;
    animation-timing-function: ease-in;
  }
  65% {
    top: 0px;
    animation-timing-function: ease-out;
  }
  80% {
    top: -2px;
    animation-timing-function: ease-in;
  }
  80% {
    top: 0px;
    animation-timing-function: ease-out;
  }
  90% {
    top: 0px;
    animation-timing-function: ease-in;
  }
  100% {
    top: 0px;
    animation-timing-function: ease-out;
  }
}
@keyframes top-burger-X {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
    top: 1.35rem;
    height: 2px;
  }
}
@keyframes bottom-burger-X {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
    top: 1.35rem;
    height: 2px;
  }
}
@keyframes animate-swipe-right {
  0% {
    opacity: 1;
    right: 0px;
  }
  50% {
    opacity: 0;
    right: 100vw;
  }
  51% {
    opacity: 0;
    left: 100vw;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
@keyframes animate-swipe-left {
  0% {
    opacity: 1;
    right: 0px;
  }
  49% {
    opacity: 0;
    right: -100vw;
  }
  50% {
    opacity: 0;
    left: -100vw;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
@keyframes move-up {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/***********************************************
  EXPANDED RECIPES
***********************************************/
@-webkit-keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveFromBottom {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@-webkit-keyframes moveToBottom {
  to {
    -webkit-transform: translateY(100%);
  }
}
@keyframes moveToBottom {
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
.animate-footer-manu {
  -webkit-animation: moveFromBottom .3s ease both;
  animation: moveFromBottom .3s ease both;
}
.animate-footer-manu.ng-hide {
  -webkit-animation: moveToBottom .3s ease both;
  animation: moveToBottom .3s ease both;
}
@-webkit-keyframes empty {
}
@keyframes empty {
}
.animate-footer-menu-helper.ng-hide {
  -webkit-animation: empty .3s ease both;
  animation: empty .3s ease both;
}
.animate-footer-text {
  position: absolute;
  top: 0;
  height: 100%;
  padding: 2.5%;
  opacity: 1 !important;
}
.animate-footer-text.with-button {
  padding-right: 50%;
}
.animate-footer-text.ng-hide {
  opacity: 0 !important;
}
@-webkit-keyframes quick-fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}
@keyframes quick-fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: .5;
  }
}
@-webkit-keyframes quick-fade-out {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes quick-fade-out {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.animate-ingr-equip-icon {
  animation: quick-fade-in 0.3s ease;
}
.animate-ingr-equip-icon.ng-hide {
  animation: quick-fade-out 0.3s ease;
  width: 0;
  opacity: 0;
}
.animate-ingr-equip-text {
  opacity: 1;
}
.animate-ingr-equip-text.ng-hide {
  opacity: 0;
}
@-webkit-keyframes skill-next-color {
  0% {
    opacity: .1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}
@keyframes skill-next-color {
  0% {
    opacity: .1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}
.animate-skill-next-color {
  -webkit-animation: skill-next-color 2s ease-in-out 0s infinite;
  animation: skill-next-color 2s ease-in-out 0s infinite;
}
/***********************************************
  GUIDED RECIPE
***********************************************/
.animate-steps-tag-text {
  opacity: 1 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.animate-steps-tag-text.ng-hide {
  opacity: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
/***********************************************
  GUIDED RECIPE RIBBON
***********************************************/
@-webkit-keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-200%);
  }
}
@keyframes moveFromTop {
  from {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }
}
@-webkit-keyframes moveToTop {
  to {
    -webkit-transform: translateY(-100%);
  }
}
@keyframes moveToTop {
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.animate-gr-ribbon {
  -webkit-animation: moveFromTop .6s ease both;
  animation: moveFromTop .6s ease both;
  animation-delay: .4s;
}
.animate-gr-ribbon.closed {
  -webkit-animation: moveToTop .6s ease both;
  animation: moveToTop .6s ease both;
}
/******************************************************
  3D Orbital CSS Keyframes
*******************************************************/
@-webkit-keyframes orbit0to1 {
  from {
    transform: rotate(0deg) translateY(-212.5px) rotate(0deg);
  }
  to {
    transform: rotate(72deg) translateY(-212.5px) rotate(-72deg);
  }
}
@-webkit-keyframes orbit1to2 {
  from {
    transform: rotate(72deg) translateY(-212.5px) rotate(-72deg);
  }
  to {
    transform: rotate(144deg) translateY(-212.5px) rotate(-144deg);
  }
}
@-webkit-keyframes orbit2to3 {
  from {
    transform: rotate(144deg) translateY(-212.5px) rotate(-144deg);
  }
  to {
    transform: rotate(216deg) translateY(-212.5px) rotate(-216deg);
  }
}
@-webkit-keyframes orbit3to4 {
  from {
    transform: rotate(216deg) translateY(-212.5px) rotate(-216deg);
  }
  to {
    transform: rotate(288deg) translateY(-212.5px) rotate(-288deg);
  }
}
@-webkit-keyframes orbit4to0 {
  from {
    transform: rotate(288deg) translateY(-212.5px) rotate(-288deg);
  }
  to {
    transform: rotate(360deg) translateY(-212.5px) rotate(-360deg);
  }
}
@-webkit-keyframes mobile-orbit0to1 {
  from {
    transform: rotate(0deg) translateY(-137.5px) rotate(0deg);
  }
  to {
    transform: rotate(72deg) translateY(-137.5px) rotate(-72deg);
  }
}
@-webkit-keyframes mobile-orbit1to2 {
  from {
    transform: rotate(72deg) translateY(-137.5px) rotate(-72deg);
  }
  to {
    transform: rotate(144deg) translateY(-137.5px) rotate(-144deg);
  }
}
@-webkit-keyframes mobile-orbit2to3 {
  from {
    transform: rotate(144deg) translateY(-137.5px) rotate(-144deg);
  }
  to {
    transform: rotate(216deg) translateY(-137.5px) rotate(-216deg);
  }
}
@-webkit-keyframes mobile-orbit3to4 {
  from {
    transform: rotate(216deg) translateY(-137.5px) rotate(-216deg);
  }
  to {
    transform: rotate(288deg) translateY(-137.5px) rotate(-288deg);
  }
}
@-webkit-keyframes mobile-orbit4to0 {
  from {
    transform: rotate(288deg) translateY(-137.5px) rotate(-288deg);
  }
  to {
    transform: rotate(360deg) translateY(-137.5px) rotate(-360deg);
  }
}
/***********************************************
  BUTTONS
***********************************************/
.choice {
  text-align: center;
  margin: 5.26315789% 2.63157895%;
  *zoom: 1;
}
.choice:before,
.choice:after {
  content: "";
  display: table;
}
.choice:after {
  clear: both;
}
.choice .md-button {
  float: left;
  width: 47.36842105%;
  margin: 0;
  margin-right: 2.63157895%;
  display: inline-block;
  min-width: inherit;
}
.choice .md-button:last-child {
  margin-right: 0;
}
.md-button,
.md-button.md-default-theme,
a.md-button.md-default-theme {
  box-shadow: none;
  padding: 16px 32px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  min-width: 48px;
  box-sizing: border-box;
  text-transform: capitalize;
  position: relative;
  text-align: center;
  display: inline-block;
}
.md-button span,
.md-button.md-default-theme span,
a.md-button.md-default-theme span {
  vertical-align: text-top;
  font-size: 1rem;
  font-weight: 400;
}
.md-button.stacked,
.md-button.md-default-theme.stacked,
a.md-button.md-default-theme.stacked {
  min-height: 72px;
}
.md-button.stacked span,
.md-button.md-default-theme.stacked span,
a.md-button.md-default-theme.stacked span {
  line-height: 1rem;
  white-space: normal;
  vertical-align: middle;
}
.md-button p,
.md-button.md-default-theme p,
a.md-button.md-default-theme p {
  padding: 0;
  margin: 0;
}
a.md-button.md-default-theme {
  line-height: normal;
}
a.md-button.md-default-theme.stacked {
  min-height: 64px;
}
.not-rounded {
  border-radius: 0px !important;
}
.primary-btn,
a.md-button.md-default-theme.md-raised.primary-btn,
.md-button.md-default-theme.md-raised.primary-btn {
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  background-color: #ffc411;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: none;
}
.primary-btn:hover,
a.md-button.md-default-theme.md-raised.primary-btn:hover,
.md-button.md-default-theme.md-raised.primary-btn:hover {
  color: #ffffff;
  background-color: #ffd144;
  text-decoration: none;
}
.primary-btn.sign-up,
a.md-button.md-default-theme.md-raised.primary-btn.sign-up,
.md-button.md-default-theme.md-raised.primary-btn.sign-up {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  min-width: 0;
  margin: 0;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 40%;
}
.border-button {
  font-weight: 400;
  padding: 14px 32px;
  background-color: transparent;
  color: #1ea182;
  border: 2px solid #1ea182;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: none;
  text-decoration: none;
}
.border-button:hover {
  color: #ffffff;
  background-color: #1ea182;
  text-decoration: none;
}
.border-button.active {
  color: #ffffff;
  background-color: #1ea182;
}
.border-button.primary {
  color: #ffc411;
  border: 2px solid #ffc411;
}
.border-button.primary:hover {
  background-color: #ffc411;
  color: #ffffff;
}
.border-button.primary.active {
  background-color: #ffc411;
}
.blue-border-button {
  font-weight: 400;
  padding: 14px 32px;
  background-color: white;
  color: #30c2d9;
  border: 2px solid white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: none;
  text-decoration: none;
}
.blue-border-button:hover {
  color: #ffffff;
  background-color: #30c2d9;
  text-decoration: none;
}
.blue-border-button.active {
  color: #ffffff;
  background-color: #30c2d9;
}
.blue-border-button.bordered {
  color: white;
  border: 2px solid white;
  background-color: #30c2d9;
}
.blue-border-button.bordered:hover {
  background-color: white;
  color: #30c2d9;
}
.blue-border-button.bordered.active {
  background-color: white;
}
.yellow-border-button {
  font-weight: 400;
  padding: 14px 32px;
  background-color: white;
  color: #ffc411;
  border: 2px solid white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: none;
  text-decoration: none;
}
.yellow-border-button:hover {
  color: #ffffff;
  background-color: #ffc411;
  text-decoration: none;
}
.yellow-border-button.active {
  color: #ffffff;
  background-color: #ffc411;
}
.yellow-border-button.bordered {
  color: white;
  border: 2px solid white;
  background-color: #ffc411;
}
.yellow-border-button.bordered:hover {
  background-color: white;
  color: #ffc411;
}
.yellow-border-button.bordered.active {
  background-color: white;
}
.secondary-btn,
a.md-button.md-default-theme.md-raised.secondary-btn,
.md-button.md-default-theme.md-raised.secondary-btn {
  font-weight: 400;
  background-color: #1ea182;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: none;
  text-decoration: none;
  transition: all 400ms ease;
}
.secondary-btn:hover,
a.md-button.md-default-theme.md-raised.secondary-btn:hover,
.md-button.md-default-theme.md-raised.secondary-btn:hover {
  color: #ffffff;
  background-color: #26cca5;
  text-decoration: none;
}
.secondary-btn.copied,
a.md-button.md-default-theme.md-raised.secondary-btn.copied,
.md-button.md-default-theme.md-raised.secondary-btn.copied {
  background-color: transparent;
  color: #1ea182;
}
.secondary-btn.copied:hover,
a.md-button.md-default-theme.md-raised.secondary-btn.copied:hover,
.md-button.md-default-theme.md-raised.secondary-btn.copied:hover {
  background-color: transparent;
  color: #1ea182;
  cursor: default;
}
.transparent-btn,
a.md-button.md-default-theme.md-raised.transparent-btn,
.md-button.md-default-theme.md-raised.transparent-btn {
  font-weight: 400;
  background-color: transparent;
  color: #1ea182;
  text-transform: none;
  text-decoration: none;
}
.transparent-btn:hover,
a.md-button.md-default-theme.md-raised.transparent-btn:hover,
.md-button.md-default-theme.md-raised.transparent-btn:hover {
  background-color: #ffffff;
  text-decoration: none;
}
.transparent-btn.sign-in,
a.md-button.md-default-theme.md-raised.transparent-btn.sign-in,
.md-button.md-default-theme.md-raised.transparent-btn.sign-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  width: 50%;
  min-width: 0;
  padding-left: 36px;
}
.light-btn,
a.md-button.md-default-theme.md-raised.light-btn,
.md-button.md-default-theme.md-raised.light-btn {
  font-weight: 400;
  background-color: #ffffff;
  color: #1ea182;
  text-transform: none;
  text-decoration: none;
}
.light-btn:hover,
a.md-button.md-default-theme.md-raised.light-btn:hover,
.md-button.md-default-theme.md-raised.light-btn:hover {
  background-color: #f0f0f6;
  text-decoration: none;
}
.button-black {
  font-weight: 400;
  color: #ffffff;
  background-color: #000000;
}
.button-black:hover {
  font-weight: 400;
  color: #ffffff;
  background-color: #000000;
}
/***********************************
 Smaller than phone View
************************************/
.form-container {
  background-color: #ffffff;
  padding: 1rem 0;
  box-sizing: border-box;
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.form-container.blue-print-switch-container {
  padding-top: 0rem;
  padding-bottom: 3rem;
}
.form-container button {
  margin: 0;
  border: 0;
}
.form-container form {
  width: auto;
  margin: 0 0;
}
.form-container section {
  margin: 1rem 0;
}
.form-container .gray-text {
  color: #363b47;
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
}
.form-container .logo {
  width: 64px;
  height: 64px;
  margin-bottom: 16px;
}
.form-container textarea,
.form-container input,
.form-container input[type="text"],
.form-container input[type="password"],
.form-container input[type="datetime"],
.form-container input[type="datetime-local"],
.form-container input[type="date"],
.form-container input[type="month"],
.form-container input[type="time"],
.form-container input[type="week"],
.form-container input[type="number"],
.form-container input[type="email"],
.form-container input[type="url"],
.form-container input[type="search"],
.form-container input[type="tel"],
.form-container input[type="color"],
.form-container select {
  margin: 0 auto;
  display: block;
  padding: .5rem;
  height: auto;
  color: #363b47;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #f0f0f6;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 350px;
  font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;
  background-color: #ffffff;
}
.form-container textarea.error,
.form-container input.error,
.form-container input[type="text"].error,
.form-container input[type="password"].error,
.form-container input[type="datetime"].error,
.form-container input[type="datetime-local"].error,
.form-container input[type="date"].error,
.form-container input[type="month"].error,
.form-container input[type="time"].error,
.form-container input[type="week"].error,
.form-container input[type="number"].error,
.form-container input[type="email"].error,
.form-container input[type="url"].error,
.form-container input[type="search"].error,
.form-container input[type="tel"].error,
.form-container input[type="color"].error,
.form-container select.error {
  background-color: #ffe5e5;
  border-color: #e50000;
}
.form-container .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transform: scale(0.75);
}
.form-container .switch.tourSwitch {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}
.form-container .green {
  color: #1ea182;
}
.form-container .switch input {
  display: none;
}
.form-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
}
.form-container .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}
.form-container input:checked + .slider {
  background-color: #1ea182;
}
.form-container input:focus + .slider {
  box-shadow: 0 0 1px #1ea182;
}
.form-container input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.form-container .slider.round {
  border-radius: 34px;
}
.form-container .slider.round:before {
  border-radius: 50%;
}
.form-container input[type=checkbox].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.form-container input[type=checkbox].css-checkbox + label.css-label {
  padding-left: 32px;
  height: 22px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 400;
}
.form-container input[type=checkbox].css-checkbox:checked + label.css-label {
  background-position: 0 -22px;
}
.form-container label.css-label {
  background-image: url('../imgs/checkbox.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-container .search-icon {
  position: absolute;
  top: 0px;
  left: 0.75rem;
  width: 24px;
  height: 24px;
  margin-top: 8px;
}
.form-container .search-x {
  position: absolute;
  top: 8.5px;
  right: -webkit-calc(3.13157895%);
  right: calc(3.13157895%);
  opacity: 0.4;
  background-image: url(../imgs/x-black.svg);
  background-size: cover;
  width: 24px;
  height: 24px;
}
.form-container .search-icon-spacing {
  padding-left: 40px !important;
  padding-right: 20px !important;
  outline: none;
}
.error-msg {
  font-size: 1rem;
  color: #ff0000;
  margin: 1rem 4rem;
}
.error-msg:not(:empty) {
  background-color: #e50000;
  padding: 8px 8px 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #ffffff !important;
}
.error-msg:not(:empty) a {
  color: #ffffff;
  text-decoration: underline;
}
.error-msg:not(:empty) a:hover {
  color: #1ea182;
}
input[type="text"] {
  -webkit-appearance: none;
}
@media screen and (max-width: 768px) {
  .pow-how-gm-form {
    position: absolute;
  }
  .form-container.blue-print-switch-container {
    padding-top: 1rem;
    padding-bottom: 0rem;
    text-align: center;
  }
  .form-container.blue-print-switch-container .switch.col-sm-4 {
    width: 60px;
  }
  .form-container.blue-print-switch-container .col-sm-4 {
    max-width: 32%;
    width: 30%;
    min-width: 50px;
    display: inline-block;
    vertical-align: middle;
  }
}
.no-comment-link {
  display: block;
  margin-top: 1rem;
}
.footer-ui-view {
  position: relative;
  z-index: 1;
  background-color: transparent;
  padding-top: 64px;
}
.footer-ui-view.light-gray-background {
  background-color: #f0f0f6;
}
.footer-glu {
  height: 260px;
}
.footer-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: 0 auto;
}
.footer-container ul {
  list-style: none;
  padding-left: 0;
}
.footer-container ul li {
  list-style-type: none;
}
.footer-container ul li a {
  display: block;
  padding: 12px 0px;
}
.footer-container ul.socials {
  list-style: none;
  text-align: center;
  padding-left: 0;
}
.footer-container ul.socials li {
  display: inline-block;
  margin-right: 3rem;
}
.footer-container ul.socials li:last-child {
  margin-right: 0;
}
.footer-container ul.socials li img {
  width: 32px;
  height: 32px;
}
.footer-container #icon {
  position: relative;
  margin: 24px 0px;
  text-align: center;
}
.footer-container #icon img {
  height: 24px;
}
.footer-container #icon .dot {
  height: 8px;
  width: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #1ea182;
  display: inline-block;
}
.footer-container #icon + ul {
  margin-top: 0;
}
.footer-container ul.socials {
  margin: 0;
}
.profile-pic {
  width: 38px !important;
  height: 38px !important;
  background-image: url('../imgs/usericon-white.svg');
}
.mobile-menu-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3999;
}
#saved-list.flash {
  animation: mild-background-flash-w-border 0.4s ease both;
}
.search-bar {
  width: 30%;
  padding: 0 15px;
  display: inline-block;
}
.search-bar .form-container input[type="text"] {
  width: 100%;
}
.header-container {
  background-color: #1ea182;
  color: #ffffff;
  *zoom: 1;
  height: 58px;
  -webkit-box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4000;
  transition: height .2s ease;
}
.header-container:before,
.header-container:after {
  content: "";
  display: table;
}
.header-container:after {
  clear: both;
}
.header-container .no-results-found {
  padding: 2rem 2rem 0 2rem;
  text-align: center;
  color: #9ba1b3;
  font-weight: 100;
}
.header-container .no-results-found b {
  color: #000;
}
.header-container .inside-header-container {
  position: relative;
  margin: auto;
}
.header-container .hs-beacon {
  padding: 0 !important;
}
.header-container hr {
  border-width: 4px;
  border-color: rgba(0, 0, 0, 0.03);
}
.header-container .header-top-row-container {
  width: 82%;
  margin-left: 9%;
}
.header-container .header-menu {
  margin-top: 0px;
  display: inline-block;
  position: absolute;
  right: 0px;
}
.header-container .header-menu > li a:hover {
  color: #ffffff;
  text-decoration: none;
  border-bottom: calc(5px) solid #ffffff;
}
.header-container .header-menu li {
  display: inline-block;
  padding: 19px 0 0 0;
  /*> a:hover {
        color: @white;
        text-decoration: none;
        border-bottom: calc(@underline-height - 1px) solid #fff;
      }*/
}
.header-container .header-menu li .small-header-icon {
  width: 17px;
  height: 17px;
  position: relative;
  top: 3px;
  margin: 0 8px;
}
.header-container .header-menu li > a {
  color: #ffffff;
  font-size: 1rem;
  padding: 20px calc(18px) calc(14px);
  transition: border 100ms ease;
}
.header-container .header-menu li > a.current-page {
  padding: 20px calc(19px) calc(14px);
  border-bottom: calc(5px) solid #ffffff;
}
.header-container .header-menu li > a.invisible {
  opacity: 0;
}
.header-container .header-menu li > a.no-bord {
  border: none !important;
}
.header-container .header-menu li > a:active {
  text-decoration: none;
}
.header-container .header-menu li.my-account-item {
  max-height: 38px;
  padding-right: calc(38px);
  margin-left: 20px;
  cursor: pointer;
}
.header-container .header-menu li:first-child {
  margin-left: 0px;
}
.header-container .image-container {
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  padding: 0;
  border: none;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  right: 10px;
  overflow: hidden;
  z-index: 1;
  transition: all .4s ease;
}
.header-container .image-container .image-thumb {
  height: 38px;
  width: 38px;
  border: none;
  box-sizing: border-box;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-image: url('../imgs/usericon-white.svg');
  transition: all .4s ease;
}
.header-container .header-menu-item {
  display: inline-block;
}
.header-container .header-menu-item .header-monj-icon {
  cursor: pointer;
  transition: all 400ms ease;
  background-image: url("../imgs/monj-icon-white.svg");
  background-size: cover;
  width: 38px;
  height: 38px;
  margin: 10px;
}
.header-container .menu-item {
  display: inline-block;
}
.header-container .fa-search.mobile {
  display: none;
}
.header-container input[type="text"] {
  padding: 0;
  color: #ffffff;
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: normal;
  border: 0px solid #1e8f74;
  font-family: Arial, sans-serif;
  background-color: #1e8f74;
  height: 58px;
  width: 0px;
  transition: all 400ms ease;
  outline: none;
  margin: auto;
}
.header-container input[type="text"].active {
  width: 600px;
  padding: .5rem;
  border: 1px solid #1e8f74;
}
.header-container .search-bar {
  color: #ffffff;
  top: 0;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 0px;
  opacity: 0;
  padding: 0;
  visibility: hidden;
}
.header-container .search-bar.active {
  visibility: visible;
  display: block;
  width: 100%;
  opacity: 1;
}
.header-container .search-bar.active .fa {
  display: inline-block;
}
.header-container .search-bar .fa {
  font-size: 20px;
  margin: 0 calc(1.27777778%);
  position: relative;
  top: 4px;
  display: none;
}
.header-container .search-bar .fa.fa-times {
  cursor: pointer;
}
.header-container .search-bar .close-btn {
  position: relative;
  top: 2px;
  right: 0;
  padding: 20px;
}
.header-container .search-icon-header.mobile {
  color: #ffffff;
  font-size: 26px;
  margin: 16px;
  position: absolute;
  right: 40px;
  display: none;
}
.header-container .related-search-bar {
  transition: all .2s ease;
  height: auto;
  position: fixed;
  top: 58px;
  left: 14px;
  right: 0;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  width: 600px;
  color: #000;
  padding: 0 2.5% 2.5% 2.5%;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  max-height: calc(100% - 58px);
}
.header-container .related-search-bar h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: inline-block;
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 500;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.header-container .related-search-bar > a {
  display: inline-block;
}
.header-container .search-bar-item .activity-item {
  margin-left: 2.77777778%;
}
.header-container .search-bar-item .activity-item .background-cover {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.header-container .search-bar-item .activity-item h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.header-container .search-bar-item .activity-item p {
  color: #9ba1b3;
  margin: 0;
}
.header-container .search-bar-item .activity-item-text {
  margin-left: 0;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px - 1rem);
}
.header-container .search-bar-item .activity-item-text span {
  font-weight: 300;
}
.header-container .search-bar-item .activity-item-text span.bold {
  font-weight: 600;
}
.header-container .search-bar-item .activity-item-img {
  display: inline-block;
  vertical-align: top;
  margin: 0.25rem;
}
.header-container .search-bar-item .activity-item a {
  padding: 2.77777778% 0;
  color: #000;
  font-weight: 400;
}
.header-container .search-bar-item .activity-item a:hover {
  color: #000;
  text-decoration: none;
}
.header-container .drop-down {
  position: absolute;
  left: -112px;
  background-color: #1ea182;
  width: 150px;
  border-radius: 0 0 8px 8px;
  padding: 12px 0px 0px 0px;
  transition: all 200ms ease;
  visibility: visible;
  top: 29px;
  overflow: hidden;
}
.header-container .drop-down.invisible {
  height: 0px !important;
  visibility: hidden;
}
.header-container .drop-down li {
  text-align: left;
  padding: 8px 0;
  width: 100%;
}
.header-container .drop-down li a {
  padding: 8px;
  font-weight: 100;
}
.header-container .drop-down li a:hover {
  border-bottom: none;
}
.header-container .drop-down li.current-page {
  background-color: #09b68d;
  border-radius: 0px;
}
.header-container .drop-down li:hover {
  background-color: #09b68d;
  border: none !important;
  border-radius: 0px;
}
.header-container .drop-down li:last-child {
  border-radius: 0px 0px 8px 8px;
}
.header-container .mobile-header-menu {
  display: none;
}
#hs-beacon iframe {
  background: rgba(0, 0, 0, 0.85) !important;
}
.search-modal-background {
  position: fixed;
  background-color: #000;
  opacity: 0.4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  transition: all 400ms ease;
}
/***********************************
 Smaller than desktop View
************************************/
@media screen and (max-width: 1280px) {
  .header-container .inside-header-container {
    position: relative;
    margin: auto;
  }
}
/***********************************
 Smaller than tablet View
************************************/
@media screen and (max-width: 1023px) {
  .header-container .header-menu {
    display: none;
  }
  .header-container .fa-search.mobile {
    display: inline-block;
    color: #ffffff;
    font-size: 26px;
    margin: 16px;
    position: absolute;
    right: 40px;
  }
  .header-container .fa-search.mobile.invisible {
    opacity: 0;
  }
  .header-container a,
  .header-container a:hover,
  .header-container a:active,
  .header-container a:visited {
    text-decoration: none;
    font-weight: 300;
  }
  .header-container .hamburger-menu-icon {
    height: calc(26px);
    width: calc(26px);
    margin: 16px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 400ms ease;
  }
  .header-container .hamburger-menu-icon.invisible {
    opacity: 0;
  }
  .header-container .hamburger-menu-icon.flash {
    animation: bounce .8s;
  }
  .header-container input[type="text"].active {
    width: 85%;
  }
  .header-container .related-search-bar {
    width: 85%;
  }
  .header-container .search-icon-header.mobile {
    display: inline-block;
  }
  .mobile-header-menu {
    max-width: calc(748px);
    width: 26%;
    opacity: 0;
    background-color: #1ea182;
    display: block !important;
    position: absolute;
    z-index: 4001;
    right: -400px;
    top: 0;
    box-shadow: 0px 0px 5px rgba(55, 55, 55, 0.7);
    transition: all 200ms ease;
    padding: 2.63157895% 0px;
    height: 100vh;
  }
  .mobile-header-menu.active {
    width: 35%;
    min-width: 250px;
    max-width: 300px;
    opacity: 1;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .mobile-header-menu ol {
    margin: 0;
    padding: 0;
  }
  .mobile-header-menu ol.sub-menu li {
    margin-left: 20px;
  }
  .mobile-header-menu .menu-spacing {
    width: 100%;
    height: 20px;
    background-color: #1ea182 !important;
  }
  .mobile-header-menu li {
    text-align: left;
    list-style-type: none;
    padding: 0;
  }
  .mobile-header-menu li:first-child {
    margin-top: 15%;
  }
  .mobile-header-menu li.header-close-button {
    height: calc(57px);
    /*a {
          right: @standard-pad-interior-interior;
          top: @standard-pad-interior-interior;
        }*/
  }
  .mobile-header-menu li.header-close-button .close-btn {
    width: calc(57px);
    height: calc(57px);
  }
  .mobile-header-menu li > a {
    color: #ffffff;
    font-size: 1.25rem;
    padding-left: 20%;
    transition: all 200ms ease;
    line-height: 48px;
  }
  .mobile-header-menu li > a.current-page {
    font-weight: 500;
  }
  .mobile-header-menu li > a.invisible {
    opacity: 0;
  }
  .mobile-header-menu li > a:active {
    text-decoration: none;
  }
  .mobile-header-menu li.current-page {
    background-color: #09b68d;
    border: none !important;
    border-radius: 0px;
    width: 100%;
  }
  .mobile-header-menu li:hover {
    background-color: #09b68d;
    border: none !important;
    border-radius: 0px;
    width: 100%;
  }
  .enroll-team-up p {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header-container input[type="text"].active {
    width: calc(100% - 100px);
  }
  .header-container .search-bar .search-icon-header {
    position: absolute;
    top: 14px;
    left: 0;
  }
  .header-container .search-bar .close-btn {
    position: absolute;
    top: 4px;
  }
  .header-container .related-search-bar {
    width: 100%;
    left: 0;
    border-radius: 0;
    min-height: 100vh;
    padding-bottom: 5rem;
  }
}
.flowplayer {
  background-size: cover;
  background-position: center;
  background-color: transparent !important;
}
.flowplayer .fp-selected:before {
  content: "\2713";
  margin-right: .5rem;
}
.flowplayer .fp-menu {
  width: 12em;
}
.flowplayer .fp-share {
  display: none;
}
.flowplayer .fp-ui,
.flowplayer .fp-header {
  background-image: none !important;
}
.flowplayer .flowplayer-embed-container + .fp-ui {
  display: none;
}
.flowplayer .fp-player .flowplayer {
  display: none;
}
.media .flowplayer-embed-container {
  padding-bottom: 75% !important;
}
.media .vimeo-video > div:first-of-type {
  padding-top: 72.5% !important;
}
.modal-background {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  opacity: 0.5;
  cursor: pointer;
  transition: all .3s ease;
  visibility: visible;
}
.modal-background.hide {
  visibility: hidden;
}
/*.setup-trans {
  display: block !important;
  opacity: 0;
  transition: opacity 0.35s;

  &.trans-half {
    opacity: 0.5;
  }

  &.trans-full {
    opacity: 1;
  }
}*/
.popup-modal {
  z-index: 9999;
  max-width: 500px;
  min-width: 300px;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  position: fixed;
  visibility: hidden;
  overflow: hidden;
  transition: all .3s ease;
  opacity: 0;
  animation-name: pop-out;
  animation-duration: 0.3s;
  box-shadow: 0px 0px 5px rgba(55, 55, 55, 0.7);
}
.popup-modal.wide {
  width: 500px;
}
.popup-modal.wider {
  width: 600px;
}
.popup-modal.widest {
  width: 768px;
}
.popup-modal.active {
  visibility: visible;
  opacity: 1;
}
.popup-modal .drilldown {
  margin: 0;
  min-height: inherit;
  max-width: 768px;
  overflow-y: auto;
}
.popup-modal .drilldown > div {
  padding: 2rem;
}
.popup-modal header {
  position: relative;
}
.popup-modal .form-container {
  padding: 0;
  /*.error-msg {
      padding: 0;
    }*/
}
.popup-modal .form-container form {
  text-align: center;
}
.popup-modal .form-container form input,
.popup-modal .form-container form select {
  margin: 0 auto;
  width: 100%;
  outline: 0;
}
.popup-modal .form-container .right {
  margin-right: 1.5rem;
}
.popup-modal .form-container section p {
  padding: 1rem 0 0;
  margin: 0;
}
.popup-modal .popup-button-width {
  min-width: 100%;
  margin: 0 0 1rem;
}
.popup-modal .popup-text {
  color: black;
  margin-top: 0;
}
/***********************************
 Smaller than phone portrait View
************************************/
@media screen and (max-width: 500px) {
  .popup-modal {
    width: 100vw;
    border-radius: 0;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .popup-modal .drilldown {
    height: 100vh;
  }
  .popup-modal .drilldown > div {
    padding: 1rem;
  }
}
.capitalize {
  text-transform: capitalize;
}
.single-paged-lesson-completed {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4000;
  opacity: 0;
  transition: opacity 200ms ease;
}
.single-paged-lesson-completed.active {
  visibility: visible;
  opacity: 1;
}
.slider-container {
  background-color: white;
}
.slider-container .modal-background {
  height: 100vh;
}
.slider-container .slider-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  background-color: #1ea182;
  z-index: 999;
  transition: all 400ms ease;
}
.slider-container .slider-menu -webkit-scrollbar {
  display: none;
}
.slider-container .slider-menu.slide-out-of-view {
  transform: translate(0, -3rem);
}
.slider-container .slider-menu .master-title {
  color: #fff;
  text-align: center;
  height: 30px;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 100;
  margin: 0.5rem 4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 8rem);
  position: absolute;
}
.slider-container .slider-menu .close-btn {
  left: 0;
  right: auto;
}
.slider-container .slider-menu .parent-slider-menu {
  position: absolute;
  right: calc(3rem - 100vw);
  width: calc(100vw - 3rem);
  transition: all 300ms ease;
  z-index: 2;
}
.slider-container .slider-menu .parent-slider-menu.open {
  right: 0px;
}
.slider-container .slider-menu .parent-slider-menu .menu-container {
  width: 100%;
  display: inline-block;
  background-color: #1ea182;
}
.slider-container .slider-menu .parent-slider-menu .menu-container h2 {
  margin: 0px 1rem;
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: capitalize;
  height: 3rem;
  padding: 8px;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 3rem);
  background-color: #f0f0f6;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul {
  margin: 0;
  padding: 0;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li {
  list-style-type: none;
  margin: 0;
  padding: 0.75rem;
  font-size: 1rem;
  min-height: 3rem;
  transition: all 400ms ease;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li.active {
  background-color: white;
  padding-left: calc(0.5rem);
  border-left: solid 0.5rem #1ea182;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .number {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.5rem;
  color: black;
  font-weight: 500;
  padding: 0 1rem 0 0.5rem;
  width: 40px;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.video,
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.videopage {
  background-image: url(../imgs/play-green.svg);
  background-size: 88%;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.video.custom-page,
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.videopage.custom-page {
  background-image: url(../imgs/play-white-plain.svg);
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.tip {
  background-color: #1ea182;
  background-size: 70%;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.tippage,
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.iconpage {
  background-color: #1ea182;
  background-size: 45%;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.missing-image {
  background-image: url(../imgs/steps-icon.svg);
  background-color: #1ea182;
  background-size: cover;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .step-icon.questionpage {
  background-image: url(../imgs/question-icon.svg);
  background-color: #1ea182;
  background-size: 75%;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li .title {
  font-size: 1rem;
  padding: 0 1rem;
  font-weight: 100;
  color: black;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  height: 1.5rem;
  text-overflow: ellipsis;
  width: calc(100% - 6.5rem);
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li h4 {
  margin-top: 0;
  margin-bottom: 6px;
}
.slider-container .slider-menu .parent-slider-menu .menu-container .menu-list ul li p {
  margin-bottom: 0;
  margin-top: 0;
}
.slider-container .slider-menu .parent-slider-menu .menu-container #ingredients h4,
.slider-container .slider-menu .parent-slider-menu .menu-container #ingredients p {
  display: inline-block;
}
.slider-container .slider-menu .parent-slider-menu .menu-container #ingredients h2 {
  color: black;
  font-size: 1.75rem;
  margin-left: 0;
  padding-left: 0;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon {
  width: 3rem;
  height: 3rem;
  background-color: #1ea182;
  position: absolute;
  left: -3rem;
  top: 0;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .steps-icon {
  background-image: url(../imgs/steps-icon.svg);
  background-color: #1ea182;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 48px;
  width: 48px;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .steps-icon.close-btn {
  background-image: url(../imgs/x-white.svg);
  background-size: 100%;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .bar {
  height: 3px;
  width: 1.5rem;
  background-color: white;
  border-radius: 3px 3px 3px 3px;
  transition: all 300ms ease;
  position: absolute;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .top-burger {
  top: .8rem;
  left: 12px;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .top-burger.x {
  animation: top-burger-X 300ms both;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .middle-burger {
  top: 1.35rem;
  left: 12px;
  opacity: 1;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .middle-burger.x {
  opacity: 0;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .bottom-burger {
  top: 1.9rem;
  left: 12px;
}
.slider-container .slider-menu .parent-slider-menu .slider-menu-toggle-icon .bottom-burger.x {
  animation: bottom-burger-X 300ms both;
}
.slider-container .arrows {
  position: absolute;
  margin-top: 75%;
  top: 18px;
  left: 0;
  width: 100%;
  z-index: 997;
  visibility: hidden;
  overflow: hidden;
  transition: all 400ms ease;
}
.slider-container .arrows.fade-out {
  opacity: 0;
}
.slider-container .arrows .left-arrow {
  background-image: url(../imgs/arrow-left-black.svg);
  float: left;
  visibility: visible;
  background-position: 82%;
  position: relative;
  left: -32px;
}
.slider-container .arrows .right-arrow {
  background-image: url(../imgs/arrow-right-black.svg);
  float: right;
  visibility: visible;
  background-position: 18%;
  position: relative;
  right: -32px;
}
.slider-container .arrows .right-arrow.final {
  background-color: #ffc411;
  background-image: url(../imgs/arrow-right-white.svg);
}
.slider-container .arrows .right-arrow.no-orange {
  background-image: url(../imgs/arrow-right-black.svg);
  background-color: white;
}
.slider-container .arrows > div {
  /*width: 42px;
      height: 42px;
      background-size: 75%;
      background-repeat: no-repeat;
      background-position: center;*/
  width: 56px;
  height: 56px;
  background-size: 50%;
  background-repeat: no-repeat;
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  margin: 0 0.5rem;
  background-color: white;
}
.slider-container .modal-background {
  z-index: 1;
  opacity: 0.75;
}
.slider-container .chef {
  background-image: url(../imgs/chef.png) !important;
}
.slider-container .fitness {
  background-image: url(../imgs/fitness.png) !important;
}
.slider-container .funfact {
  background-image: url(../imgs/funfact.png) !important;
}
.slider-container .information {
  background-image: url(../imgs/information.png) !important;
}
.slider-container .meditation {
  background-image: url(../imgs/meditation.png) !important;
}
.slider-container .mindset {
  background-image: url(../imgs/mindset.png) !important;
}
.slider-container .mission {
  background-image: url(../imgs/mission.png) !important;
}
.slider-container .mood {
  background-image: url(../imgs/mood.png) !important;
}
.slider-container .nutrition {
  background-image: url(../imgs/nutrition.png) !important;
}
.slider-container .reminder {
  background-image: url(../imgs/reminder.png) !important;
}
.slider-container .safety {
  background-image: url(../imgs/safety.png) !important;
}
.slider-container .sleep {
  background-image: url(../imgs/sleep.png) !important;
}
.slider-container .stress {
  background-image: url(../imgs/stress.png) !important;
}
.slider-container .slider-content {
  width: 100%;
  margin-top: 3rem;
  transition: left 400ms, transform 400ms;
  display: inline-block;
  position: absolute;
  user-select: none;
  /*-webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);*/
}
.slider-container .slider-content.final-page {
  margin-top: 0;
}
.slider-container .slider-content.flush-with-top {
  margin-top: 0;
}
.slider-container .slider-content.left {
  left: -100%;
  overflow: hidden;
  height: calc(100vh - 3rem);
  text-align: left !important;
}
.slider-container .slider-content.center {
  left: 0;
}
.slider-container .slider-content.right {
  left: 100%;
  overflow: hidden;
  height: calc(100vh - 3rem);
  position: fixed;
  text-align: left !important;
}
.slider-container .slider-content .flowplayer {
  border-radius: 0;
}
.slider-container .slider-content .media {
  overflow: visible;
  /*max-height: ~'calc(100vh - 7rem)';
      overflow: hidden;*/
}
.slider-container .slider-content .media .play-button {
  background-image: url('../imgs/play-white-noring.svg');
  background-size: 25%;
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vw * 0.75);
  top: 0;
}
.slider-container .slider-content .media .video-embed .video-player {
  opacity: 0.000001;
  transition: opacity 400ms ease;
  transform: scale(1.8);
}
.slider-container .slider-content .media .video-embed .video-player.play {
  opacity: 1;
  transform: scale(1);
}
.slider-container .slider-content .media .image {
  padding-top: 75%;
  position: relative;
  width: 100%;
  /*@keyframes selected-image {
          0% {
            border: 3px solid rgba(255,255,255, 0);
            top: 3px;
            width: 3rem;
            height: 3rem;
          }
          100% {
            border: 3px solid rgba(255,255,255, 1);
            top: 6px;
            width: ~'calc(3rem + 6px)';
            height: ~'calc(3rem + 6px)';
          }
        }*/
}
.slider-container .slider-content .media .image .carousel-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f0f0f6;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1200ms ease;
}
.slider-container .slider-content .media .image .carousel-image.active {
  opacity: 1;
}
.slider-container .slider-content .media .image .carousel {
  position: relative;
  width: 100%;
  text-align: center;
  bottom: -1.5rem;
  position: absolute;
}
.slider-container .slider-content .media .image .carousel .slide-icon {
  border-radius: 100%;
  border: 3px solid rgba(255, 255, 255, 0);
  width: 3rem;
  height: 3rem;
  position: relative;
  top: 3px;
  background-color: white;
  display: inline-block;
  margin: 0 calc(0.5rem + 3px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  transition: all 200ms ease;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}
.slider-container .slider-content .media .image .carousel .slide-icon.active {
  border: 3px solid #ffffff;
}
.slider-container .slider-content .media .image .carousel .slide-icon.video-icon {
  background-image: url('../imgs/PlayArrow_GreenCircle.svg') !important;
  background-size: 116%;
}
.slider-container .slider-content .media .video {
  height: calc(100vw * 0.75);
  max-height: calc(100vh - 7rem);
  background-color: #f0f0f6;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slider-container .slider-content .media .video .flowplayer {
  transition: all 200ms ease;
}
.slider-container .slider-content .media .video .flowplayer .fp-ratio {
  transition: all 200ms ease;
}
.slider-container .slider-content .content {
  padding: 2rem 1.5rem;
}
.slider-container .slider-content .content term,
.slider-container .slider-content .content .link {
  color: #1ea182;
  font-weight: 600;
  cursor: pointer;
}
.slider-container .slider-content .content .subtitle {
  display: inline-block;
}
.slider-container .slider-content .content ie-list {
  display: inline-block;
}
.slider-container .slider-content .content ie-list div {
  background-color: #1ea182;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../imgs/ie-panel.svg);
  background-size: 70%;
  background-position-y: 30%;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 4px;
}
.slider-container .slider-content .content li {
  text-align: left;
  margin: .5rem 0;
  font-weight: 100;
}
.slider-container .slider-content .content h1 {
  margin: 0;
}
.slider-container .slider-content .final-step {
  margin: 1rem auto;
  text-align: center;
  width: 100%;
  visibility: hidden;
}
.slider-container .slider-content .final-step.active {
  visibility: visible;
  animation: bubble-in-opaque 400ms both;
  animation-delay: 400ms;
}
.slider-container .slider-content .final-step.note-max-width {
  max-width: 256px;
  min-width: 180px;
  margin-top: 2rem;
}
.slider-container .slider-content .note {
  background-color: #1ea182;
  min-height: calc(100vh - 3rem);
  height: 100%;
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}
.slider-container .slider-content .note.quiz,
.slider-container .slider-content .note.quiz-back {
  padding-top: 0;
}
.slider-container .slider-content .note.quiz-back .note-image {
  max-height: 200px;
  margin: 0 auto 1rem;
}
.slider-container .slider-content .note .note-image {
  max-width: 120px;
  max-height: 120px;
  min-width: 120px;
  min-height: 120px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto 1rem;
  background-image: url('../imgs/tip-icon.svg');
}
.slider-container .slider-content .note .note-content {
  width: 50%;
  text-align: center;
  max-width: 256px;
  min-width: 180px;
  margin: 16px auto 0rem;
}
.slider-container .slider-content .note .note-content a {
  color: white;
  font-weight: 600;
}
.slider-container .slider-content .note .note-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  padding: 0 3rem;
}
.slider-container .slider-content .quiz {
  padding: 1rem 2rem 0;
}
.slider-container .slider-content .quiz ul {
  padding: 0;
  margin: 0;
}
.slider-container .slider-content .quiz ul li {
  text-align: left;
  list-style: none;
  padding: 8px;
}
.slider-container .slider-content .quiz ul li.active .question-number {
  color: #1ea182;
}
.slider-container .slider-content .quiz ul li.active .question-number .number-border {
  background-color: white;
  color: #1ea182;
}
.slider-container .slider-content .quiz .quiz-image {
  max-width: 256px;
  max-height: 256px;
  min-width: 200px;
  min-height: 230px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.slider-container .slider-content .quiz .question-number {
  float: none;
  display: inline-block;
  vertical-align: middle;
  color: white;
  width: 28px;
  height: 28px;
  text-align: center;
  position: relative;
}
.slider-container .slider-content .quiz .question-number .centered-number {
  position: absolute;
  top: -50%;
  left: -50%;
  text-align: center;
  width: 28px;
  height: 28px;
  z-index: 2;
  transform: translate(50%, 60%);
}
.slider-container .slider-content .quiz .question-number .number-border {
  position: absolute;
  border: 2px solid white;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
}
.slider-container .slider-content .quiz .question-answer {
  margin-left: 0.25rem;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 50px);
}
.slider-container .slider-content .quiz .quiz-title {
  max-width: 460px;
  margin: 0 auto;
  color: white;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: left;
  line-height: normal;
  padding: 0 1rem;
  text-transform: none;
  letter-spacing: normal;
}
.slider-container .slider-content .quiz .quiz-title.center {
  text-align: center;
}
.slider-container .slider-content .quiz .quiz-answers {
  text-align: center;
  max-width: 460px;
  margin: 16px auto 1rem;
  padding: 0 1rem;
}
.slider-container .slider-content .quiz .open-ended-answer {
  max-width: 460px;
  margin-top: 1rem;
  /*padding: 0 1rem;
        margin: 1rem auto 0;*/
}
.slider-container .slider-content .quiz .open-ended-answer textarea {
  width: 100%;
  height: 8rem;
  border-radius: 8px;
  color: #1ea182;
  padding: .25rem .5rem;
}
.slider-container .slider-content .quiz .open-ended-answer textarea[disabled="disabled"] {
  cursor: pointer;
  color: #9ba1b3;
}
.slider-container .slider-content .quiz .submit {
  margin: 1rem auto;
  text-align: center;
}
.slider-container .slider-content .quiz .submit .md-button {
  border: 2px solid white;
}
.slider-container .slider-content .quiz-back {
  width: 100%;
}
.slider-container .slider-content .quiz-back .no-answer {
  background-image: url('../imgs/thanks-for-sharing.svg');
}
.slider-container .slider-content .quiz-back .correct-answer {
  background-image: url('../imgs/correct-answer.svg');
}
.slider-container .slider-content .quiz-back .wrong-answer {
  background-image: url('../imgs/incorrect-answer.svg');
}
/*.flip-container {
  .back {
    background-color: @tertiary-color !important;
  }
}*/
.completed-guide {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}
.completed-guide .recipe-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.4;
}
.completed-guide .ribbon .ribbon-container {
  opacity: 0.9;
  z-index: 1;
  position: absolute;
  top: 0;
  right: calc(50vw - 104px);
  margin: 0 auto;
  text-align: center;
  width: 208px;
  background-color: #1ea182;
  animation: moveFromTop 400ms ease both;
  animation-delay: 200ms;
  color: white;
}
.completed-guide .ribbon .ribbon-container h1 {
  color: white;
}
.completed-guide .ribbon .ribbon-container .title {
  padding: 0.5rem;
  margin-top: 1rem;
}
.completed-guide .ribbon .ribbon-container .survey-buttons {
  margin-top: 0rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
}
.completed-guide .ribbon .ribbon-container .survey-buttons .ribbon-btn {
  width: 45%;
  height: 170px;
  text-align: center;
  display: inline-block;
  border: 3px solid transparent;
  background-position: center 12px;
  background-size: 70% auto;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.completed-guide .ribbon .ribbon-container .survey-buttons .ribbon-btn.left-img {
  background-image: url('../imgs/fun-white.svg');
}
.completed-guide .ribbon .ribbon-container .survey-buttons .ribbon-btn.right-img {
  background-image: url('../imgs/not-fun-white.svg');
}
.completed-guide .ribbon .ribbon-container .survey-buttons .ribbon-btn:hover {
  border: 3px solid white;
  background-color: rgba(255, 255, 255, 0.2);
}
.completed-guide .ribbon .ribbon-container .survey-buttons .ribbon-btn h1 {
  position: relative;
  top: 128px;
  margin: 0 auto;
}
.completed-guide .ribbon .ribbon-container .ribbon-bottom {
  position: absolute;
  width: 100%;
  bottom: -110px;
  height: 110px;
  border-radius: 0 0 50% 50%;
  background-color: #1ea182;
}
.completed-guide .ribbon .ribbon-container .ribbon-bottom p {
  margin: 15px auto;
  text-align: right;
  padding: 15px 1rem;
  font-size: 1rem;
  font-weight: 100;
}
.completed-guide .ribbon .ribbon-container .ribbon-bottom .close-btn {
  left: 4px;
  top: 18px;
  width: 38px;
}
.empty-video-element {
  height: calc(.5265 * 100vw);
  max-height: 446px;
  width: 100%;
  background-color: #f0f0f6;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}
.content-section-right p {
  margin: 0;
}
.recipe-message-sent {
  text-align: center;
}
.recipe-message-sent div {
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
}
@media screen and (min-width: 500px) {
  .slider-container {
    /*.slider-menu {
      .parent-slider-menu {
        &.open {
          right: -17px;
        }
      }
    }*/
  }
  .slider-container .slider-content .note .note-image {
    margin: 0 auto 2rem;
  }
  .slider-container .slider-content .quiz {
    text-align: left;
  }
  .slider-container .slider-content .quiz .question-answer {
    margin-left: 0.5rem;
    text-align: left;
  }
}
/***********************************
 larger than phone view
************************************/
@media screen and (min-width: 768px) {
  .slider-container .slider-content .quiz .quiz-image {
    margin-bottom: 2rem;
  }
  .slider-container .slider-content .content {
    display: inline-block;
    vertical-align: top;
    width: 39%;
    padding: 1rem;
    overflow-x: auto;
    max-height: calc(100vh - 7rem);
    -webkit-overflow-scrolling: touch;
  }
  .slider-container .slider-content .content .note {
    height: calc(100vh - 7rem);
  }
  .slider-container .slider-content .note {
    min-height: calc(100vh - 3rem);
  }
  .slider-container .slider-content .note.quiz-back .note-image {
    max-height: 236px;
  }
  .slider-container .slider-content .flowplayer .fp-controls {
    padding: 0;
  }
  .slider-container .slider-content .media {
    display: inline-block;
    vertical-align: top;
    width: 60%;
    position: relative;
    z-index: 10;
  }
  .slider-container .slider-content .media .image {
    padding-top: 0;
    height: calc(60vw * 0.75);
    max-height: calc(100vh - 7rem);
  }
  .slider-container .slider-content .media .image .carousel-image {
    border-radius: 0 0 8px 0;
    background-color: #f0f0f6;
  }
  .slider-container .slider-content .media .play-button {
    height: 100%;
  }
  .slider-container .slider-content .media .video {
    height: calc(60vw * 0.75);
    max-height: calc(100vh - 7rem);
    background-color: #f0f0f6;
    border-radius: 0 0 8px 0;
  }
  .slider-container .slider-content .media .video .flowplayer {
    border-radius: 0 0 8px 0;
  }
  .slider-container .slider-menu .parent-slider-menu {
    right: -500px;
    width: 500px;
  }
  .slider-container .arrows {
    position: fixed;
    margin: 0;
    top: inherit;
    bottom: 0;
    height: 4rem;
  }
  .slider-container .arrows .left-arrow {
    background-image: url(../imgs/arrow-left-white.svg);
    background-position: center;
    position: relative;
    left: 0;
  }
  .slider-container .arrows .left-arrow.note {
    background-image: url(../imgs/arrow-left-black.svg);
    background-color: white;
  }
  .slider-container .arrows .right-arrow {
    background-image: url(../imgs/arrow-right-white.svg);
    background-position: center;
    position: relative;
    right: 0;
  }
  .slider-container .arrows .right-arrow.note {
    background-image: url(../imgs/arrow-right-black.svg);
    background-color: white;
  }
  .slider-container .arrows div {
    width: 42px;
    height: 42px;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0.5rem;
    width: 3rem;
    height: 3rem;
    background-color: #1ea182;
  }
}
@media screen and (min-width: 1023px) {
  .empty-video-element {
    height: calc(.5265 * 67vw);
    width: 67%;
    display: inline-block;
  }
}
.save {
  background: url('../imgs/save-unselected.svg');
  background-size: cover;
}
.save .selected {
  background: url('../imgs/save-selected.svg');
  background-size: cover;
}
.i-did-it {
  background: url('../imgs/didit-unselected.svg');
  background-size: cover;
}
.i-did-it .selected,
.i-did-it.complete {
  background: url('../imgs/didit-selected.svg');
  background-size: cover;
}
.shopping-list-icon {
  background: url('../imgs/Consolidated_Shopping_List_Icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
}
.shopping-list-icon.white {
  background: url('../imgs/Consolidated_Shopping_List_Icon_White.svg');
  background-size: contain;
}
.challenge-check-mark {
  background: url('../imgs/check_circle_empty_.svg');
  background-size: cover;
}
.challenge-check-mark .selected,
.challenge-check-mark.complete {
  background: url('../imgs/check_circle_checked.svg');
  background-size: cover;
}
.exclamation-circle {
  background: url('../imgs/Exclamation_Circle_Icon.svg');
  background-size: cover;
}
.refresh-mission {
  background: url('../imgs/new_mission_temp.JPG');
  background-size: cover;
  background-position: center center;
}
.thin-share-icon {
  background: url('../imgs/Share_Icon_Thin.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.groceries-icon {
  background: url('../imgs/GroceryBag_Icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.print-icon {
  background: url('../imgs/Printer_Icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.search-icon {
  background-image: url('../imgs/Search_icon-black.svg');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none !important;
  width: 21px;
  height: 21px;
  margin-top: 10px;
  margin-left: 0px;
}
.search-icon-header {
  background-image: url('../imgs/Search_icon-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  margin: 0px 18px 0 14px;
  padding: 14px !important;
}
.hamburger-menu-icon {
  background-image: url('../imgs/WhiteHumburgerMenu.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.close-btn {
  background-image: url('../imgs/x-black.svg');
}
.close-btn.contrast {
  background-image: url('../imgs/x-white.svg');
}
.close-btn.shadow {
  background-image: url('../imgs/x-shadow.svg');
}
.close-btn.shadow-white {
  background-image: url(../imgs/x-shadow-white.svg);
}
.back-btn {
  background-size: 24px;
  background-image: url('../imgs/arrow-left-black.svg');
}
.back-btn.contrast {
  background-image: url('../imgs/arrow-left-white.svg');
}
.corner {
  width: 100%;
  padding-bottom: 100%;
  background-image: url('../imgs/recipe-corner.svg');
  background-size: cover;
}
.corner.gray {
  background-image: url('../imgs/recipe-corner-gray.svg');
}
.arrow-up-right {
  background-image: url('../imgs/Orientation Bubble Arrow_up_right.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  top: -45px;
}
.arrow-up-left {
  background-image: url('../imgs/Orientation Bubble Arrow_up_left.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  top: -45px;
}
.arrow-right-up {
  background-image: url('../imgs/Orientation Bubble Arrow_right_up.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  right: -61px;
}
.arrow-right-down {
  background-image: url('../imgs/Orientation Bubble Arrow_right_down.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  right: -61px;
}
.arrow-left-down {
  background-image: url('../imgs/Orientation Bubble Arrow_left_down.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  left: -61px;
}
.arrow-left-up {
  background-image: url('../imgs/Orientation Bubble Arrow_left_up.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  left: -61px;
}
.arrow-down-right {
  background-image: url('../imgs/Orientation Bubble Arrow_down_right.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  bottom: -45px;
}
.arrow-down-left {
  background-image: url('../imgs/Orientation Bubble Arrow_down_left.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  bottom: -45px;
}
.arrow-right-green {
  background-image: url('../imgs/arrow-right-green.svg');
  height: 13px;
  width: 13px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  display: inline-block;
}
/***********************************************
MISC ICONS
***********************************************/
.upload-cam {
  background-image: url('../imgs/Camera for upload image.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.up-arrow-gray {
  background-image: url('../imgs/Up Arrow_Gray.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.down-arrow-gray {
  background-image: url('../imgs/Down Arrow_gray.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.up-angle {
  background-image: url('../imgs/Up Arrow Box.svg') !important;
}
.insp-cam {
  background-image: url('../imgs/Inspiration Camera_Gray.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 1.75rem;
  height: 1.55rem;
  display: inline-block;
  vertical-align: bottom;
  border: 1px solid rgba(0, 0, 0, 0);
}
.insp-cam.dark {
  background-image: url('../imgs/Inspiration Camera_Dark.svg') !important;
}
/***********************************************
Badge Icons
***********************************************/
.placeholder-badge {
  background-image: url('../imgs/placeholder-badge.svg') !important;
}
.savvy-sage-icon {
  background-image: url('../imgs/SAVVY_SAGE_ICON.svg');
}
.saute-badge {
  background-image: url('../imgs/saute-badge-trans.svg') !important;
}
.saute-badge.filled {
  background-image: url('../imgs/saute-badge.svg') !important;
}
.saute-badge.white {
  background-image: url('../imgs/saute-badge-white.svg') !important;
}
.stirfry-badge {
  background-image: url('../imgs/stirfry-badge-trans.svg') !important;
}
.stirfry-badge.filled {
  background-image: url('../imgs/stirfry-badge.svg') !important;
}
.stirfry-badge.white {
  background-image: url('../imgs/stirfry-badge-white.svg') !important;
}
.roast-badge {
  background-image: url('../imgs/roast-badge-trans.svg') !important;
}
.roast-badge.filled {
  background-image: url('../imgs/roast-badge.svg') !important;
}
.roast-badge.white {
  background-image: url('../imgs/roast-badge-white.svg') !important;
}
.grill-badge {
  background-image: url('../imgs/grill-badge-trans.svg') !important;
}
.grill-badge.filled {
  background-image: url('../imgs/grill-badge.svg') !important;
}
.grill-badge.white {
  background-image: url('../imgs/grill-badge-white.svg') !important;
}
.poach-badge {
  background-image: url('../imgs/placeholder-badge.svg') !important;
}
.braise-badge {
  background-image: url('../imgs/placeholder-badge.svg') !important;
}
.wellness-badge {
  background-image: url('../imgs/wellness-badge-trans.svg') !important;
}
.wellness-badge.filled {
  background-image: url('../imgs/wellness-badge.svg') !important;
}
.wellness-badge.white {
  background-image: url('../imgs/wellness-badge-white.svg') !important;
}
.social-badge {
  background-image: url('../imgs/social-badge-trans.svg') !important;
}
.social-badge.filled {
  background-image: url('../imgs/social-badge.svg') !important;
}
.social-badge.white {
  background-image: url('../imgs/social-badge-white.svg') !important;
}
.curious-badge {
  background-image: url('../imgs/curious-badge-trans.svg') !important;
}
.curious-badge.filled {
  background-image: url('../imgs/curious-badge.svg') !important;
}
.curious-badge.white {
  background-image: url('../imgs/curious-badge-white.svg') !important;
}
.prep-badge {
  background-image: url('../imgs/cook-badge-trans.svg') !important;
}
.prep-badge.filled {
  background-image: url('../imgs/cook-badge.svg') !important;
}
.prep-badge.white {
  background-image: url('../imgs/cook-badge-white.svg') !important;
}
.simmer-badge {
  background-image: url('../imgs/simmer-badge-trans.svg');
}
.simmer-badge.filled {
  background-image: url('../imgs/simmer-badge.svg');
}
.simmer-badge.white {
  background-image: url('../imgs/simmer-badge-white.svg') !important;
}
.mindful-badge {
  background-image: url('../imgs/mindful-badge-trans.svg') !important;
}
.mindful-badge.filled {
  background-image: url('../imgs/mindful-badge.svg') !important;
}
.mindful-badge.white {
  background-image: url('../imgs/mindful-badge-white.svg') !important;
}
.addicted-challenge {
  background-image: url('../imgs/ORANGE_GREEN_CHALLENGE.svg') !important;
}
.challenge-badge {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON_ONLY.svg');
}
.challenge-badge.filled {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON.svg');
}
.generic-challenge-badge {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON_ONLY.svg');
}
.generic-challenge-badge.filled {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON.svg');
}
.knife1-badge {
  background-image: url('../imgs/knife1-badge-trans.svg') !important;
}
.knife1-badge.filled {
  background-image: url('../imgs/knife1-badge.svg') !important;
}
.knife1-badge.white {
  background-image: url('../imgs/knife1-badge-white.svg') !important;
}
.knife2-badge {
  background-image: url('../imgs/knife2-badge-trans.svg') !important;
}
.knife2-badge.filled {
  background-image: url('../imgs/knife2-badge.svg') !important;
}
.knife2-badge.white {
  background-image: url('../imgs/knife2-badge-white.svg') !important;
}
.knife3-badge {
  background-image: url('../imgs/knife3-badge-trans.svg') !important;
}
.knife3-badge.filled {
  background-image: url('../imgs/knife3-badge.svg') !important;
}
.knife3-badge.white {
  background-image: url('../imgs/knife3-badge-white.svg') !important;
}
.lessons-0-badge {
  background-image: url('../imgs/lessons-0-badge-trans.svg') !important;
}
.lessons-0-badge.filled {
  background-image: url('../imgs/lessons-0-badge.svg') !important;
}
.lessons-0-badge.white {
  background-image: url('../imgs/lessons-0-badge-white.svg') !important;
}
.lessons-1-badge {
  background-image: url('../imgs/lessons-1-badge-white.svg') !important;
}
.lessons-1-badge.filled {
  background-image: url('../imgs/lessons-1-badge.svg') !important;
}
.lessons-1-badge.white {
  background-image: url('../imgs/lessons-1-badge-white.svg') !important;
}
.lessons-2-badge {
  background-image: url('../imgs/lessons-2-badge-trans.svg') !important;
}
.lessons-2-badge.filled {
  background-image: url('../imgs/lessons-2-badge.svg') !important;
}
.lessons-2-badge.white {
  background-image: url('../imgs/lessons-2-badge-white.svg') !important;
}
.lessons-3-badge {
  background-image: url('../imgs/lessons-3-badge-trans.svg') !important;
}
.lessons-3-badge.filled {
  background-image: url('../imgs/lessons-3-badge.svg') !important;
}
.lessons-3-badge.white {
  background-image: url('../imgs/lessons-3-badge-white.svg') !important;
}
.lessons-4-badge {
  background-image: url('../imgs/lessons-4-badge-trans.svg') !important;
}
.lessons-4-badge.filled {
  background-image: url('../imgs/lessons-4-badge.svg') !important;
}
.lessons-4-badge.white {
  background-image: url('../imgs/lessons-4-badge-white.svg') !important;
}
.lessons-5-badge {
  background-image: url('../imgs/lessons-5-badge-trans.svg') !important;
}
.lessons-5-badge.filled {
  background-image: url('../imgs/lessons-5-badge.svg') !important;
}
.lessons-5-badge.white {
  background-image: url('../imgs/lessons-5-badge-white.svg') !important;
}
.lessons-6-badge {
  background-image: url('../imgs/lessons-6-badge-trans.svg') !important;
}
.lessons-6-badge.filled {
  background-image: url('../imgs/lessons-6-badge.svg') !important;
}
.lessons-6-badge.white {
  background-image: url('../imgs/lessons-6-badge-white.svg') !important;
}
.levelup-icon {
  background-image: url('../imgs/Level_Up_Icon.svg') !important;
}
.levelup-icon.gray {
  background-image: url('../imgs/Level_Up_Icon_Gray.svg') !important;
}
.monj-icon {
  background-image: url('../imgs/monj-icon.svg') !important;
}
.monj-icon.white {
  background-image: url('../imgs/monj-icon-white.svg') !important;
}
.go-challenge-badge {
  background-image: url('../imgs/ORANGE_GREEN_ICON_ONLY.svg');
}
.go-challenge-badge.filled {
  background-image: url('../imgs/ORANGE_GREEN_CHALLENGE.svg');
}
.together-challenge-badge {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON_ONLY.svg');
}
.together-challenge-badge.filled {
  background-image: url('../imgs/GENERIC_CHALLENGE_ICON.svg');
}
.points-bulb {
  background-image: url('../imgs/Monji_Carrot.svg') !important;
}
.experience-icon {
  background-color: #ffc411;
  border-radius: 100%;
}
.together-challenge-badge {
  background-image: url('../imgs/together-badge-icon-only.svg');
}
.together-challenge-badge.filled {
  background-image: url('../imgs/together-challenge-badge.svg');
}
.challenge-completed-ribbon {
  background-size: cover;
  width: 125px;
  height: 100px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}
.challenge-completed-ribbon.en {
  background-image: url('../imgs/Challenge Complete_Ribbon.svg');
}
.challenge-completed-ribbon.es {
  background-image: url('../imgs/Challenge Complete_Ribbon_ES.svg');
}
.help-icon {
  background-image: url('../imgs/Help_Icon_for_Header.svg');
  background-size: cover;
}
.challenge-icon {
  background-image: url('../imgs/challenge.svg');
}
.plus-circle-icon {
  background-image: url('../imgs/invite_plus_circle.svg') !important;
  background-size: cover;
}
.plus-circle-icon.gray {
  background-image: url('../imgs/Plus_Circle_Icon_Gray.svg') !important;
}
.powerup-powerup {
  background-image: url('../imgs/powerup-powerup.svg');
}
.powerup-secretweapon {
  background-image: url('../imgs/powerup-secretweapon.svg');
}
.flav-frame-icon {
  background-image: url('../imgs/FlavorFramework_icon.svg');
}
.fearless-foodie-icon {
  background-image: url('../imgs/Fearless Foodie PP_Color.svg');
}
.overview-steps-icon {
  background-image: url('../imgs/Steps_icon.svg');
}
.vegetarian-image {
  background-image: url('../imgs/Intro-Tour_Veggie.jpg');
}
.red-meat-image {
  background-image: url('../imgs/Intro-Tour_No-Red-Meat.jpg');
}
.gluten-image {
  background-image: url('../imgs/Intro-Tour_Gluten-Free.jpg');
}
.dairy-image {
  background-image: url('../imgs/Intro-Tour_Dairy-Free.jpg');
}
.low-sugar-image {
  background-image: url('../imgs/Intro-Tour_Low-Sugar.jpg');
}
.correct-answer {
  background-image: url('../imgs/correct-answer.svg');
}
.incorrect-answer {
  background-image: url('../imgs/incorrect-answer.svg');
}
.question-icon {
  background-image: url('../imgs/question-icon.svg');
}
.steps-icon {
  background-image: url('../imgs/steps-icon.svg');
}
.thanks-for-sharing {
  background-image: url('../imgs/thanks-for-sharing.svg');
}
.tippage {
  background-image: url('../imgs/tip-icon.svg');
}
.quiz-carrot {
  background-image: url('../imgs/Carrot.svg');
}
.quiz-chef-hat {
  background-image: url('../imgs/Chef Hat.svg');
}
.quiz-circle {
  background-image: url('../imgs/Circle.svg');
}
.quiz-citrus {
  background-image: url('../imgs/Citrus.svg');
}
.slider-completed {
  background-image: url('../imgs/Lesson_Complete_Circle_ONLY.svg');
}
.quiz-leaf1 {
  background-image: url('../imgs/Leaf1.svg');
}
.quiz-leaf2 {
  background-image: url('../imgs/Leaf2.svg');
}
.quiz-plus-sign {
  background-image: url('../imgs/Plus Sign.svg');
}
.quiz-spoon {
  background-image: url('../imgs/Spoon.svg');
}
.button-arrow {
  background-image: url('../imgs/arrow-drilldown-white.svg');
  background-size: cover;
  position: absolute;
  right: 1rem;
  top: 14px;
  width: 20px;
  height: 20px;
}
/***********************************************
FLAVOR FRAMEWORK IMAGES
***********************************************/
.bitter-image {
  background-image: url('../imgs/Bitter_Small.svg');
}
.bitter-image.full {
  background-image: url('../imgs/Bitter_Full.svg');
}
.bitter-image.es {
  background-image: url('../imgs/ES_Amargo_Small.svg');
}
.bitter-image.es.full {
  background-image: url('../imgs/ES_Amargo_Full.svg');
}
.bitter-header {
  background-image: url('../imgs/Bitter_header.svg');
}
.salty-image {
  background-image: url('../imgs/Salty_Small.svg');
}
.salty-image.full {
  background-image: url('../imgs/Salty_Full.svg');
}
.salty-image.es {
  background-image: url('../imgs/ES_Salado_Small.svg');
}
.salty-image.es.full {
  background-image: url('../imgs/ES_Salado_Full.svg');
}
.salty-header {
  background-image: url('../imgs/Salty_header.svg');
}
.sour-image {
  background-image: url('../imgs/Sour_Small.svg');
}
.sour-image.full {
  background-image: url('../imgs/Sour_Full.svg');
}
.sour-image.es {
  background-image: url('../imgs/ES_Agrio_Small.svg');
}
.sour-image.es.full {
  background-image: url('../imgs/ES_Agrio_Full.svg');
}
.sour-header {
  background-image: url('../imgs/Sour_header.svg');
}
.spicy-image {
  background-image: url('../imgs/Spicy_Small.svg');
}
.spicy-image.full {
  background-image: url('../imgs/Spicy_Full.svg');
}
.spicy-image.es {
  background-image: url('../imgs/ES_Picante_Small.svg');
}
.spicy-image.es.full {
  background-image: url('../imgs/ES_Picante_Full.svg');
}
.spicy-header {
  background-image: url('../imgs/Spicy_header.svg');
}
.sweet-image {
  background-image: url('../imgs/Sweet_Small.svg');
}
.sweet-image.full {
  background-image: url('../imgs/Sweet_Full.svg');
}
.sweet-image.es {
  background-image: url('../imgs/ES_Dulce_Small.svg');
}
.sweet-image.es.full {
  background-image: url('../imgs/ES_Dulce_Full.svg');
}
.sweet-header {
  background-image: url('../imgs/Sweet_header.svg');
}
.umami-image {
  background-image: url('../imgs/Umami_Small.svg');
}
.umami-image.full {
  background-image: url('../imgs/Umami_Full.svg');
}
.umami-image.es {
  background-image: url('../imgs/ES_Umami_Small.svg');
}
.umami-image.es.full {
  background-image: url('../imgs/ES_Umami_Full.svg');
}
.umami-header {
  background-image: url('../imgs/Umami_header.svg');
}
.ff-video-icon {
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../imgs/Video_Icon_3.svg');
}
.ff-gray-circle {
  background-image: url('../imgs/FF_Grey_Circle.svg');
}
/***********************************************
DEEP DIVES BADGE ICONS
***********************************************/
.modules-1-badge {
  background-image: url('../imgs/essentials-1-badge-trans.svg');
}
.modules-1-badge.white {
  background-image: url('../imgs/modules-1-badge-white.svg');
}
.modules-1-badge.filled {
  background-image: url('../imgs/modules-1-badge.svg') !important;
}
.modules-2-badge {
  background-image: url('../imgs/essentials-2-badge-trans.svg');
}
.modules-2-badge.white {
  background-image: url('../imgs/modules-2-badge-white.svg');
}
.modules-2-badge.filled {
  background-image: url('../imgs/modules-2-badge.svg') !important;
}
.modules-3-badge {
  background-image: url('../imgs/essentials-3-badge-trans.svg');
}
.modules-3-badge.white {
  background-image: url('../imgs/modules-3-badge-white.svg');
}
.modules-3-badge.filled {
  background-image: url('../imgs/modules-3-badge.svg') !important;
}
/***********************************************
POWER UP BADGE ICONS
***********************************************/
.breakfast-badge {
  background-image: url('../imgs/breakfast-badge-trans.svg');
}
.breakfast-badge.white {
  background-image: url('../imgs/breakfast-badge-white.svg');
}
.breakfast-badge.filled {
  background-image: url('../imgs/breakfast-badge.svg') !important;
}
.lunch-badge {
  background-image: url('../imgs/lunch-badge-trans.svg');
}
.lunch-badge.white {
  background-image: url('../imgs/lunch-badge-white.svg');
}
.lunch-badge.filled {
  background-image: url('../imgs/lunch-badge.svg') !important;
}
.snack-badge {
  background-image: url('../imgs/snack-badge-trans.svg');
}
.snack-badge.white {
  background-image: url('../imgs/snack-badge-white.svg');
}
.snack-badge.filled {
  background-image: url('../imgs/snack-badge.svg') !important;
}
.dinner-badge {
  background-image: url('../imgs/dinner-badge-trans.svg');
}
.dinner-badge.white {
  background-image: url('../imgs/dinner-badge-white.svg');
}
.dinner-badge.filled {
  background-image: url('../imgs/dinner-badge.svg') !important;
}
/***********************************************
CHEF HAT ICONS
***********************************************/
.super-chef-gray {
  background-image: url('../imgs/Super Chef_Gray.svg');
}
.super-chef-pp-gray {
  background-image: url('../imgs/Super Chef PP_Gray.svg');
}
.super-chef-p-gray {
  background-image: url('../imgs/Super Chef P_Gray.svg');
}
.newbie-gray {
  background-image: url('../imgs/Newbie_Gray.svg');
}
.newbie-color {
  background-image: url('../imgs/Newbie_Color.svg');
}
.newbie-color path:last-child {
  stroke: #1ea182 !important;
}
.monj-master-levels-gray {
  background-image: url('../imgs/Monj Master Levels_Gray.svg');
}
.monj-master-filled-gray {
  background-image: url('../imgs/Monj Master Filled_Gray.svg');
}
.monj-master-filled {
  background-image: url('../imgs/Monj Master_Filled.svg');
}
.monj-master-filled path:last-child {
  stroke: #1ea182 !important;
}
.fearless-foodie-gray {
  background-image: url('../imgs/Fearless Foodie_Gray.svg');
}
.fearless-foodie-pp-gray {
  background-image: url('../imgs/Fearless Foodie PP_Gray.svg');
}
.fearless-foodie-p-gray {
  background-image: url('../imgs/Fearless Foodie P_Gray.png');
}
.fearless-foodie-pp-color {
  background-image: url('../imgs/Fearless Foodie PP_Color.svg');
}
.fearless-foodie-pp-color path:last-child {
  stroke: #30c2d9 !important;
}
.fearless-foodie-p-color {
  background-image: url('../imgs/Fearless Foodie P_Color.svg');
}
.fearless-foodie-p-color path:last-child {
  stroke: #30c2d9 !important;
}
.fearless-foodie-color {
  background-image: url('../imgs/Fearless Foodie_Color.svg');
}
.fearless-foodie-color path:last-child {
  stroke: #30c2d9 !important;
}
.confident-cook-gray {
  background-image: url('../imgs/Confident Cook_Gray.svg');
}
.confident-cook-pp-gray {
  background-image: url('../imgs/Confident Cook PP_Gray.svg');
}
.confident-cook-p-gray {
  background-image: url('../imgs/Confident Cook P_Gray.svg');
}
.confident-cook-color {
  background-image: url('../imgs/Confident Cook_Color.svg');
}
.confident-cook-color path:last-child {
  stroke: #ffc411 !important;
}
.confident-cook-p-color {
  background-image: url('../imgs/Confident Cook P_Color.svg');
}
.confident-cook-p-color path:last-child {
  stroke: #ffc411 !important;
}
.confident-cook-pp-color {
  background-image: url('../imgs/Confident Cook PP_Color.svg');
}
.confident-cook-pp-color path:last-child {
  stroke: #ffc411 !important;
}
.super-chef-color {
  background-image: url('../imgs/Super Chef_Color.svg');
}
.super-chef-color path:last-child {
  stroke: #ff9e00 !important;
}
.super-chef-pp-color {
  background-image: url('../imgs/Super Chef PP_Color.svg');
}
.super-chef-pp-color path:last-child {
  stroke: #ff9e00 !important;
}
.super-chef-p-color {
  background-image: url('../imgs/Super Chef P_Color.svg');
}
.super-chef-p-color path:last-child {
  stroke: #ff9e00 !important;
}
.page-404-container {
  text-align: center;
}
.tour-step-background {
  background-color: transparent;
}
.progress-page-container .tour-tour-element,
.guided-meals-container .tour-tour-element,
.power-up-container .tour-tour-element,
.how-to-container .tour-tour-element {
  padding: 1rem;
}
.welcome-container {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch;
}
.welcome-container .tour {
  display: inline-block;
  position: absolute;
  width: 100%;
  overflow-y: hidden;
}
.welcome-container .tour.animate-left {
  left: -102%;
}
.welcome-container .tour.animate-center {
  left: 0;
}
.welcome-container .tour.animate-right {
  left: 100%;
}
.welcome-container .monj-icon {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-size: cover;
  position: relative;
  z-index: 10;
}
.welcome-container h1 {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0.15rem;
  position: relative;
  z-index: 10;
  margin: 1rem;
}
.welcome-container p {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  margin: 1.5rem auto 2.25rem;
  padding: 0 1rem;
  max-width: 768px;
  position: relative;
  z-index: 10;
  font-weight: 400;
}
.welcome-container .light-btn,
.welcome-container .primary-btn {
  margin: 1rem auto;
  font-size: 1.5rem;
  padding: 1.5rem 3rem;
  border-radius: 6px;
  position: relative;
  z-index: 10;
  display: table;
}
.welcome-container .primary-btn {
  background-color: #ffffff;
  border: solid 2px #1ea182;
  color: #1ea182;
}
.welcome-container .primary-btn:hover {
  background-color: #1ea182 !important;
  color: #ffffff;
}
.welcome-container.first-page {
  background-color: #1ea182;
  color: #ffffff;
  padding: 1rem;
}
.welcome-container.first-page h1 {
  color: #ffffff;
}
.welcome-container.first-page .guac_bowl {
  background-image: url('../imgs/Intro_Tour_Guac_Bowl.svg');
  background-size: cover;
  background-position: center center;
  width: 512px;
  height: 300px;
  bottom: 0;
  margin: 1rem auto;
  position: relative;
  z-index: 1;
}
.welcome-container.first-page .diving_chip {
  background-image: url('../imgs/Intro_Tour_Chip_Diving.svg');
  background-size: cover;
  background-position: center center;
  width: 320px;
  height: 196px;
  right: 0;
  top: 30%;
  margin: 1rem auto;
  position: absolute;
  z-index: 1;
}
.welcome-container .form-container {
  background-color: transparent;
}
.welcome-container .form-container .switch {
  margin-top: 120px;
}
.welcome-container .form-container .switch.method {
  margin-top: 60px;
}
.welcome-container .wisdom-item-desc {
  margin-top: 2.5rem !important;
  color: #9ba1b3;
  font-style: italic;
  font-weight: 100;
  font-size: 1rem;
}
.welcome-container .tour-card-container {
  padding: 0 1rem;
}
.welcome-container .first-page {
  background-color: #1ea182;
  margin: 0 auto;
}
.welcome-container .first-page p {
  max-width: 668px;
  margin: 1.5rem auto 2.25rem;
}
.welcome-container .second-page {
  margin: 0 auto;
}
.welcome-container .second-page div p {
  font-size: 1rem;
  letter-spacing: normal;
  position: absolute;
  margin: 0.5rem auto;
  width: 100%;
  text-align: center;
}
.welcome-container .second-page .tour-checkbox {
  opacity: 0.5;
  margin: 0.5rem;
  cursor: pointer;
}
.welcome-container .second-page .tour-checkbox.active {
  opacity: 1;
}
.welcome-container .second-page .tour-card {
  border-radius: 12px;
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
  padding: .5rem;
  display: inline-block;
  width: 180px;
  margin: .5rem;
  overflow: hidden;
  position: relative;
}
.welcome-container .second-page .tour-card.maintain-width {
  max-width: 180px;
}
.welcome-container .second-page .tour-card:first-child {
  margin-left: 0;
}
.welcome-container .second-page .tour-card:last-child {
  margin-right: 0;
}
.welcome-container .second-page .tour-card .relative-position {
  padding-top: 0;
  margin-top: 0;
}
.welcome-container .tour-badge-size {
  width: 100px;
  height: 100px;
}
.welcome-container .tour-diet-img-size {
  width: 180px;
  height: 180px;
  position: relative;
  top: -10px;
  left: -8px;
  border-radius: 12px 12px 0 0;
}
/***********************************************
BOOTSTRAP TOUR
***********************************************/
.disable-pointer-events {
  pointer-events: none !important;
}
.visible-overflow {
  overflow: visible !important;
}
.temp-padding {
  padding: 1rem;
}
.temp-padding-half {
  padding: 0.5rem;
}
.temp-offset {
  left: 1px;
  top: -4px;
}
.tour-tour {
  z-index: 4001 !important;
}
.popover {
  background-color: #30c2d9;
  max-width: 600px;
  border: none;
  border-radius: 16px !important;
  font-family: sofia-pro-soft, Verdana, Geneva, sans-serif;
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
  margin: 0 1rem;
}
.popover .fa-long-arrow-right {
  margin-left: 5px;
}
.popover.right {
  margin-left: 52px;
}
.popover.right > .arrow {
  background-image: url('../imgs/Orientation Bubble Arrow_left_up.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  left: -61px;
}
.popover.right > .arrow:after {
  bottom: -31px;
  left: -84px;
  content: " ";
  border-right-color: #30c2d9;
  border-width: 30.5px 95px 30.5px 0;
  display: none;
}
.popover.left {
  margin-left: -52px;
}
.popover.left > .arrow {
  background-image: url('../imgs/Orientation Bubble Arrow_right_up.svg');
  width: 62px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  right: -61px;
}
.popover.left > .arrow:after {
  bottom: -31px;
  right: -84px;
  content: " ";
  border-left-color: #30c2d9;
  border-width: 30.5px 0 30.5px 95px;
  display: none;
}
.popover.bottom {
  margin-top: 40px;
}
.popover.bottom > .arrow {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-image: url('../imgs/Orientation Bubble Arrow_up_right.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  top: -45px;
}
.popover.bottom > .arrow:after {
  top: -33px;
  margin-left: -17px;
  content: " ";
  border-bottom-color: #30c2d9;
  border-width: 0 20px 45px 20px;
  display: none;
}
.popover.top {
  margin-top: -40px;
}
.popover.top > .arrow {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-image: url('../imgs/Orientation Bubble Arrow_down_left.svg');
  height: 45px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0;
  bottom: -45px;
}
.popover.top > .arrow:after {
  bottom: -33px;
  margin-left: -17px;
  content: " ";
  border-top-color: #30c2d9;
  border-width: 45px 20px 0px 20px;
  display: none;
}
.popover-title {
  padding: 0 !important;
  margin: 1rem auto 0.75rem;
  font-size: 1.5rem;
  background-color: transparent;
  border-bottom: none;
  border-radius: 0;
  text-align: center;
  color: white;
}
.popover-content {
  padding: 0px 34px;
  color: white;
  text-align: center;
  font-size: 1rem;
  padding-top: 0;
}
.my-btn {
  display: block;
  margin: 1.5rem auto;
  color: #30c2d9;
  width: auto;
  border-radius: 8px;
  padding: 10px 10px 13px;
  font-size: 1rem;
  border-color: white;
  background-color: white;
  cursor: pointer;
  text-align: center;
  max-width: 150px;
}
.tour-backdrop {
  background-color: white;
  opacity: 0.9;
}
.tour-step-background {
  display: none;
}
.tour-tour-element {
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
  border-radius: 8px !important;
}
.tour-tour-element.item-grid {
  padding: 1rem;
}
@media screen and (max-width: 1180px) {
  .welcome-container.first-page .diving_chip {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .welcome-container .form-container .switch {
    margin: 1rem 1rem 0.25rem;
  }
  .welcome-container .form-container .switch.method {
    margin-top: 1rem;
  }
  .welcome-container .form-container .switch.tourSwitch {
    transform: translateX(0);
    position: relative;
    bottom: auto;
    vertical-align: middle;
    margin: 1rem;
  }
  .welcome-container .tour-badge-size {
    float: left;
  }
  .welcome-container .second-page div p {
    margin: 0rem auto;
    position: relative;
    padding-top: 1rem;
  }
  .welcome-container .second-page .tour-checkbox {
    opacity: 0.5;
    margin: 0.5rem;
    cursor: pointer;
  }
  .welcome-container .second-page .tour-checkbox.active {
    opacity: 1;
  }
  .welcome-container .second-page .tour-card-container {
    padding: 0 1rem;
  }
  .welcome-container .second-page .tour-card {
    width: 100%;
    margin: .5rem 0;
  }
  .welcome-container .second-page .tour-card:first-child {
    margin: 0 0 .5rem;
  }
  .welcome-container .second-page .tour-card:last-child {
    margin: .5rem 0 0;
  }
  .welcome-container .second-page .tour-card .skillbox {
    text-align: center;
  }
  .welcome-container .wisdom-item-desc {
    margin-top: 0rem !important;
  }
}
/***********************************************
  PUBLIC EXPERIENCE
***********************************************/
.green-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1ea182;
}
.pe-container {
  max-width: 1024px;
  margin: auto;
}
.pe-container-large {
  max-width: 1280px;
  margin: auto;
}
.pe-max-width-override {
  max-width: 100% !important;
}
.pe-header {
  width: 100%;
  padding: 1rem 1rem;
  position: absolute;
  top: 0;
  left: 0;
}
.pe-header .language-switch {
  position: relative;
  /*top:1rem;
    left:1rem;*/
  display: inline-block;
}
.pe-header .language-switch .form-control {
  max-width: 120px;
}
.pe-header .pe-header-links {
  position: relative;
  /*top:1rem;
    right:1rem;*/
  display: inline-block;
  float: right;
  text-align: right;
}
.pe-header .pe-header-links ul {
  margin: 0;
  padding: 0;
}
.pe-header .pe-header-links li {
  display: inline-block;
  padding: 4px 4px;
}
.pe-header .pe-header-links a {
  color: #ffffff;
  padding: 10px;
  font-size: 1.25rem;
}
.pe-header .pe-header-links a:hover {
  color: #ffffff;
}
.pe-hero-container {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)), url("../imgs/home-hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  background-position-y: 0;
  width: 100%;
  padding: 6rem 0;
}
.pe-top-icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../imgs/monj-icon.svg");
  width: 100px;
  left: 50%;
  margin: 0 auto 3rem;
}
.pe-top-text {
  width: 100%;
  top: 30vh;
  max-width: 1023px;
  margin: 0 auto;
}
.pe-top-text h1 {
  font: 600 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
  font-size: 1.5rem;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 3rem;
  padding: 0 1rem;
}
.pe-top-text h2 {
  font: 300 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #9ba1b3;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: normal;
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 400;
  text-transform: none;
  font-size: 1.5rem;
  max-width: 50rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 0 1rem;
}
.pe-top-sign-in {
  color: #f0f0f6;
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 1.75rem;
}
.pe-top-sign-in a {
  color: #f0f0f6;
  font-weight: 400;
}
.pe-top-sign-in a:hover {
  color: #f0f0f6;
}
.pe-how-it-works {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  margin: 0 10%;
}
.pe-how-it-works h1 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 3rem;
}
.pe-how-it-works h2,
.pe-how-it-works p {
  margin-bottom: 1rem;
}
.pe-how-it-works p {
  max-height: 60px;
}
.pe-how-it-works .pe-how-internal {
  text-align: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
}
.pe-how-it-works .pe-how-internal img {
  position: relative;
  width: 100%;
  margin-top: 2rem;
}
.pe-how-it-works .pe-how-internal:nth-child(odd) img {
  width: 75%;
  margin-top: 30%;
}
.pe-number-icon {
  width: 3rem;
  margin: auto;
  margin-bottom: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.pe-number-icon.one {
  background-image: url("../imgs/1_gradient.svg");
}
.pe-number-icon.two {
  background-image: url("../imgs/2_gradient.svg");
}
.pe-number-icon.three {
  background-image: url("../imgs/3_gradient.svg");
}
.pe-phone-icon {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}
.pe-phone-icon.one img {
  width: 80%;
  max-width: 256px;
}
.pe-phone-icon.two img {
  width: 100%;
  max-width: 320px;
}
.pe-phone-icon.three img {
  width: 80%;
  max-width: 256px;
}
.pe-divider {
  margin: 3rem 12.5%;
  width: 75%;
}
.pe-divider hr {
  border-top-width: 2px;
  border-top-color: #f0f0f6;
}
.pe-try-out-container {
  background-size: 50% auto;
  background-position: -1px 101%;
  background-repeat: no-repeat;
  background-image: url("../imgs/chicken-chickpea-plate.png");
  padding: 5vw 0 35vw 0;
}
.pe-try-out-container img {
  width: 25%;
  position: relative;
  left: 0;
}
.pe-try-out-container .pe-try-out {
  width: 100%;
  text-align: center;
  padding-bottom: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.pe-try-out-container .pe-try-out p {
  font-size: 1.25rem;
}
.pe-try-out-container .pe-try-out h1 {
  font-size: 2rem;
}
.pe-try-out-container h1 {
  color: #1ea182;
  font-size: 1.5rem;
}
.pe-try-out-container p {
  font-size: 1.25rem;
}
.pe-try-out-container button {
  margin-top: 1rem;
}
.pe-build-new-skills {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  background-image: url("../imgs/image_build-skills.jpg");
  background-color: #B3B7BA;
  background-position: 100% 0%;
  padding: 5rem 10%;
  text-align: center;
}
.pe-build-new-skills h1 {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.pe-build-new-skills {
  text-align: left;
}
.pe-skill-raw {
  color: #f0f0f6;
  min-height: 6rem;
  margin-top: 3rem;
  width: 65%;
}
.pe-skill-raw h3 {
  font-size: 1.5rem;
  color: #f0f0f6;
}
.pe-skill-raw p {
  font-size: 1.25rem;
  margin-left: 7rem;
}
.pe-skill-raw div {
  /*width: 5rem;
    height: 5rem;
    font-size: 1rem;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  display: inline-block;
}
.pe-skill-raw div.pe-icon-methods {
  background-image: url("../imgs/icon_methods-white.svg");
}
.pe-skill-raw div.pe-icon-skill-knife {
  background-image: url("../imgs/icon_skill-knife-white.svg");
}
.pe-skill-raw div.pe-icon-flavor-framework {
  background-image: url("../imgs/icon_flavor-framework-white.svg");
}
.pe-skill-raw div {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
  float: left;
  margin-right: 2rem;
  /*&.pe-icon-methods {
      background-image: url("../imgs/icon_methods.svg");
    }
    &.pe-icon-skill-knife {
      background-image: url("../imgs/icon_skill-knife.svg");
    }
    &.pe-icon-flavor-framework {
      background-image: url("../imgs/icon_flavor-framework.svg");
    }*/
}
.pe-testimonials {
  padding: 3rem 5%;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden;
}
.pe-testimonials h1 {
  font-size: 2.5rem;
  font-weight: 400;
}
.pe-testimonials .arrow {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100%;
  max-width: 32px;
}
.pe-testimonials .arrow.left {
  background-image: url("../imgs/arrow-left-black.svg");
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: 20;
  display: none;
}
.pe-testimonials .arrow.right {
  background-image: url("../imgs/arrow-right-black.svg");
  position: absolute;
  top: 50%;
  right: 0px;
  z-index: 20;
  display: none;
}
.pe-testimonials .pe-testimonials-container {
  position: relative;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial {
  cursor: -webkit-grab;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial h2 {
  text-transform: uppercase;
  color: black;
  font-weight: 700;
  position: relative;
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 1.5rem;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial .pe-testimonial-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 3rem auto 1rem auto;
  border: solid 3px #1ea182;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial .pe-testimonial-name {
  color: #1ea182;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial .pe-testimonial-body {
  width: 90%;
  margin: auto;
  position: relative;
}
.pe-testimonials .pe-testimonials-container .pe-single-testimonial .pe-testimonial-body .pe-quote {
  color: #1ea182;
  font-size: 4rem;
  font-family: initial;
}
.pe-intro-video-section {
  padding: 3rem 5%;
  background-color: #f0f0f6;
}
.pe-intro-video-section h1 {
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 0;
}
.pe-intro-video-section .pe-video {
  margin: 0 auto 2rem auto;
}
.pe-intro-video-section .pe-video-caption {
  margin: 0 auto;
}
.pe-video.brand-video {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-size: 100%;
}
.pe-video.brand-video .flowplayer {
  background-size: 100%;
}
.pe-video.brand-video .flowplayer.en {
  background-image: url('../imgs/WelcomeToMonj.jpg');
}
.pe-video.brand-video .flowplayer.es {
  background-image: url('../imgs/BienvenidoaMonj.jpg');
}
.pe-get-cooking {
  padding: 2rem 10%;
  text-align: center;
}
.pe-get-cooking h1 {
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}
.pe-social-media-container {
  width: 210px;
  height: 32px;
  margin: 40px auto;
}
.pe-social-media-container a {
  margin-left: 20px;
  margin-right: 20px;
}
.pe-social-media {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 28px;
  height: 28px;
}
.pe-social-media.facebook {
  background-image: url("../imgs/facebook.svg");
}
.pe-social-media.instagram {
  background-image: url("../imgs/instagram.svg");
}
.pe-social-media.twitter {
  background-image: url("../imgs/twitter.svg");
}
.pe-social-links-container {
  margin: auto;
  margin-top: 5rem;
}
.pe-social-links-container div {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.why-sign-up-container section {
  margin: 0 1rem 1rem 0;
}
.why-sign-up-container .choice {
  margin: 0 3rem;
}
/*.sign-up-container {
  .form-container {
    padding: calc(1.5%);
  }
}*/
/***********************************
 Smaller than phone View
************************************/
@media screen and (max-width: 768px) {
  .pe-how-it-works {
    margin: 0 auto;
  }
  .pe-how-it-works p {
    width: 75%;
    margin: 0 auto 1.5rem;
    max-width: 300px;
    max-height: initial;
  }
  .pe-how-it-works .pe-how-internal {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .pe-how-it-works .pe-how-internal img {
    width: 40%;
    min-width: 200px;
    margin-top: 0rem;
    margin-bottom: 2.5rem;
  }
  .pe-how-it-works .pe-how-internal:nth-child(odd) img {
    width: 40%;
    margin-top: 0;
  }
  .pe-skill-raw {
    width: 100%;
    text-align: center;
  }
  .pe-skill-raw div {
    float: none;
    margin-right: 0;
  }
  .pe-skill-raw p {
    margin-left: 0;
  }
  .pe-header .pe-header-links {
    width: 61%;
  }
}
.home-auth {
  padding-top: 58px;
  padding-bottom: 10px;
  background-color: #f0f0f6;
  position: relative;
  z-index: 2;
}
.home-auth #mission {
  background: transparent;
  overflow: visible!important;
}
.home-auth .box-btn-back {
  width: 100%;
  margin: 0;
}
.home-auth .challenge-description p:last-child {
  margin-bottom: 0px;
}
.home-auth .rec-list-titles {
  text-align: left;
  color: black !important;
  font-weight: 400 !important;
}
.home-auth .carousel-container #carousel {
  padding: calc(2.12777778%);
  user-select: none;
  position: relative;
  min-width: 100%;
}
.home-auth .carousel-container #carousel > div > div .recipe-card {
  width: 99%;
  margin: 10px 0 6px 0;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  background-color: #f0f0f6;
  position: relative;
}
.home-auth .carousel-container #carousel h4 {
  margin-bottom: 0px;
}
.home-auth .carousel-container #carousel p {
  color: #9ba1b3;
  font-weight: 100;
}
.home-auth .carousel-container #carousel .list-of-recipes {
  width: 100%;
  position: relative;
  top: 0;
  left: 0px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.home-auth .carousel-container #carousel .list-of-recipes.animate-left {
  animation-name: animate-swipe-left;
  animation-duration: .6s;
}
.home-auth .carousel-container #carousel .list-of-recipes.animate-right {
  animation-name: animate-swipe-right;
  animation-duration: .6s;
}
.home-auth .carousel-container #carousel .list-of-recipes h4,
.home-auth .carousel-container #carousel .list-of-recipes p {
  text-align: left;
  margin: 0 0 0.5rem;
}
.home-auth .carousel-container #carousel .list-of-recipes h4 {
  font-weight: 400;
}
.home-auth .carousel-container #carousel .list-of-recipes p {
  font-weight: 300;
}
.home-auth .carousel-container #carousel .list-of-recipes .recipe-card-container {
  width: 32%;
  padding: 0px;
  display: inline-block;
  vertical-align: top;
}
.home-auth .carousel-container #carousel .list-of-recipes .recipe-card {
  width: 100%;
  margin: auto;
  margin-bottom: 4px;
}
.home-auth .carousel {
  width: 100%;
  position: relative;
  margin-left: 0;
}
.home-auth .progress-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 2.77777778%;
}
.home-auth .goal-progress .progress {
  height: 22px;
  border-radius: 60px;
  width: 100%;
  margin: 0 auto;
  overflow: visible;
  position: relative;
}
.home-auth .goal-progress .progress .progress-bar {
  border-radius: 60px;
}
.home-auth .goal-progress .goal-padding {
  margin: 2.77777778% 0 0;
}
.home-auth .goal-progress .goal-padding .horizontal-rule-wrapper {
  margin: 3rem 0 1rem;
}
.home-auth .goal-progress .goal-padding .horizontal-rule-wrapper .horizontal-rule {
  color: #f0f0f6;
}
.home-auth .goal-progress .goal-padding .horizontal-rule-wrapper .title {
  padding: 0 2rem;
  font-size: 1rem;
  top: -29px;
}
.home-auth .goal-progress .goal-padding p {
  width: 100%;
  margin-top: 0.5rem;
}
.home-auth .goal-progress .goal-padding:first-child {
  margin-top: 0;
}
.home-auth .goal-progress .customized-goals {
  position: relative;
}
.home-auth .goal-progress .customized-goals > .badge-placeholder {
  position: relative;
  width: 38%;
}
.home-auth .goal-progress .customized-goals .progress {
  margin: 18px 0 8px;
}
.home-auth .goal-progress .customized-goals .progress-container {
  width: 60%;
}
.home-auth .goal-progress .customized-goals .progress-container p {
  text-align: left;
  color: #9ba1b3;
  font-weight: 100;
  margin: 0;
}
.home-auth .users {
  cursor: pointer;
}
.gray-background {
  /*position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;*/
  background-color: #f0f0f6;
}
/***********************************
 Smaller than Desktop View
************************************/
@media screen and (max-width: 1280px) {
  .home-auth .challenge-engaged .user-container {
    width: 96px;
    height: 96px;
  }
  .home-auth .challenge-engaged .user-container .progress-circle.aspect-content {
    top: -0.5px;
    left: -0.5px;
  }
  .home-auth .challenge-engaged .users {
    width: 100%;
    text-align: center;
  }
}
/***********************************
 Smaller than tablet View
************************************/
@media screen and (max-width: 1023px) {
  .home-auth {
    margin: auto;
  }
  .home-auth .challenge-engaged .user-container {
    width: 109px;
    height: 109px;
  }
  .home-auth .challenge-engaged .user-container .progress-circle.aspect-content {
    top: -2.75px;
    left: 0;
  }
  .home-auth .challenge-enroll-content .col-md-7 {
    width: 30%;
    min-width: 210px;
  }
  .home-auth .challenge-enroll-content .col-md-5 {
    width: 30%;
    min-width: 150px;
  }
  .home-auth .challenge-enroll-content .challenge-description {
    max-width: 500px;
    margin: 0 auto;
  }
}
/***********************************
 Phone and Smaller View
************************************/
@media screen and (max-width: 768px) {
  .home-auth .container-out {
    margin-bottom: 2.5%;
  }
  .home-auth .ae-challenge-container .container-out {
    margin-bottom: 0;
  }
  .home-auth .back .challenge-enroll-content h2,
  .home-auth .back .challenge-enroll-content p {
    padding-right: 0;
  }
}
/***********************************
 Phone and Smaller View
************************************/
@media screen and (max-width: 500px) {
  .home-auth .carousel-container #carousel .list-of-recipes {
    display: block;
  }
  .home-auth .carousel-container #carousel .list-of-recipes .recipe-card-container {
    width: 100%;
  }
  .home-auth .carousel-container #carousel .list-of-recipes .recipe-card {
    width: 40%;
    margin: 0;
    margin-bottom: 10px;
    display: inline-block;
  }
  .home-auth .carousel-container #carousel .list-of-recipes .carousel-cell-bottom-text {
    width: 55%;
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
  }
  .home-auth .carousel-container #carousel .list-of-recipes .carousel-cell-bottom-text h4 {
    margin-top: 0 !important;
  }
}
/***********************************
 Super small View
************************************/
@media screen and (max-width: 484px) {
  .home-auth .teammates .teammate {
    width: 24%;
  }
  .home-auth .teammates .teammate .user-image {
    width: 50px;
    height: 50px;
  }
  .home-auth .challenge-enroll-content h2 {
    text-align: center;
  }
  .home-auth .challenge-enroll-content .col-md-7,
  .home-auth .challenge-enroll-content .right-column {
    text-align: center;
    width: 100%;
    padding-left: 0;
    min-width: 100px;
    margin-top: 1rem;
  }
  .home-auth .challenge-enroll-content .col-md-7 p,
  .home-auth .challenge-enroll-content .right-column p {
    text-align: center;
  }
}
.er-container {
  /*.title-n-value {
    text-align: center;
    margin: auto;

    .tnv-title {
      font-size: @large-font-size;
    }

    .tnv-value {
      font-size: 2*@large-font-size;
      color: @secondary-color;
    }
  }*/
  /*.er-item-tab-row {
    width: 85%;
    margin: 0% 7.5%;
    justify-content: flex-end;
  }*/
}
.er-container .flavor-framework .pie-chart {
  position: relative;
  width: 200px;
  height: 200px;
  display: inline-block;
  vertical-align: top;
  background-position: center;
}
.er-container .flavor-framework .pie-chart div {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
}
.er-container .flavor-framework > div {
  margin-top: 1rem;
}
.er-container .flavor-framework .ff-video-icon {
  background-position: center;
  height: 100px;
  min-width: 140px;
  display: inline-block;
  vertical-align: top;
}
.er-container .flavor-framework .not-a-flavor {
  color: #9ba1b3;
}
.er-container .flavor-framework .lowercase {
  text-transform: lowercase;
}
.er-container .flavor-framework .description {
  margin: 0 1rem;
  color: #9ba1b3;
  font-weight: 200;
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 200px - 2.5rem);
}
.er-container .flavor-framework .col-sm-6,
.er-container .flavor-framework .col-sm-4,
.er-container .flavor-framework .col-sm-8,
.er-container .flavor-framework .col-sm-7 {
  padding-left: 0;
  padding-right: 0;
}
.er-container .flavor-framework .heading {
  border-bottom: 3px solid #f0f0f6;
  max-width: 90%;
  padding: .25rem 0;
  margin: 1rem 0;
}
.er-container .flavor-framework .heading .title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #9ba1b3;
  display: inline-block;
  vertical-align: middle;
  font-weight: 200;
  padding-left: .25rem;
}
.er-container .tab-nav-wrapper {
  padding: 1rem 0;
}
.er-container .tab-right-shade {
  right: 0;
  width: 70px;
}
.er-container .er-tabs-container {
  width: 100%;
  max-height: 57px;
  float: none !important;
  margin: 1rem auto 2rem;
}
.er-container .begin-btn-arrow {
  float: right;
  top: -29px;
  position: relative;
  right: 23px;
  background-image: url('imgs/arrow-drilldown-white.svg');
  background-color: rgba(255, 255, 255, 0.03);
}
.er-container .page-title h1 {
  margin-top: 0;
}
.er-container div {
  outline: 0;
}
.er-container .er-section {
  background-color: #ffffff;
  margin: 0;
  overflow: hidden;
}
.er-container .er-section .md-default-theme {
  background-color: transparent;
}
.er-container .er-section.nutritionDisplay {
  width: 100%;
}
.er-container .link-row {
  margin-bottom: 1rem;
  width: 100%;
}
.er-container .inbetween-row {
  color: #9ba1b3;
  width: 100%;
}
.er-container .bot-row {
  color: #9ba1b3;
  width: 100%;
}
.er-container .info-icon-desc {
  width: 80%;
  margin: 0 30px;
  display: inline-block;
}
.er-container .sec-act-link-two {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  display: inline-block;
  font-size: 1rem;
  color: #1ea182;
  cursor: pointer;
}
.er-container .small-vert-line {
  color: #9ba1b3;
  margin: 0px 8px;
  display: inline-block;
  vertical-align: top;
}
.er-container .er-button {
  width: 100%;
  margin: 0;
}
.er-container .recipe-detail {
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  padding: 0rem 0.5rem;
  font-weight: 400;
  width: auto;
}
.er-container .recipe-detail .vente {
  width: 75px;
  height: 75px;
}
.er-container .recipe-detail .label {
  font-size: 1.25rem;
}
.er-container .recipe-detail .value {
  font-size: 2.5rem;
  color: #1ea182;
}
.er-container .recipe-detail p {
  position: relative;
  top: -0.25rem;
  margin-bottom: 0rem;
  padding-bottom: 0rem;
  font-weight: 400 !important;
}
.er-container .recipe-detail-badges {
  position: relative;
}
.er-container .badge-container-row {
  justify-content: space-around;
  width: 75%;
  margin: auto;
}
.er-container .recipe-attr {
  margin-bottom: 0;
  width: 100%;
  padding-left: 6.25%;
  display: inline-block;
}
.er-container .recipe-attr p {
  font-weight: 300;
}
.er-container .er-skills-container {
  margin-bottom: 1rem;
}
.er-container .recipe-description-container {
  padding: 0 0 0 0;
  width: 50%;
  display: inline-block;
}
.er-container .recipe-description-container .recipe-description {
  overflow: hidden;
  color: #9ba1b3;
}
.er-container .generic-description-container {
  width: 100%;
  display: inline-block;
  color: #9ba1b3;
  margin: 0;
  position: relative;
  /*a {
      display: block;
      text-align: center;
      margin-top: 1rem;
    }*/
}
.er-container .group-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: top;
  max-width: 786px;
  margin: auto;
}
.er-container .er-ingredient-category {
  margin-left: 48px;
  display: inline-block;
}
.er-container .er-info-icon {
  height: 20px;
  width: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
  position: relative;
  top: 11px;
  left: 14px;
  display: inline-block;
  vertical-align: top;
}
.er-container .er-item-container {
  width: 100%;
  min-height: 48px;
  margin: 1.5rem 0;
  padding-right: 16px;
  position: relative;
  outline: 0;
  /***********************************
 Smaller than phone portrait View
************************************/
  /***********************************
 Smaller than phone portrait View
************************************/
}
.er-container .er-item-container.opaque {
  opacity: 0.5;
  transition: opacity 0.4s ease;
}
.er-container .er-item-container.large {
  min-height: 96px;
}
.er-container .er-item-container.strong {
  opacity: 1;
}
.er-container .er-item-container .thumb {
  display: inline-block;
  vertical-align: top;
}
.er-container .er-item-container .thumb .er-item-image {
  width: 48px;
  height: 48px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-size: cover;
  margin-left: 48px;
}
.er-container .er-item-container .thumb .er-item-image.large {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  width: 96px;
  height: 96px;
  background-size: cover;
  margin-left: 0;
  border-left: none;
}
.er-container .er-item-container .thumb.info {
  background-image: url('../imgs/info-grey.svg');
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}
.er-container .er-item-container .thumb.info.large {
  background-image: none;
}
.er-container .er-item-container .description {
  margin-left: 32px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: calc(100% - 149px);
}
.er-container .er-item-container .description .substitution-bottom-margin {
  margin-bottom: 1rem;
}
.er-container .er-item-container .description h4 {
  margin: 0 auto .25rem;
}
.er-container .er-item-container .description p {
  margin: 0;
}
.er-container .er-item-container .description article {
  margin-top: .5rem;
  color: #9ba1b3;
}
.er-container .er-item-container .description .er-dietary-title {
  margin-top: 1rem;
  padding-bottom: 32px;
  position: relative;
}
.er-container .er-item-container .description .er-dietary-title .er-plus-minus-icon {
  width: 24px;
  background-image: url('../imgs/plus-grey.svg');
  background-position: left 4px;
  background-size: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
}
.er-container .er-item-container .description .er-dietary-title .er-plus-minus-icon.open {
  background-image: url('../imgs/minus-grey.svg');
}
.er-container .er-item-container .description .er-dietary-title h5 {
  margin: 0 0 16px 24px;
}
.er-container .er-item-container .description .er-dietary-icon-container {
  margin-left: 24px;
  position: absolute;
  margin-top: -32px;
}
.er-container .er-item-container .description .er-dietary-icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  top: 0px;
  position: absolute;
  margin-top: 0.75rem;
}
.er-container .er-item-container .description .er-dietary-icon.vegetarian {
  background-image: url('../imgs/veg-green.svg');
}
.er-container .er-item-container .description .er-dietary-icon.gluten {
  background-image: url('../imgs/gf-green.svg');
}
.er-container .er-item-container .description .er-dietary-icon.nutritional {
  background-image: url('../imgs/nutrition-green.svg');
}
.er-container .er-item-container .description .er-dietary-expanded-container {
  margin: 0 0 24px 0;
  opacity: 0;
  transition: opacity .3s ease;
  top: 0;
  position: relative;
}
.er-container .er-item-container .description .er-dietary-expanded-container article {
  margin-left: 48px;
}
.er-container .er-item-container .description .er-dietary-expanded-container article h4 {
  margin-top: 1rem;
}
.er-container .er-item-container .description .er-dietary-expanded-container.open {
  opacity: 1;
}
.er-container .er-item-container .description .er-dietary-expanded-container .nutritional-information {
  /*.nut-header {
          padding-bottom:15px;
      }*/
}
.er-container .er-item-container .description .er-dietary-expanded-container .nutritional-information .nut-label {
  width: 132px;
  padding: 3px;
}
.er-container .er-item-container .description .er-dietary-expanded-container .nutritional-information .nut-footer {
  padding-top: 15px;
}
.er-container .er-item-container .description .er-dietary-expanded-container .nutCol {
  width: 33%;
  display: inline-table;
  position: relative;
}
.er-container .er-item-container .description .columns {
  *zoom: 1;
  margin: auto;
  max-width: 768px;
}
.er-container .er-item-container .description .columns:before,
.er-container .er-item-container .description .columns:after {
  content: "";
  display: table;
}
.er-container .er-item-container .description .columns:after {
  clear: both;
}
.er-container .er-item-container .description .columns .col1 {
  float: left;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.er-container .er-item-container .description .columns .col2 {
  float: left;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.er-container .er-item-container .description .er-badge-containers {
  width: 100px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}
.er-container .er-item-container .description .er-badge-containers.flav {
  height: 100px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3%;
  margin-right: 0%;
  margin-left: 0%;
}
.er-container .er-item-container .description .flav-frame-desc {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  color: #9ba1b3;
}
.er-container .er-item-container .description .flav-icon-n-desc {
  display: block;
  width: 100%;
}
.er-container .er-item-container .description .flav-frame-col-container {
  display: block;
}
.er-container .er-item-container .description .er-badge-labels {
  position: relative;
  top: 90px;
  width: 104px;
}
.er-container .er-item-container .strike-through {
  color: #9ba1b3 !important;
  text-decoration: line-through;
}
.er-container .er-item-container .strike-through span {
  color: #9ba1b3 !important;
  text-decoration: line-through;
}
.er-container .er-item-container .strike-through a {
  opacity: 0.25;
}
.er-container .er-item-container .videos-popup-container h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 300;
}
.er-container .er-item-container .videos-popup-container .secondary-btn {
  margin: 1rem 0 0;
}
.er-container .er-item-container .xpan-rec-overview-popup-container ul li {
  padding: .25rem;
}
.er-container .er-item-container .xpan-rec-delivery-popup-container ul {
  list-style-type: none;
  padding: 0 0 .5rem;
}
.er-container .er-item-container .xpan-rec-delivery-popup-container ul li {
  list-style-type: none;
  padding: .25rem;
}
.er-container .er-item-container .xpan-rec-delivery-popup-container ul:last-child {
  padding: 0;
  margin: 0;
}
.er-container .er-item-container .delivery-methods {
  opacity: 1;
  height: 60px;
  transition: all 400ms ease;
  overflow: hidden;
  padding-top: 0.5rem !important;
}
.er-container .er-item-container .delivery-methods.invisible {
  opacity: 0;
  height: 0px;
}
@media screen and (max-width: 1023px) {
  .er-container .er-item-container .er-container .er-dietary-icon {
    margin-top: 0;
  }
  .er-container .er-item-container .er-container .columns {
    padding-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .er-container .er-item-container .er-container {
    /*.er-button {
      width: 100%;
      margin: 0 0;
    }*/
  }
  .er-container .er-item-container .er-container .er-badge-containers.flav {
    display: block;
    vertical-align: middle;
    margin: 10px auto;
  }
  .er-container .er-item-container .er-container .flav-frame-desc {
    width: 100%;
  }
  .er-container .er-item-container .er-container .columns {
    padding-left: 0;
  }
  .er-container .er-item-container .er-container .content-section-right {
    margin-bottom: 0.25rem;
    padding: 0;
  }
  .er-container .er-item-container .er-container .expanded-recipe-row li {
    /*padding: 0.5rem 1rem;
        &:first-child {
          padding: 0.5rem 1rem;
        }
        &:last-child {
          padding: 0.5rem 1rem;
        }
        a {
          padding: .25rem;
        }*/
    padding: 1rem 2rem;
  }
  .er-container .er-item-container .er-container .expanded-recipe-row li:last-child {
    padding-right: 1rem;
  }
  .er-container .er-item-container .er-container .recipe-detail {
    padding: 0.25rem 0.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .er-container .er-item-container .er-container .flavor-framework .pie-chart {
    margin-bottom: 2rem;
  }
  .er-container .er-item-container .er-container .flavor-framework .ff-video-icon {
    margin-bottom: 1rem;
  }
  .er-container .er-item-container .er-container .flavor-framework .description {
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 430px) {
  .er-container .er-item-container .er-container .flavor-framework .pie-chart {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
  }
  .er-container .er-item-container .er-container .flavor-framework .ff-video-icon {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .er-container .er-item-container .er-container .flavor-framework .description {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }
}
.expanded-recipe .path-box {
  justify-content: start !important;
}
@media screen and (max-width: 420px) {
  .expanded-recipe .path-box {
    justify-content: center !important;
  }
}
.expanded-recipe .copy {
  min-width: 75%;
}
@media screen and (max-width: 420px) {
  .expanded-recipe .copy {
    min-width: auto;
    width: 100% !important;
    margin-top: 8px;
  }
}
.expanded-recipe .copy strong {
  font-style: italic;
}
.my-profile-container .col-sm-6 {
  padding-right: 2rem;
}
.my-profile-container .col-sm-6:last-child {
  padding-right: 0;
}
.my-profile-container .column-left-8 {
  width: calc(100% - 100px);
  display: inline-block;
}
.my-profile-container .reset-btn-container {
  margin-top: 1rem;
}
.my-profile-container .reset-btn-container a {
  display: block;
  margin: 0.5rem 0;
}
.my-profile-container .secondary-btn.reset-demo-btn,
.my-profile-container a.md-button.md-default-theme.md-raised.secondary-btn.reset-demo-btn,
.my-profile-container .md-button.md-default-theme.md-raised.secondary-btn.reset-demo-btn {
  margin-left: 25%;
}
.my-profile-container .form-container {
  padding: 12px 0;
}
.my-profile-container .form-container input,
.my-profile-container .form-container select {
  width: 100%;
}
.my-profile-container .form-container button {
  border: none;
}
.my-profile-container .form-container .text-link {
  padding: 8px 0;
}
.my-profile-container .form-container .text-link .link-field-text {
  color: #9ba1b3;
  display: inline-block;
}
.my-profile-container .form-container .text-link .text-padding {
  float: right;
  padding: 0;
}
.my-profile-container .form-container .text-padding {
  padding: 8px 0;
}
.my-profile-container .upload {
  padding: 16px 0 0;
  overflow: hidden;
}
.my-profile-container .upload .file-input-wrapper {
  position: relative;
  display: inline-block;
}
.my-profile-container .upload .file-input-wrapper > input[type="file"] {
  font-size: 190px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.my-profile-container .upload .file-input-wrapper > .btn-file-input {
  position: absolute;
}
.my-profile-container .upload .file-input-wrapper:hover > input[type="file"] {
  cursor: pointer;
}
.my-profile-container .upload .file-input-wrapper button {
  margin-left: 0;
}
.my-profile-container .upload .image-container {
  margin-right: 10px;
  min-height: 32px;
  min-width: 64px;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px dashed #aaa;
  position: relative;
  overflow: hidden;
  visibility: hidden;
  display: none;
}
.my-profile-container .upload .upload-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0%;
  background: #1ea182;
  display: none;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
.my-profile-container .upload .upload-progress:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  transition: background-position 2s ease;
  background-size: 10px 10px;
  background-image: linear-gradient(-45deg, #ffffff 25%, transparent 25%, transparent 50%, #ffffff 50%, #ffffff 75%, transparent 75%, transparent);
}
.my-profile-container .upload .processing:before {
  background-size: 40px 40px;
  -webkit-animation: barberpole 1.5s linear infinite;
  -moz-animation: barberpole 1.5s linear infinite;
  animation: barberpole 1.5s linear infinite;
}
.my-profile-container .upload .upload-icon {
  position: absolute;
  right: 30px;
  bottom: 0;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 100%;
  border: rgba(255, 255, 255, 0) solid 10px;
}
@-webkit-keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 40px;
  }
}
@-moz-keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 40px;
  }
}
@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 40px;
  }
}
.my-profile-container .user-title {
  /*display: inline-block;
    vertical-align: top;
    padding-left: @standard-pad-interior;
    width: ~'calc(50% - 120px)';*/
}
.my-profile-container .user-title h1 {
  font-size: 2.25rem;
  margin: 2.77777778% 0 4px;
}
.my-profile-container .user-title h2 {
  font-size: 1rem;
  letter-spacing: 0;
  margin: 0 0 28px;
}
.my-profile-container .user-title h1,
.my-profile-container .user-title h2 {
  text-transform: capitalize;
  font-weight: 500;
}
.my-profile-container .user-title .sign-out a {
  padding: 2.5% 0;
}
.my-profile-container .profile-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 175px;
  height: 175px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-image: url('../imgs/usericon-white.svg');
  display: inline-block;
}
.my-profile-container .save-button {
  display: inline-block;
  vertical-align: bottom;
  /*text-align: right;
    width: 49%;*/
}
.my-profile-container .section-title {
  position: relative;
  top: 16px;
  color: #9ba1b3;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 100;
}
.my-profile-container .profile-header {
  padding-bottom: 2.5%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 39%;
  margin-right: 10%;
  display: inline-block;
  vertical-align: middle;
}
.my-profile-container .profile-body {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
.my-profile-container .save-button .primary-btn:disabled {
  background-color: #f0f0f6 !important;
  cursor: default;
}
.my-profile-container .invite-btn {
  width: 50%;
  position: relative;
  top: -68px;
  float: right;
}
.my-profile-container .plus-1-text {
  padding: 5px 0px;
}
.my-profile-container .table-holder table {
  width: 100%;
}
.my-profile-container .table-holder table tr td {
  padding: 2.5% 0%;
  border-top: 1px solid #eee;
}
.my-profile-container .table-holder table tr .invited-profile-pic {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 120px;
  height: 120px;
  display: inline-block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.my-profile-container .table-holder table tr .name-n-status {
  display: inline-block;
  margin: 5% 5%;
  vertical-align: bottom;
}
.my-profile-container .table-holder table tr .invite-name {
  font-size: 1rem;
  display: block;
}
.my-profile-container .table-holder table tr .invite-status {
  font-size: 1rem;
  color: #9ba1b3;
  opacity: 1;
}
.my-profile-container .table-holder table tr .invite-x {
  display: block;
  float: right;
  margin-top: 10%;
}
.popup-modal .form-container .message p {
  padding: 0;
  font-size: 1.25rem;
  font-weight: 200;
}
@media screen and (max-width: 768px) {
  .my-profile-container .profile-header {
    width: 100%;
    border-right: none;
    margin: 0;
  }
  .my-profile-container .profile-body {
    width: 100%;
  }
  .my-profile-container .dietary-responsiveness {
    width: 70px;
    display: inline-block;
    vertical-align: middle;
  }
  .my-profile-container .control-label {
    width: calc(100% - 75px);
  }
  .restart-tour-link {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .secondary-btn.reset-demo-btn,
  a.md-button.md-default-theme.md-raised.secondary-btn.reset-demo-btn,
  .md-button.md-default-theme.md-raised.secondary-btn.reset-demo-btn {
    margin-left: 0px !important;
  }
}
.progress-page-container {
  overflow: visible;
  position: relative;
}
.progress-page-container .badge-placeholder {
  margin: 0 auto 0.5rem;
}
.progress-page-container .section-description {
  color: #9ba1b3;
  font-weight: 300;
  margin: 1rem auto 2rem;
  max-width: 1024px;
  text-align: center;
}
.progress-page-container .progress-section {
  max-width: 1023px;
  margin: 0 auto;
  text-align: center;
}
.progress-page-container .progress-section > div {
  display: inline-block;
  vertical-align: top;
  margin: .5rem 1rem;
  width: 140px;
}
.progress-page-container .progress-section > div .item-description {
  color: gray;
  margin-top: 0;
}
.progress-page-container .progress-section .progress-badge-labels {
  width: 140px;
  margin-bottom: 8px;
  font-weight: 500;
}
.progress-page-container .all-sections-container {
  text-align: center;
  margin-top: 2rem;
}
.progress-page-container .all-sections-container:first-child {
  margin-top: 1rem;
}
.progress-page-container .current-progress-level-title {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  padding-bottom: 1rem;
}
.progress-page-container .chef-hats-holder {
  width: 100%;
  margin-bottom: 2.5%;
  text-align: center;
}
.progress-page-container .chef-hats-holder .progress-level-hat {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 5.5%;
  padding-bottom: 6.7%;
  margin: 0 1.1%;
}
.progress-page-container .horizontal-rule-wrapper.overall-prog {
  margin-top: 2rem;
}
@media screen and (max-width: 1023px) {
  .progress-page-container .progress-section > div {
    margin: .5rem .75rem;
  }
  .progress-page-container .progress-section .progress-badge-labels {
    width: 120px;
  }
}
@media screen and (max-width: 768px) {
  .progress-page-container .progress-section {
    min-width: 100%;
  }
  .progress-page-container .progress-section > div {
    margin: .5rem 1rem;
  }
  .progress-wellness-popup-container .title {
    font-size: 18px;
    color: black;
    text-align: center;
    margin-bottom: 25px;
    margin-left: 22px;
    margin-right: 22px;
    margin-top: 8px;
  }
  .progress-wellness-popup-container .line {
    font-size: 13px;
    margin: 5% 20px;
  }
  .progress-wellness-popup-container .line .colored {
    font-size: 14px;
  }
  .progress-wellness-popup-container .bot-line {
    width: 61%;
    font-size: 14px;
    margin: 10px 20px;
    margin-right: 5px;
    display: inline-block;
  }
  .progress-wellness-popup-container .img {
    width: 25%;
    height: 70px;
  }
}
/***********************************************
CHALLENGE HEADER
***********************************************/
.bold {
  font-weight: 600 !important;
}
.large-font {
  font-size: 1.5rem !important;
}
.no-bottom-margin {
  margin-bottom: 0 !important;
}
.tasks.challenge-content {
  background-color: #f0f0f6;
  padding: 2rem 2rem 3rem 2rem;
  min-height: 50vh;
}
.margin-right {
  margin-right: 2rem !important;
}
.margin-bottom {
  margin-bottom: 0.5rem !important;
}
.normal-margin-bottom {
  margin-bottom: 1rem !important;
}
.margin-top {
  margin-top: 0.5rem !important;
}
.team-up-header {
  color: white;
}
.team-up-header .ae-challenge-container {
  margin-bottom: 2.5%;
  transition: all 400ms ease;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}
.team-up-header .ae-challenge-container.active {
  height: initial;
}
.team-up-header .ae-challenge-container.expandable-height {
  height: 80px;
}
.team-up-header .data-box {
  width: 130px;
  padding: 1rem 1.5rem;
  border-right: 2px solid rgba(255, 255, 255, 0.15);
}
.team-up-header .data-box:last-child {
  border-right: none;
}
.team-up-header .data-box.extra-width {
  width: 250px;
}
.team-up-header .margin-center {
  margin: 0 auto 1rem;
  text-align: center;
}
.team-up-header .content-box {
  width: calc(100% - 416px - 75px - 2rem - 48px);
  margin: 0 1.5rem;
}
.team-up-header .content-box.three-items {
  width: calc(100% - 416px - 75px - 2rem - 200px);
}
.team-up-header h1 {
  color: white;
  font-weight: 400;
  margin: 0;
}
.team-up-header .badge-placeholder {
  transition: all 400ms ease;
  /*border: 1.5px solid white;
    border-radius: 100%;*/
}
.team-up-header .badge-placeholder.md-margin {
  margin: 1rem 1rem 1rem 0;
}
.team-up-header .enroll-team-up {
  float: right;
}
.team-up-header .enroll-team-up p {
  margin-right: 1rem !important;
}
.team-up-header .enroll-team-up .primary-btn {
  color: #363b47;
}
.team-up-header .text-uppercase {
  text-transform: uppercase;
  font-weight: 100;
  border-right: 1px solid white;
  padding: 0 1rem 0 0.35rem;
  font-size: 1.25rem;
}
.team-up-header .arrows {
  color: #ffc411;
  position: relative;
  top: 1px;
  display: inline-block;
}
.team-up-header .arrows:before {
  content: "\25BC";
}
.team-up-header .arrows.active:before {
  content: "\25B2";
}
.team-up-header .arrows.top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0 2rem 2rem;
}
.team-up-header .teamup-content {
  left: 65px;
  font-weight: 100;
  position: relative;
  transition: all 400ms ease;
  /*.challenge-description {
      width: 50%;
      min-width: 400px;
    }*/
}
.team-up-header .teamup-content.small-on-mobile {
  /*.mobile-width {
        display: none;
      }*/
}
.team-up-header .teamup-content.not-challenge-page {
  max-width: 600px;
  margin-right: 50px;
  margin-top: 1rem;
}
.team-up-header .teamup-content .header-progress-bar {
  padding-left: 3.5rem;
  width: calc(100% - 6rem - 160px);
}
.team-up-header .teamup-content .header-progress-bar .progress {
  height: 30px;
}
.team-up-header .teamup-content h1 {
  font-size: 1.25rem;
  font-weight: 500;
}
.team-up-header .teamup-content p {
  margin: 0;
  font-size: 1rem;
  font-weight: 100;
  max-width: 450px;
  /*&:last-child {
        padding-right: 0;
      }*/
}
.team-up-header .teamup-content .enroll-active {
  padding-right: 3rem;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  float: left;
}
.team-up-header .teamup-content .enroll-team-content-active {
  padding-left: 3rem;
  width: calc(100% - 6rem - 160px);
}
.team-up-header .teamup-content .name-and-tools .tools {
  padding: 0;
  margin: 0;
}
.team-up-header .teamup-content .name-and-tools .tools .separator {
  color: #9ba1b3;
}
.team-up-header .teamup-content .name-and-tools .tools a {
  text-transform: lowercase;
  /*&:last-child {
            border-left: 1px solid @light-gray;
            padding-right: 0;
            padding-left: 0.65rem;
            padding-bottom: 0.25rem;
          }
          &:first-child {
            padding-left: 0;
          }*/
}
.team-up-header .teamup-content .name-and-tools .tools a:hover {
  color: white;
}
.team-up-header .teamup-content .name-and-tools.show-on-mobile {
  display: none;
}
.team-up-header .teamup-title {
  font-size: 1.25rem;
  padding-left: 0.75rem;
}
.team-up-header .challenge-background {
  text-align: left;
  padding: 1rem 0;
  background-color: #363b47;
  color: white;
}
.team-up-header .challenge-enroll-content {
  background-color: #ffffff;
  padding: 1rem;
  margin: 2rem;
  border-radius: 12px;
  position: relative;
}
.team-up-header .challenge-enroll-content header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.team-up-header .challenge-enroll-content h1 {
  padding: 0;
  margin: 0 0 1rem;
}
.team-up-header .challenge-enroll-content h2 {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
  color: #000000;
  margin: 0;
  text-align: left;
}
.team-up-header .challenge-enroll-content .col-md-5 {
  display: inline-block;
  float: none;
  vertical-align: middle;
  width: 150px;
  margin-top: calc(38px + 1rem);
}
.team-up-header .challenge-enroll-content .col-md-7 p,
.team-up-header .challenge-enroll-content .right-column p {
  color: #000;
  position: relative;
  margin: 6px 0;
  text-align: left;
}
.team-up-header .challenge-enroll-content .col-md-7 p strong,
.team-up-header .challenge-enroll-content .right-column p strong {
  color: #000;
}
.team-up-header .challenge-enroll-content .col-md-7 p span.hr,
.team-up-header .challenge-enroll-content .right-column p span.hr {
  background: #fff;
  padding: 0 1rem;
  position: relative;
}
.team-up-header .challenge-enroll-content .col-md-7 p.dash:before,
.team-up-header .challenge-enroll-content .right-column p.dash:before {
  background: #1ea182;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.team-up-header .challenge-enroll-content .primary-btn {
  margin: 1rem auto 0.25rem;
  max-width: 240px;
}
.team-up-header .challenge-enroll-content .left-column {
  display: inline-block;
  width: 120px;
  margin: 0.25rem 0.75rem 0.25rem 0;
  vertical-align: middle;
}
.team-up-header .challenge-enroll-content .left-column p {
  margin-bottom: 0;
  margin-top: .25rem;
}
.team-up-header .challenge-enroll-content .col-md-7,
.team-up-header .challenge-enroll-content .right-column {
  display: inline-block;
  width: calc(100% - 150px - 1rem);
  vertical-align: middle;
  float: none;
  text-align: left;
  margin-top: calc(38px + 1rem);
}
.team-up-header .challenge-enroll-content .col-md-7,
.team-up-header .challenge-enroll-content .col-md-9 {
  padding-left: 1rem;
}
.team-up-header .challenge-enroll-content .challenge-card-tools h2 {
  font-weight: 400;
  letter-spacing: 0;
  color: #000000;
  text-transform: none;
  margin: 1rem 0;
}
.team-up-header .challenge-enroll-content .challenge-card-tools ul {
  padding-left: 0;
  margin-bottom: 1rem;
}
.team-up-header .challenge-enroll-content .challenge-card-tools ul li {
  padding: 0 .25rem 0 .5rem;
  border-left: solid 2px #f0f0f6;
  display: inline-block;
  height: 22px;
}
.team-up-header .challenge-enroll-content .challenge-card-tools ul li:first-child {
  border-left: none;
  padding-left: 0;
}
.team-up-header .challenge-enroll-content .challenge-card-tools ul li a {
  text-transform: lowercase;
  font-size: 1rem;
  font-weight: 100;
}
.team-up-header .challenge-enroll-content .challenge-card-tools .progress {
  height: 1.75rem;
  border-radius: 60px;
  text-align: left;
}
.team-up-header .challenge-enroll-content .challenge-card-tools .progress .progress-bar {
  background-color: #30c2d9;
}
.team-up-header .teammates {
  text-align: left;
  margin-bottom: 0;
}
.team-up-header .teammates .teammate {
  width: 50px;
}
.team-up-header .teammates .teammate.set-width {
  width: 100px;
}
.team-up-header .teammates .teammate:first-child {
  margin-left: 0;
}
.team-up-header .teammates .teammate:last-child {
  margin-right: 0;
}
.team-up-header .teammates .teammate .user-name {
  width: 100%;
}
.team-up-header .teammates .teammate .user-image {
  width: 75px;
  height: 75px;
}
.team-up-header .teammates .teammate.no-side-margin {
  margin: .5rem 0;
}
.team-up-header .back .challenge-enroll-content {
  padding: 0;
}
.team-up-header .back .challenge-enroll-content h2 {
  padding-right: 2rem;
}
.team-up-header .back .challenge-enroll-content .badge-placeholder {
  margin-bottom: .5rem;
}
.team-up-header .ae-challenge-back {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2.77777778%;
}
.team-progress .progress {
  height: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  border-radius: 36px;
  position: relative;
  margin: 1rem auto;
}
.team-progress .progress .color-1 {
  background-color: #6de770;
}
.team-progress .progress .color-2 {
  background-color: #ff3f3f;
}
.team-progress .progress .color-0 {
  background-color: #30c2d9;
}
.team-progress .progress .color-3 {
  background-color: #ffc411;
}
.team-progress .progress .color-4 {
  background-color: #1ea182;
}
.team-progress .progress .color-5 {
  background-color: #FB9B00;
}
.team-progress .progress .color-6 {
  background-color: #65E770;
}
.team-progress .progress .progress-bar {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 0;
  z-index: 10;
}
.team-progress .progress .progress-bar.completed {
  border-radius: 0 68px 68px 0;
}
.team-progress .progress .progress-bar:first-child {
  border-radius: 24px 0 0 24px !important;
  border-left: 0;
}
.team-progress .progress .progress-bar:last-child {
  border-right: none;
}
.team-progress .progress.complete .progress-bar:last-child {
  border-radius: 0 68px 68px 0;
}
.team-progress .progress.completed {
  border-radius: 0 68px 68px 0;
}
.team-progress > p {
  text-align: center;
  color: #9ba1b3;
  font-size: 1.25rem;
}
.no-left-padding {
  padding-left: 0 !important;
}
.no-left-placement {
  left: 0 !important;
}
.no-right-padding {
  padding-right: 0;
}
.team-cap-label {
  position: absolute;
  padding: 0;
  font-size: 0.75rem;
  color: #9ba1b3 !important;
  text-align: center;
  left: 0;
}
.team-cap-label.top {
  top: -1.75rem;
}
.team-cap-label.bottom {
  bottom: -1.75rem;
}
.inspiration-row {
  background-color: #f0f0f6;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
  padding-top: 0.25rem;
  min-height: 400px;
}
.inspiration-row h2 {
  color: black;
  font: 600 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  text-transform: uppercase;
  width: 49%;
  letter-spacing: normal;
  margin: 0;
}
.todos .col-sm-6 {
  padding-right: 4rem;
}
.todos.challenge-page .task-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.todos.challenge-page .task-header p {
  color: black;
  margin-top: 0.5rem;
}
.todos.challenge-page .team-task {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 1rem;
}
.todos.challenge-page .team-task:last-child {
  border-bottom: none;
}
.todos.challenge-page .task-title {
  margin: 0;
  padding: 12px 0 4px;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  font-size: 1.25rem;
}
.todos.challenge-page .label h3 span {
  font-size: 1rem;
  color: #363b47;
}
.team-task {
  position: relative;
  padding: 23px 4rem 23px 46px;
  border-bottom: 1px solid #f0f0f6;
  cursor: pointer;
}
.team-task:last-child {
  border-bottom: none;
}
.team-task.no-bottom-border {
  border-bottom: none;
}
.team-task .label {
  padding: 0;
  display: inline-block;
}
.team-task .label h3,
.team-task .label span {
  display: inline-block;
  margin: 0 auto;
}
.team-task .label span {
  font-size: 1rem;
}
.team-task .top-label {
  padding: 0;
  display: block;
  top: -18px;
  width: 100%;
  position: relative;
  padding-bottom: 10px;
}
.team-task .top-label h2 {
  text-decoration: none;
  text-transform: capitalize;
  color: #1ea182;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 0px;
  top: 8px;
  position: relative;
}
.team-task .arrow {
  background-image: url('../imgs/arrow-right-gray.svg');
  background-size: cover;
  background-position: center center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: calc(5.26315789%);
  color: #363b47;
}
.team-task .arrow.fa-large-size {
  font-size: 1.5rem;
}
.team-task .arrow.button-position {
  top: -9%;
  right: calc(4.26315789%);
}
.team-task .arrow .md-button {
  min-width: 100px;
  min-height: 50px;
}
.team-task .arrow .md-button span {
  color: #ffffff;
  font-weight: 400;
}
.double-top-margin {
  margin-top: 2rem;
}
.completed-ribbon {
  display: inline-block;
  position: relative;
  margin-bottom: .25rem;
}
.completed-ribbon.white .left,
.completed-ribbon.white .right {
  background-color: white;
}
.completed-ribbon .left {
  background-image: url('../imgs/ribbon_left.svg');
  left: 0;
}
.completed-ribbon .right {
  background-image: url('../imgs/ribbon_right.svg');
  right: 0;
}
.completed-ribbon .left,
.completed-ribbon .right {
  background-size: 110%;
  background-position: center center;
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  background-color: #363b47;
}
.completed-ribbon .star {
  background-image: url('../imgs/white_star.svg');
  background-size: cover;
  background-position: center center;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  vertical-align: top;
  margin-top: 6px;
}
.completed-ribbon p {
  display: inline-block;
  background-color: #069775;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  color: #fff;
  padding: 0 2.75rem;
  height: 30px;
  vertical-align: top;
}
.completed-ribbon p .completed-text {
  position: relative;
  top: 4px;
}
.challenge-container {
  margin-top: 58px;
  /*.page-title-description {
    margin: 1rem auto 0;
  }*/
  /*.user-name {
    width: 120px;
    &.small {
      width: 50px;
    }
  }*/
}
.challenge-container .journey-post-tools {
  width: 50%;
  margin: 1rem auto;
}
.challenge-container .challenge-search-box {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
}
.challenge-container .team-list .members {
  max-width: 50px;
}
.challenge-container .team-list .teammates {
  margin: .5rem 0 0;
}
.challenge-container .team-list .teammates.member-list {
  margin: 1rem 0 0;
}
.challenge-container .team-list .teammates.member-list .teammate {
  margin: 0.5rem;
  /*&:first-child {
            margin-left: 0;
          }*/
  width: 90px;
}
.challenge-container .team-list .teammates.member-list.not-join-page {
  margin-top: 0;
  max-width: 416px;
  float: right;
}
.challenge-container .team-list .teammates.member-list.not-join-page .teammate {
  margin: 0.5rem;
  width: 50px;
}
.challenge-container .team-list .teamrow {
  margin: 0 auto;
  padding: 1.5rem 0;
  border-bottom: 1px solid #f0f0f6;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
}
.challenge-container .team-list .teamrow:first-child {
  border-top: 1px solid #f0f0f6;
  padding-top: 1rem !important;
}
.challenge-container .team-list .teamrow.smaller-padding {
  padding: 1rem 0;
}
.challenge-container .team-list .teamrow .team-data {
  top: 1rem;
}
.challenge-container .team-list .teamrow .team-data.on-join-page {
  top: 0;
}
.challenge-container .team-list .teamrow .team-data p {
  margin: 0;
}
.challenge-container .team-list .teamrow:first-child {
  padding-top: 0;
}
.challenge-container .team-list .teamrow:last-child {
  border-bottom: none;
}
.challenge-container .teams-container {
  position: relative;
  min-height: 200px;
}
.challenge-container .teams-container .progress {
  height: 20px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  position: relative;
  margin: 0 auto;
}
.challenge-container .teams-container .progress .progress-bar {
  background-color: #1ea182;
  font-size: 1rem;
}
.challenge-container .teams-container .progress p {
  padding: 2px 0 0 10px;
}
.challenge-container .teams-container .progress.no-percentage-text {
  margin-top: 4px;
}
.challenge-container .right-aligned-button {
  width: 100%;
  text-align: right;
  margin-top: 0.75rem;
}
.challenge-container .team-rows-overlay {
  position: absolute;
  z-index: 3;
}
.challenge-container .more-btn {
  width: 100%;
  margin-top: 2rem;
}
.challenge-container .show-more-teams-button {
  margin: 0 auto !important;
  display: block;
  color: #1ea182;
  border: solid;
  background-color: white !important;
}
.challenge-container .marg-right-20 {
  margin-right: 20px;
  width: 25% !important;
}
.challenge-container .vert-a-mid {
  vertical-align: top;
}
.challenge-container .teamrow {
  width: 100%;
  display: block;
  min-height: 90px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.challenge-container .teamrow p {
  font-weight: 400;
}
.challenge-container .teamrow svg {
  border-radius: 4px;
}
.challenge-container .team-name-size {
  font-size: 1.25rem;
  margin-bottom: .5rem;
  padding-left: 1rem;
}
.challenge-container .team-name-size:first-child {
  padding-left: 0;
}
.challenge-container .team-name-size p {
  margin-bottom: 0;
}
.challenge-container .team-name-size .activity-num,
.challenge-container .team-name-size .perc {
  color: #9ba1b3;
  font-size: 1rem;
}
.challenge-container .last-activity {
  width: 25%;
  display: inline-block;
  text-align: center;
  margin-right: 5%;
  vertical-align: top;
}
.challenge-container .last-activity .activity-num {
  font-size: 1.25rem;
  display: block;
  margin-bottom: 0;
}
.challenge-container .last-activity .time-ago {
  font-size: 1rem;
  display: block;
}
.challenge-container .t-prog {
  width: 25%;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-right: 5%;
}
.challenge-container .t-prog .perc {
  font-size: 1.25rem;
  display: block;
  margin-bottom: 0;
  letter-spacing: 2px;
}
.challenge-container .t-prog .prog-bar {
  width: 100%;
  display: block;
  border-radius: 4px;
}
.challenge-container .team-members {
  min-width: 180px;
  display: inline-block;
  vertical-align: top;
}
.challenge-container .team-members .member {
  width: 10%;
  display: inline-block;
  margin: 0 14px 0 0;
  vertical-align: bottom;
  min-width: 50px;
  /*.user-name {
        width: 50px;
      }*/
}
.challenge-container .team-members .member.join-image-member {
  float: right;
}
.challenge-container .team-members .member .user-image {
  width: 50px;
  height: 50px;
}
.challenge-container .team-members .member .join-image {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #1ea182;
  color: white;
  margin-bottom: 31px;
  padding-top: 14px;
  padding-left: 3px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.35);
  letter-spacing: 2px;
  background-image: url('../imgs/plus-white.svg');
}
.challenge-container .member:last-child {
  margin-right: 0;
}
.challenge-container .extra-top-margin {
  margin-top: 3rem;
}
.challenge-container .captain-tool {
  color: #ff3f3f !important;
  font-size: 0.75rem;
  width: 120px;
}
.challenge-container .small-did-it-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  float: left;
  top: -35px;
  left: -12px;
}
.challenge-container .name-n-did-it-container-fixed {
  display: block;
  width: 100%;
  position: relative;
}
.challenge-container .name-n-did-it-container-fixed p {
  position: absolute;
  margin-left: 50% !important;
  transform: translateX(-50%) !important;
}
.challenge-container .small-did-it.selected {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
}
.challenge-container .small-did-it.place-in-corner {
  border: 2px solid #ffffff;
  border-radius: 100%;
  position: absolute;
  top: -3px;
  right: -3px;
}
.challenge-container .badge-align-center {
  text-align: center;
  display: block;
}
.challenge-container .user-image.no-bottom-padding {
  padding-bottom: 0;
}
.challenge-container .name-n-did-it-container {
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
}
.challenge-container .name-n-did-it-container p {
  position: relative;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}
.challenge-container .name-n-did-it-container .small-did-it {
  display: inline-block;
  vertical-align: middle;
}
.challenge-container .invited-text {
  display: block;
  text-align: center;
  font-size: 2.25rem;
  margin: 1.42857143% 0;
}
.challenge-container .team-name-text {
  color: #1ea182;
}
.challenge-container .join-team-btn {
  width: 300px;
  margin: 1.25% auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
.challenge-container .all-teams-team-name {
  color: white;
  background-color: #1ea182;
}
.challenge-container .all-teams-team-container {
  border: 1px solid #c0bbbb;
  margin: 0px 0px 30px 0px !important;
}
.challenge-container .tasks-did-it {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 29px;
}
.challenge-container .challenge-game-values {
  margin: 2rem auto 0;
  text-align: center;
  position: relative;
}
.challenge-container .challenge-game-values h1 {
  margin-top: 1rem;
}
.challenge-container .challenge-rules-link {
  position: absolute;
  top: 0;
  right: 0;
}
.challenge-container .challenge-title-description-wrapper {
  display: inline-block;
}
.challenge-container .challenge-menu {
  text-align: center;
  position: relative;
  padding: 2rem 1rem;
  margin: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}
.challenge-container .challenge-menu .md-button {
  min-width: 200px;
  margin: 1rem;
  text-transform: none;
}
.challenge-container .no-challenge {
  padding: 2.63157895%;
  margin: 10% auto 0;
  text-align: center;
  max-width: calc(465.45454545px);
}
.challenge-container .edit {
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  color: #1ea182;
  padding-right: 1rem;
  margin-bottom: 3px;
}
.challenge-container .team-name-inline {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 auto;
  margin-right: 1rem;
}
.challenge-container .team-challenge {
  margin-bottom: 2rem;
}
.challenge-container .team-challenge .team-header {
  margin-top: 3rem;
  position: relative;
}
.challenge-container .team-challenge .team-header .inline-team-header {
  display: inline-block;
  margin-left: .5rem;
  vertical-align: middle;
}
.challenge-container .team-challenge .team-header .team-name,
.challenge-container .team-challenge .team-header .team-tools {
  display: block;
}
.challenge-container .team-challenge .team-header .team-name a {
  font-size: calc(2rem);
  display: inline-block;
}
.challenge-container .team-challenge .team-header .team-name a:hover {
  text-decoration: none;
}
.challenge-container .team-challenge .team-header .team-name .team-header-badge {
  top: -24px;
}
.challenge-container .team-challenge .team-header .team-name span {
  font-size: 1rem;
  font-weight: 100;
  color: #9ba1b3;
  display: inline-block;
  padding-left: 2.77777778%;
}
.challenge-container .team-challenge .team-header .team-tools {
  position: absolute;
  top: 12px;
  right: 0;
  min-width: 300px;
  text-align: right;
}
.challenge-container .team-challenge .team-header .team-tools span {
  margin-right: 6px;
}
.challenge-container .team-challenge .team-header .team-tools a,
.challenge-container .team-challenge .team-header .team-tools .md-button {
  display: inline-block;
}
.challenge-container .team-challenge .team-header .team-tools a {
  margin-right: 2.77777778%;
  font-size: 1rem;
}
.challenge-container .team-challenge .team-header .team-tools .md-button {
  min-width: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}
.challenge-container .team-challenge .form-container {
  padding: 0;
}
.challenge-container .team-challenge .form-container section {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.challenge-container .message-team-button {
  position: absolute;
  top: -10px;
  right: 0;
}
.challenge-container .message-team-button button {
  margin-right: 0;
}
.challenge-container .team-progress {
  position: relative;
}
.challenge-container .challenge-complete-bulb {
  background-image: url('../imgs/finished_challenge_icon.svg');
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: -8px;
  right: 0;
  width: 50px;
  height: 50px;
}
.challenge-container .invite-color {
  color: #FFC411 !important;
}
.challenge-container .team-details {
  width: 100%;
  margin: 0 auto;
}
.challenge-container .team-details h2,
.challenge-container .team-details h3 {
  font: 600 1.25rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  color: #363b47;
  text-transform: none;
  letter-spacing: normal;
  line-height: normal;
  display: inline-block;
}
.challenge-container .team-details h2 span,
.challenge-container .team-details h3 span {
  color: #000000;
  font-weight: 300;
  font-size: 1.25rem;
}
.challenge-container .team-details h2 {
  margin-bottom: 0px;
  text-transform: uppercase;
}
.challenge-container .team-details h3 {
  font-weight: 300;
}
.challenge-container .team-details p,
.challenge-container .team-details span {
  color: #9ba1b3;
  font-weight: 100;
}
.challenge-container .team-details .team-tabs {
  width: 75%;
  text-align: center;
  position: relative;
  margin: 2.5% auto;
}
.challenge-container .team-details .team-tabs a {
  display: inline-block;
  margin: 0 18%;
  color: #9ba1b3;
  text-decoration: none;
  font-size: 1.25rem;
}
.challenge-container .team-details .team-tabs a.active {
  color: #1ea182;
  text-decoration: underline;
}
.challenge-container .team-details .horizontal-rule-wrapper {
  margin-top: calc(7.89473684%);
}
.challenge-container .team-details .horizontal-rule-wrapper .title {
  font-weight: 100;
  color: #9ba1b3;
  text-transform: uppercase;
}
.challenge-container .team-details .team-message .form-container {
  padding-top: 0;
}
.challenge-container .team-details .team-message .form-container .message-container {
  max-width: 1023px;
  margin: 0 auto 1rem;
}
.challenge-container .team-details .team-message .form-container textarea {
  width: 100%;
}
.challenge-container .team-details .team-message .form-container .md-button span {
  color: #ffffff;
}
.challenge-container .team-details .team-message .form-container section {
  max-width: 1023px;
  margin: 0 auto;
}
.challenge-container .team-details .completed-item {
  padding: 1rem 1rem 0 0;
  margin: 0;
}
.challenge-container .team-details .completed-item .game-values .game-icon {
  width: 30px;
  height: 30px;
  margin: 0;
  text-align: center;
  padding-top: 4px;
  opacity: 0.8;
}
.challenge-container .team-details .completed-item .background-cover {
  width: 125px;
  height: 125px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.challenge-container .team-details .completed-item h2 {
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 0px;
  display: block;
}
.challenge-container .team-details .completed-item h3 {
  font-size: 1rem;
  color: #1ea182;
  margin: 0px auto;
  display: block;
}
.challenge-container .team-details .completed-item p {
  max-width: 468px;
  font-size: 1rem;
  margin: 0;
}
.challenge-container .team-details .completed-item .completed-info {
  display: inline-block;
  width: calc(100% - 80px - 2rem);
  vertical-align: top;
}
.challenge-container .task-users .teammates,
.challenge-container .team-row .teammates {
  margin: 0 auto;
}
.challenge-container .task-users .teammates .teammate,
.challenge-container .team-row .teammates .teammate {
  transition: all 400ms ease;
  margin-right: 1rem;
}
.challenge-container .task-users .teammates .teammate:last-child,
.challenge-container .team-row .teammates .teammate:last-child {
  margin-right: 0;
}
.challenge-container .task-users .teammates .teammate p,
.challenge-container .team-row .teammates .teammate p {
  margin-top: 2.77777778%;
  color: #363b47;
}
.challenge-container .task-users .teammates .teammate.bubble,
.challenge-container .team-row .teammates .teammate.bubble {
  animation: bubble-out 300ms ease both;
}
.challenge-container .task-users .teammates .teammate.bubble:hover,
.challenge-container .team-row .teammates .teammate.bubble:hover {
  animation-duration: 100ms;
  animation-name: bubble-in;
}
.challenge-container .team-details-container {
  margin-top: 3rem;
}
.challenge-container .team-details-container.progress-selector-active .teammates:hover .teammate {
  transition: all 400ms ease;
  opacity: 0.5;
}
.challenge-container .team-details-container.progress-selector-active .teammates:hover .teammate:hover {
  opacity: 1;
}
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-1,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-2,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-3,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-4,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-5,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-0 {
  transition: all .2s ease;
  opacity: 0.15;
}
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-1.bubble,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-2.bubble,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-3.bubble,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-4.bubble,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-5.bubble,
.challenge-container .team-details-container .team-row:hover + .team-progress .progress .member-selection-0.bubble {
  opacity: 1;
}
.challenge-container .challenge-tab .tab-nav-wrapper {
  padding: 0;
}
.challenge-container .challenge-tab .tab-nav-wrapper .page-tabs {
  display: inline-block;
  text-align: center;
}
.challenge-container .challenge-tab .tab-nav-wrapper .page-tabs li {
  margin: 0 auto 1rem;
  padding: 0;
  width: 50%;
  height: 50px;
}
.challenge-container .task-list {
  margin-bottom: 2.63157895%;
}
.challenge-container .list-of-teams .team-task {
  padding: 2.77777778% 0;
}
.challenge-container .list-of-teams .team-task .teammates {
  margin: 0 auto;
  text-align: left;
}
.challenge-container .list-of-teams .team-task .teammates p {
  text-align: center;
}
.challenge-container .list-of-teams .team-task .teammates .teammate {
  width: 150px;
  height: 150px;
  text-align: center;
}
.challenge-container .list-of-teams .team-task .teammates .teammate:first-child {
  margin-right: 0px;
  margin-left: 0px !important;
}
.challenge-container .list-of-completed-users {
  margin-top: .5rem;
  text-align: left;
}
.challenge-container .list-of-completed-users > span:first-child {
  margin-left: 0;
}
.challenge-container .list-of-completed-users span {
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  color: #9ba1b3;
}
.challenge-container .list-of-completed-users span.line-through {
  text-decoration: line-through;
  color: #f0f0f6;
}
.challenge-container .list-of-completed-users span.line-through span {
  color: #f0f0f6;
}
.challenge-container .list-of-completed-users .completed {
  margin-left: 2px;
  text-transform: lowercase;
  text-decoration: none;
}
.challenge-container .list-of-completed-users .and {
  margin-left: 8px !important;
}
.challenge-container .list-of-completed-users .comma {
  margin-left: 0;
}
.challenge-container .find-a-team .form-container {
  background-color: transparent;
}
.challenge-container .find-a-team .team-task {
  cursor: auto;
}
.challenge-container .team-task .completed-task {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 13px;
  top: 28px;
}
.challenge-container .team-task h3 {
  /*span:first-child {
        margin-left: 1rem;
      }
      span {
        margin-left: 6px;
      }*/
}
.challenge-container .team-task h3.enrollment-state a {
  color: #000000;
  cursor: default;
  font-weight: 500;
}
.challenge-container .team-task h3.enrollment-state a:hover {
  text-decoration: none;
}
.challenge-container .team-task h3.enrollment-state.strike-through a {
  color: #9ba1b3;
}
.challenge-container .team-task h3 a {
  padding-left: 6px;
  padding-right: 6px;
}
.challenge-container .team-task .strike-through {
  color: #f0f0f6 !important;
  text-decoration: line-through;
}
.challenge-container .team-task .strike-through span {
  color: #f0f0f6 !important;
  text-decoration: line-through;
}
.challenge-container .team-task .strike-through a {
  opacity: 0.25;
}
.challenge-container .opaque {
  opacity: 0.25;
}
.challenge-container .invisible {
  visibility: hidden;
}
.challenge-container .hide-on-desktop {
  display: none;
}
.challenge-rules-container .challenge-description {
  margin: 2.5% 2.5%;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}
.team-message .message-container {
  max-width: 1023px;
  margin: 0 auto 1rem;
}
.team-message .message-container textarea {
  width: 100%;
}
.team-message .right {
  margin: 1rem 0 0 !important;
}
.specific-uppercase {
  text-transform: uppercase !important;
}
/***********************************
 Smaller than tablet View
************************************/
@media screen and (max-width: 1023px) {
  .challenge-container {
    /*.user-name {
      width: 100px;
      &.small {
        width: 50px;
      }
    }*/
  }
  .challenge-container .content-box {
    width: 100%;
  }
  .challenge-container .content-box.three-items {
    width: 100%;
  }
  .challenge-container .hide-on-desktop {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.5rem;
  }
  .challenge-container .challenge-header-icon-mobile {
    width: 52px;
    height: 52px;
  }
  .challenge-container .team-task {
    margin-bottom: 0;
  }
  .challenge-container .team-task .label {
    white-space: normal;
    text-align: left;
  }
  .challenge-container .captain-tool {
    width: 100px;
  }
  .team-up-header .content-box {
    width: 100%;
    margin: 0;
  }
  .team-up-header .teamup-content {
    padding-top: 0;
  }
  .team-up-header .teamup-content p {
    max-width: 900px;
  }
  .team-up-header .padding {
    padding: 1rem 0;
  }
  .team-up-header .data-box {
    display: none;
  }
  .team-up-header .challenge-heading {
    display: none;
  }
  .team-up-header .challenge-heading.with-border {
    font-weight: 200;
    padding-right: 0.75rem;
    margin-right: 0.5rem;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .team-up-header .challenge-heading.mobile {
    display: inline-block !important;
    margin-bottom: 0;
    margin-top: 12px;
  }
  .team-up-header .badge-placeholder.md-margin {
    margin: 0 1rem 0 0;
  }
  .team-up-header .badge-placeholder.medium {
    width: 52px;
    height: 52px;
  }
  .team-up-header .name-and-tools .tools {
    display: none;
  }
  .team-up-header .name-and-tools.show-on-mobile {
    display: block !important;
  }
  .team-up-header .name-and-tools.show-on-mobile .tools {
    display: block;
    margin-top: 0.5rem;
  }
}
/***********************************
 Smaller than phone landscape View
************************************/
@media screen and (max-width: 768px) {
  .challenge-container .teammates .teammate {
    width: 16%;
  }
  .challenge-container .todos .col-sm-6 {
    padding-right: 0;
  }
  .challenge-container .team-list .teamrow .team-data {
    top: 0;
  }
  .challenge-container .team-list .team-name-size {
    margin-bottom: 4px;
    padding-left: 0;
  }
  .challenge-container .team-list .teammates {
    padding-left: 0;
  }
  .challenge-container .team-list .teammates.member-list.not-join-page {
    float: left;
    margin-top: 0.5rem;
  }
  .challenge-container .team-list .teammates.member-list.not-join-page .teammate {
    margin: 0.5rem;
  }
  .challenge-container .message-team-button {
    position: relative;
    top: 0;
    margin-left: 0;
    margin-top: 0.5rem;
  }
  .challenge-container .team-name-inline {
    width: 100%;
    /*&.mobile-header {
        width: auto;
      }*/
  }
  .challenge-container .marg-right-20 {
    width: 50%;
  }
  .challenge-container .teamrow {
    border-bottom: solid #bbb 1px;
  }
  .challenge-container .teamrow:last-child {
    border-bottom: none;
  }
  .challenge-container .team-name {
    width: 30%;
  }
  .challenge-container .last-activity {
    width: 30%;
  }
  .challenge-container .t-prog {
    width: 38%;
    margin-right: 0;
  }
  .challenge-container .team-members {
    width: 99%;
    display: block;
    padding-top: 10px;
  }
  .challenge-container .team-details p {
    margin: 0.25rem auto;
  }
  .challenge-container .team-details .horizontal-rule-wrapper {
    margin-bottom: 0;
  }
  .challenge-container .team-task {
    padding-right: 0;
  }
  .challenge-container .team-task .arrow {
    display: none;
  }
  .challenge-container .team-task .label {
    margin: 0;
  }
  .challenge-container .team-task .label h3 {
    padding-bottom: 6px;
    padding-right: 6px;
  }
  .challenge-container .team-task .label h3 span {
    margin-left: 0;
  }
  .challenge-container .team-task .label span {
    margin-left: 0;
    display: inline;
  }
  .challenge-container .team-task a {
    display: inline;
  }
  .challenge-container .arrows {
    display: none;
  }
  .team-up-header .teamup-title {
    padding: 0;
    text-align: left;
    width: calc(100% - 136px);
    margin-right: 20px;
  }
  .team-up-header .teamup-content {
    /*.name-and-tools {
        display: none;
      }*/
  }
  .team-up-header .teamup-content.enroll-width {
    width: calc(100% - 120px);
  }
  .team-up-header .teamup-content .badge-placeholder {
    margin-right: 1rem;
  }
  .team-up-header .teamup-content .header-progress-bar {
    padding-left: 0.5rem;
    width: 100%;
  }
  .team-up-header .teamup-content.small-on-mobile {
    height: 62px;
    /*.mobile-width {
            display: none;
          }*/
  }
  .team-up-header .teamup-content.small-on-mobile .enroll-active {
    width: 49%;
    text-align: left;
  }
  .team-up-header .teamup-content.small-on-mobile .enroll-active .badge-placeholder {
    width: 60px;
    height: 60px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    float: none;
    margin-bottom: 0 !important;
    margin-right: 0.5rem;
  }
  .team-up-header .teamup-content.small-on-mobile .enroll-active .teamup-title {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    vertical-align: middle;
    width: calc(100% - 100px);
    padding-right: 1rem;
  }
  .team-up-header .teamup-content.small-on-mobile.active {
    height: auto;
  }
  .team-up-header .teamup-content.small-on-mobile p,
  .team-up-header .teamup-content.small-on-mobile .name-and-tools {
    display: none !important;
  }
  .team-up-header .teamup-content.small-on-mobile .mobile-width {
    width: 49%;
    vertical-align: middle;
    display: inline-block;
  }
  .team-up-header .teamup-content.small-on-mobile .mobile-width h1 {
    font-size: 0.9rem;
    font-weight: 100;
  }
  .team-up-header .teamup-content.small-on-mobile .mobile-width .progress {
    height: 24px;
  }
  .team-up-header .teamup-content .team-progress {
    /*&.mobile-width {
          &.active {
            width: 100%;
          }
        }*/
  }
  .team-up-header .teamup-content .team-progress .progress {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
  .team-up-header .teamup-content .team-up-enrolled-right {
    margin-top: 0.5rem !important;
  }
  .team-up-header .teamup-content .team-up-enrolled-right.add-top-margin {
    margin-top: 1rem !important;
  }
  .team-up-header .teamup-content .team-up-enrolled-right .team-progress {
    display: none;
  }
  .team-up-header .teamup-content .team-up-enrolled-right .teammates {
    margin-top: 0.5rem;
  }
  .team-up-header .teamup-content .hide-on-mobile {
    display: none;
  }
  .team-up-header .teamup-content .enroll-team-content-active {
    width: 100%;
    padding-left: 0;
    margin-top: 1rem;
  }
  .team-up-header .teamup-content .enroll-active {
    padding-right: 0;
    border-right: none;
  }
  .team-up-header .teamup-content .enroll-active .badge-placeholder {
    margin-right: 1rem;
  }
  .team-up-header .mobile-width {
    width: 100%;
    padding-left: 0.5rem;
  }
  .team-up-header .text-uppercase {
    border-right: none;
    display: block;
  }
  .team-up-header .teamup-content.not-challenge-page {
    left: 0;
    margin-right: 0 !important;
    margin-top: 1rem;
  }
  .team-up-header .name-and-tools.show-on-mobile .tools {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .team-up-header .challenge-heading.mobile {
    display: block !important;
    border-right: none;
    margin-top: 0;
  }
}
/*@media screen and (max-width: 435px) {
  .challenge-container {
    .challenge-tab {
      .tab-nav-wrapper {
        .page-tabs {
          li {
            width: 130px;
          }
        }
      }
    }
  }
}*/
@media screen and (max-width: 500px) {
  .challenge-container .team-details .completed-item {
    width: 33%;
  }
  .challenge-container .team-details .completed-item .background-cover {
    width: 100%;
    height: auto;
    padding-bottom: 100%;
  }
  .challenge-container .teammates .teammate {
    width: 26%;
  }
  .challenge-container .team-list .teammates.member-list .teammate {
    width: 50px;
  }
}
@media screen and (min-width: 768px) {
  .team-up-header .arrows {
    display: none;
  }
}
.reset-to-mygoals {
  margin-bottom: 15px;
}
.reset-all {
  background: #1ea182;
  display: inline-block;
  color: white;
  border-radius: 4px;
  line-height: normal;
}
.reset-all:hover {
  color: white;
}
.reset-all p {
  margin: 0;
  padding: 0.5rem;
}
.reset-all p:hover {
  color: white !important;
}
.my-15 {
  margin: 15px 0;
}
.inspiration-page-container .full-width {
  width: 100%;
}
.inspiration-page-container .page-title h1 {
  /*width: 40%;*/
  margin-bottom: 1rem;
}
.inspiration-page-container .page-title h2 {
  width: 40%;
  margin-bottom: 0;
  margin-top: 1rem;
  font-weight: 400;
  bottom: 0;
  display: inline-block;
  vertical-align: top;
}
.inspiration-page-container .page-title-desc-container {
  max-width: 100%;
}
.post-popup-container .uploaded-image {
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
}
.post-popup-container #userComments {
  margin: 1rem 0;
}
.post-popup-container #postComment {
  border: 0;
}
.post-popup-container button {
  border: none;
}
.post-popup-container .no-comment {
  padding: 1rem;
  display: block;
}
.post-popup-container .ugc-image-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background: #5bb75b;
  display: none;
}
.post-popup-container .ugc-image-progress:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  transition: all 2s ease;
  background-size: 10px 10px;
  background-image: linear-gradient(-45deg, #ffffff 25%, transparent 25%, transparent 50%, #ffffff 50%, #ffffff 75%, transparent 75%, transparent);
}
@media screen and (max-width: 768px) {
  .inspiration-page-container .page-title h1 {
    width: 100%;
  }
  .inspiration-page-container .page-title h2 {
    width: 100%;
  }
  .inspiration-page-container .page-title .journey-post-tools {
    width: 100%;
  }
}
.how-to-container .page-title.vid-view h1 {
  margin-top: 0;
}
.how-to-container .how-to-video {
  padding: 0px 0px !important;
}
.how-to-container .howToContentSection {
  width: 49%;
}
.how-to-container .how-to-vid-right {
  top: -21px !important;
}
.how-to-container .terms-title {
  font-size: 1.75rem;
  text-align: left;
  margin: 1.25% 0px;
}
.how-to-container .col-md-12 {
  padding: 0;
}
.how-to-container .term-line {
  font-size: 1rem;
  text-align: left;
  margin: 2.5% 0px;
}
.how-to-container .term-line .term {
  font-weight: 600;
}
.how-to-container .new-entry {
  color: #ffc411;
  text-transform: uppercase;
}
@media screen and (max-width: 500px) {
  .how-to-container .essentials-tabs li {
    padding-right: 2rem;
  }
  .how-to-container .essentials-tabs li:last-child {
    padding-right: 1rem;
  }
}
.slider-container.onboarding .slider-content {
  background-color: #f0f0f6;
  min-height: 100%;
  margin: 0;
  padding: 1rem;
  display: flex;
}
.slider-container.onboarding .slider-content .info-block {
  max-width: 800px;
  margin: 0 auto;
  vertical-align: top;
  text-align: center;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.slider-container.onboarding .slider-content .info-block .finished {
  width: 50%;
  float: left;
  height: 350px;
}
.slider-container.onboarding .slider-content .info-block .allset {
  width: 50%;
  float: left;
  padding-left: 10px;
}
.slider-container.onboarding .slider-content .icon {
  width: 75px;
  margin: 2rem auto 1rem;
}
.slider-container.onboarding .slider-content .icon.hide-on-mobile {
  display: none;
}
.slider-container.onboarding .slider-content h1 {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 auto 2rem auto;
}
.slider-container.onboarding .slider-content h1,
.slider-container.onboarding .slider-content p {
  text-align: center;
  max-width: 540px;
  color: #1ea182;
}
.slider-container.onboarding .slider-content p {
  line-height: 1.5;
  font-size: 1.2rem;
  margin: 0 auto 1rem auto;
}
.slider-container.onboarding .slider-content .path-preview {
  position: relative;
}
@media screen and (max-width: 550px) {
  .slider-container.onboarding .slider-content .path-preview {
    padding-bottom: 50px;
  }
  .slider-container.onboarding .slider-content .path-preview img.bg-mobile {
    position: absolute;
    top: 0;
    z-index: 0;
  }
}
.slider-container.onboarding .slider-content .path-preview .path-box {
  background-color: #ddedf6;
  position: absolute;
  left: 7%;
  right: 7%;
  top: 15%;
  display: flex;
}
@media screen and (max-width: 550px) {
  .slider-container.onboarding .slider-content .path-preview .path-box {
    top: 40px;
    justify-content: center;
    position: inherit !important;
    width: calc(86%);
    z-index: 1;
  }
  .slider-container.onboarding .slider-content .path-preview .path-box .path-name {
    text-align: center !important;
  }
}
.slider-container.onboarding .slider-content .path-preview .path-box .upload {
  padding: 10px 0;
  min-width: 85px;
}
.slider-container.onboarding .slider-content .path-preview .path-box .upload .profile-image {
  width: 72px;
  height: 72px;
  margin: 0;
}
.slider-container.onboarding .slider-content .path-preview .path-box .upload .upload-icon {
  right: 0;
}
.slider-container.onboarding .slider-content .path-preview .path-box .upload .image-container {
  margin: 4px 0;
  min-height: 8px;
  padding: 0;
  visibility: visible;
  display: block;
}
.slider-container.onboarding .slider-content .path-preview .path-box .copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  align-items: start;
}
.slider-container.onboarding .slider-content .path-preview .path-box .copy p {
  text-align: left;
  margin: 0;
  color: black;
}
.slider-container.onboarding .slider-content .path-preview .path-box .copy p.path-name {
  font-weight: bold;
}
.slider-container.onboarding .slider-content .path-preview img.bg {
  display: none;
  width: 100%;
}
.slider-container.onboarding .slider-content .path-preview img.bg-mobile {
  display: block;
  width: 100%;
}
.slider-container.onboarding .slider-content .pref-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box {
  height: 72px;
  width: 190px;
  background-color: white;
  border-radius: 36px;
  margin: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  padding: 13px;
  cursor: pointer;
  position: relative;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box p {
  color: black;
  text-align: center;
  margin: 0;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box input[type=checkbox],
.slider-container.onboarding .slider-content .pref-boxes .pref-box input[type=radio] {
  position: absolute;
  left: 12px;
  visibility: hidden;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box input[type=checkbox]:not(:checked),
.slider-container.onboarding .slider-content .pref-boxes .pref-box input[type=radio]:not(:checked) {
  visibility: hidden;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box.active {
  border-color: #1ea182;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box.active p {
  color: #1ea182;
  font-weight: bold;
}
.slider-container.onboarding .slider-content .pref-boxes.tall .pref-box,
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box {
  height: 144px;
  width: 144px;
  border-radius: 8px;
  flex-direction: column;
}
.slider-container.onboarding .slider-content .pref-boxes.tall .pref-box input[type=checkbox],
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box input[type=checkbox],
.slider-container.onboarding .slider-content .pref-boxes.tall .pref-box input[type=radio],
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box input[type=radio] {
  bottom: 8px;
  left: auto;
}
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box {
  height: 288px;
  justify-content: flex-start;
}
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box img {
  width: 66.7%;
}
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box p {
  color: black;
  font-weight: bold;
  margin-bottom: 8px;
}
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box p.small {
  font-size: 1rem;
  color: #9ba1b3;
  font-weight: normal;
}
.slider-container.onboarding .slider-content .pref-boxes.tallest .pref-box.active p {
  color: #1ea182;
}
.slider-container.onboarding .slider-content .pagination-glu {
  display: block;
  height: 1rem;
  width: 100%;
}
.slider-container.onboarding .slider-content .content-glu {
  height: 3rem;
  width: 100%;
}
.slider-container.onboarding .slider-content .step-circles {
  display: block;
  bottom: 1rem;
  min-width: 275px;
}
.slider-container.onboarding .slider-content .primary-btn,
.slider-container.onboarding .slider-content .secondary-btn {
  position: relative;
  min-width: 150px;
  display: inline-block;
  margin: 2rem auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 25px;
}
.slider-container.onboarding .slider-content .primary-btn.lowercase,
.slider-container.onboarding .slider-content .secondary-btn.lowercase {
  text-transform: none;
}
.slider-container.onboarding .slider-content .primary-btn {
  background-color: #4bb49b;
  color: white;
}
.slider-container.onboarding .slider-content .secondary-btn {
  background-color: white;
  color: #4bb49b;
}
.slider-container.onboarding .slider-content .circle.current,
.slider-container.onboarding .slider-content .circle.active {
  background-color: #1ea182;
}
.slider-container.onboarding .slider-content .circle {
  border-color: #1ea182;
}
.slider-container.onboarding .slider-content.inverse {
  background-color: #1ea182;
}
.slider-container.onboarding .slider-content.inverse h1,
.slider-container.onboarding .slider-content.inverse p,
.slider-container.onboarding .slider-content.inverse small {
  color: white;
}
.slider-container.onboarding .slider-content.inverse .icon {
  width: 75px;
}
.slider-container.onboarding .slider-content.inverse .circle.current,
.slider-container.onboarding .slider-content.inverse .circle.active {
  background-color: white;
}
.slider-container.onboarding .slider-content.inverse .circle {
  border-color: white;
}
.slider-container.onboarding .slider-content.inverse .primary-btn {
  background-color: white;
  color: #4bb49b;
}
.mt-auto {
  margin-top: auto !important;
}
.slide6-main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/***********************************
 Smaller than phone portrait View
************************************/
@media screen and (max-width: 500px) {
  .prefs-left {
    float: left;
    width: 28%;
  }
  .prefs-left img {
    width: 85px;
  }
  .prefs-right {
    float: left;
    width: 72%;
  }
  .pref-boxes.tallest .pref-box {
    border-radius: 8px;
    flex-direction: column;
    height: 100px !important;
    width: 100% !important;
    display: block !important;
  }
  .pref-boxes.tallest .pref-box p {
    text-align: left !important;
  }
}
/***********************************
 larger than phone view
************************************/
@media screen and (min-width: 768px) {
  .slider-container.onboarding .slider-content .path-preview .path-box {
    left: 5.6%;
    right: 5.6%;
    top: 15%;
    min-height: 32%;
  }
  .slider-container.onboarding .slider-content .path-preview img.bg {
    display: block;
  }
  .slider-container.onboarding .slider-content .path-preview img.bg-mobile {
    display: none;
  }
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box img {
  display: none;
}
.slider-container.onboarding .slider-content .pref-boxes .pref-box.active img {
  display: inline-block;
}
.backbtn {
  margin-top: -15px !important;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  .backbtn {
    margin-top: 0px;
  }
}
.progress-badge-labels + .form-container {
  padding: 0;
}
textarea.placeholder-gray::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #f0f0f6;
}
textarea.placeholder-gray:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #f0f0f6;
}
textarea.placeholder-gray::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #f0f0f6;
}
textarea.placeholder-gray:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #f0f0f6;
}
/*******************
STYLING FOR POWER UPS AND
POWER UPS VIEDOS PAGES
*******************/
.power-up-container .page-title.vid-view h1 {
  margin-top: 0;
}
.power-up-container .content-section-right {
  top: 0px;
}
.power-up-container .right-section-wrapper {
  margin-top: 1rem;
}
.power-up-container .right-section-wrapper p {
  color: #9ba1b3;
}
.power-up-container .right-section-wrapper .section-title {
  display: inline-block;
  font-weight: 600;
}
.power-up-container .pow-small {
  height: 26px;
  width: 40px;
  display: inline-block;
  vertical-align: text-bottom;
}
.power-up-container .pow-btn {
  width: 100%;
  margin: 0;
}
.power-up-container .pow-content {
  padding-top: 10px !important;
}
.power-up-container .pow-content .list-item {
  padding: 0 1rem 1rem 0;
  color: #9ba1b3;
  font-size: 1rem;
  font-weight: 100;
  max-width: 50%;
  width: 50%;
}
.powerup-form .secondary-title {
  font-weight: 500;
  margin-bottom: 1rem;
}
.powerup-form form .powerup-selection input {
  display: none;
}
.powerup-form form .powerup-selection input:checked + label {
  color: #1ea182;
  font-weight: 900;
}
.powerup-form form .powerup-selection label {
  text-align: center;
  font-weight: 200;
  cursor: pointer;
  padding: 0.5rem;
  margin: 4px;
}
.powerup-form form input {
  text-align: center;
  margin-top: 0.5rem !important;
}
.saved-list-container .action-list {
  top: 0;
  margin: 1rem 0;
}
.saved-list-container .action-list .md-button.special {
  padding: 8px 8px 8px 3rem;
  width: 300px;
}
.saved-list-container .action-list .md-button.special span {
  text-transform: uppercase;
  font-size: 0.8rem;
  vertical-align: middle;
  font-weight: 100;
}
.saved-list-container .shopping-list-notifications {
  background-color: #ffc411;
  border-radius: 100%;
  /* padding: 2px; */
  width: 24px;
  height: 24px;
  text-align: center;
  position: absolute;
  top: -12px;
  right: -12px;
  color: white;
  box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.7);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  animation: pop-in 400ms ease both;
}
.saved-list-container .shopping-list-notifications.active {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  animation: bubble-out 400ms ease both;
}
.saved-list-container .recipe-item .shopping-list-options {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding-top: 20%;
}
.saved-list-container .recipe-item .shopping-list-options .border-button {
  background-color: white;
  margin: 0.5rem;
  font-size: 0.85rem;
  padding: 10px 18px;
  box-shadow: 0 0 32px black;
}
.saved-list-container .recipe-item .shopping-list-options .border-button:hover {
  background-color: #1ea182;
}
.saved-list-container .recipe-item.show-shopping-list-options:hover .shopping-list-options {
  visibility: visible;
}
.saved-list-container .shopping-list-selector {
  color: #9ba1b3;
  text-transform: uppercase;
  font-weight: 200;
  display: inline-block;
  vertical-align: middle;
  padding-right: 0.5rem;
}
.saved-list-container .shopping-list-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-left: 2rem;
}
.saved-list-container .box-pointer {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  right: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}
.saved-list-container .shopping-list {
  position: absolute;
  top: 50px;
  max-width: 300px;
  width: 80vw;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(155, 155, 155, 0.7);
  border-radius: 4px;
  z-index: 100;
}
.saved-list-container .shopping-list .shopping-list-mobile-header {
  display: none;
}
.saved-list-container .shopping-list .shopping-list-frame {
  min-height: 240px;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}
.saved-list-container .shopping-list .shopping-list-page {
  margin: 0 1rem;
}
.saved-list-container .shopping-list .list-item {
  font-weight: 100;
  font-size: 1rem;
  position: relative;
  padding: 1rem 0;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}
.saved-list-container .shopping-list .list-item:first-child {
  border-top: none;
}
.saved-list-container .shopping-list .list-item .list-name {
  padding-right: 28px;
}
.saved-list-container .shopping-list .list-item .close-btn {
  width: 20px;
  height: 20px;
  top: 17px;
  opacity: 0.5;
  right: -4px;
}
.saved-list-container .shopping-list .md-button {
  margin: 0.5rem 0 1rem;
  border: none;
}
.saved-list-container .shopping-list .md-button:disabled {
  background-color: #9ba1b3;
}
.saved-list-container .shopping-list .clear-list {
  padding: 0 1rem 1rem;
}
.saved-list-container .page-tabs {
  padding: 0.5rem 0 0.5rem;
}
.saved-list-container .mission-dropshadow {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.saved-list-container #missions-tab {
  transition: all 400ms ease;
}
.saved-list-container.container-out {
  overflow: visible;
}
.saved-list-container .full-border-radius {
  border-radius: 8px;
}
.saved-list-container .saved-miss-width {
  width: 32%;
  float: left;
  display: inline-block;
  margin-top: 0.75rem;
}
.saved-list-container .saved-miss-width:nth-child(2n) {
  float: right;
}
.saved-list-container .unsave-modal-bottom-cells {
  display: inline-block;
  width: 49%;
  vertical-align: middle;
}
.saved-list-container .saved-num {
  color: white;
  background-color: #9ba1b3;
  padding: 2px 0;
  width: 28px;
  height: 28px;
  position: relative;
  top: -2px;
  display: inline-block;
  border-radius: 100%;
  text-decoration: none !important;
  cursor: default;
  margin-right: .5rem;
}
.saved-list-container .saved-num.active-num {
  background-color: #1ea182;
}
.saved-list-container .no-data-text {
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 3rem;
  color: black;
}
.saved-list-container .action-icon .selected {
  border: none;
}
/***********************************
 Smaller than tablet View
************************************/
@media screen and (max-width: 1023px) {
  .saved-list-container .saved-miss-width {
    width: 49%;
  }
}
/***********************************
 Smaller than phone View
************************************/
@media screen and (max-width: 768px) {
  .saved-list-container .saved-miss-width {
    width: 100%;
  }
  .saved-list-container .action-list {
    position: fixed;
    background-color: #ffc411;
    width: 100%;
    bottom: 0;
    top: auto;
    left: 0;
    height: 56px;
    text-align: center;
    margin: 0;
    padding-top: 0.5rem;
    z-index: 9999;
  }
  .saved-list-container .action-list .md-button.special:hover {
    background-color: #ffc411;
  }
  .saved-list-container .shopping-list-notifications {
    background-color: #1ea182;
  }
  .saved-list-container .shopping-list-selector {
    color: white;
  }
  .saved-list-container .box-pointer {
    display: none;
  }
  .saved-list-container .shopping-list {
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .saved-list-container .shopping-list .shopping-list-mobile-header {
    display: block;
    width: 100%;
    background-color: #1ea182;
    color: white;
  }
  .saved-list-container .shopping-list .shopping-list-mobile-header h2 {
    margin: 0;
    padding: 1rem;
    color: white;
  }
  .saved-list-container .shopping-list .shopping-list-mobile-header .close-btn {
    margin-top: 5px;
  }
}
/*hello world*/
.bio-pic-left {
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 170px !important;
  height: 170px !important;
  margin-right: 15px;
}
.inline-block p {
  font-size: 17px;
}
.img-t-t-coach {
  padding: 0px 5px 16px 4% !important;
}
.coach-page-title {
  margin-top: 3% !important;
}
.coach-page-text {
  font-size: 17px;
  color: #9ba1b3;
  width: 100%;
  display: block;
  margin: 10px 0px;
}
/***********************************
 Smaller than phone View
************************************/
@media screen and (max-width: 768px) {
  .bio-pic-left {
    width: 40% !important;
    height: 134px !important;
  }
  /*.inline-block {
    width: 50%;
  }*/
}
.essentials-steps-container {
  overflow: visible;
}
.essentials-steps-container .page-title h1 {
  margin-top: 0;
  font-weight: 400;
}
.essentials-steps-container .p-title-ess {
  margin-top: 5% !important;
}
.essentials-steps-container .p-title-desc p {
  font-size: 1.25rem;
}
.essentials-steps-container .essentials-arrow-holder {
  height: 48px;
  width: 48px;
  padding: 8px;
  position: relative;
}
.essentials-steps-container .essentials-arrow-holder .ess-arrow {
  position: relative;
  top: -8px;
  width: 32px !important;
  height: 32px !important;
}
.essentials-steps-container .ess-back {
  left: 0;
}
.essentials-steps-container .step-circles {
  margin: 2.5rem auto;
}
.essentials-steps-container .step {
  margin-top: 48px;
}
.essentials-steps-container .scroll-arrow {
  position: relative;
  top: 200px;
  font-size: 40px;
  outline: 0;
  z-index: 10;
  max-height: 80px;
}
.essentials-steps-container .scroll-arrow.right {
  text-align: left;
}
.essentials-steps-container .scroll-arrow.left {
  text-align: right;
}
.essentials-steps-container .steps-tab-wrapper {
  position: relative;
}
.essentials-steps-container .steps-tab-wrapper .steps-container {
  display: flex;
  width: 100%;
}
.essentials-steps-container .steps-tab-wrapper .step-content {
  position: absolute;
  padding: 0 15px;
  transition: all 0.4s ease;
  transform: translate(0%);
  opacity: 1;
}
.essentials-steps-container .steps-tab-wrapper .step-content.step-hide {
  opacity: 0;
}
.essentials-steps-container .steps-tab-wrapper .step-content.left {
  transform: translate(-100%);
}
.essentials-steps-container .steps-tab-wrapper .step-content.right {
  transform: translate(100%);
}
.essentials-steps-container .steps-tab-wrapper .step-content .primary-btn {
  position: relative;
  left: -8px;
}
.essentials-steps-container .steps-tab-wrapper .step-title {
  font-size: 2.5rem;
  text-align: center;
  color: #1ea182;
  margin-bottom: 2.5rem;
}
.essentials-steps-container .steps-tab-wrapper .step-text {
  text-align: left;
  padding: 0px 0px 20px;
  font-size: 20px;
  display: inline-block;
  width: 65%;
}
.essentials-steps-container .steps-tab-wrapper .step-text.just-text {
  text-align: left;
  padding: 0px;
  margin: auto;
  font-size: 20px;
  display: block;
  width: 70%;
  float: none !important;
}
.essentials-steps-container .steps-tab-wrapper .step-text.fleft {
  float: left;
}
.essentials-steps-container .steps-tab-wrapper .step-text.fright {
  float: right;
}
.essentials-steps-container .steps-tab-wrapper .img-disp {
  width: 30%;
  display: inline-block;
}
.essentials-steps-container .steps-tab-wrapper .img-disp .step-img {
  width: 100%;
  padding-bottom: 100%;
  display: inline-block;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.essentials-steps-container .steps-tab-wrapper .img-disp.fleft {
  float: left;
  margin: 0 2rem 1rem 0;
}
.essentials-steps-container .steps-tab-wrapper .img-disp.fright {
  float: right;
  margin: 0 0 1rem 2rem;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-container {
  width: 65%;
  display: inline-block;
  margin-bottom: 2rem;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-container.just-text {
  text-align: left;
  margin: 10px auto 0px;
  font-size: 20px;
  /* display: inline-block; */
  width: 70%;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-container.fleft {
  float: left;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-container.fright {
  float: right;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #1ea182;
}
.essentials-steps-container .steps-tab-wrapper .bot-left-text {
  font-size: 20px;
}
.essentials-steps-container .steps-tab-wrapper .step-btn {
  border-radius: 7px 7px 7px 7px;
  text-align: center;
  background-color: #ffc411;
  color: white;
  display: block;
  width: 40%;
  margin: auto;
  margin: 10px auto;
}
.essentials-steps-container .steps-tab-wrapper .shop-list {
  width: 32%;
  display: inline-block;
}
.essentials-steps-container .steps-tab-wrapper .shop-list .shop-img {
  width: 95%;
  height: 185px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin: 40px auto 0px;
}
.essentials-steps-container .steps-tab-wrapper .shop-list .shop-text {
  display: block;
  width: 95%;
  text-align: left;
  margin: 10px auto;
}
.essentials-steps-container .steps-tab-wrapper .shop-list .shop-btn {
  border-radius: 7px 7px 7px 7px;
  text-align: center;
  background-color: #ffc411;
  color: white;
  display: block;
  width: 70%;
  margin: auto;
  margin: 10px auto;
}
@media screen and (max-width: 1023px) {
  .essentials-steps-container .scroll-arrow {
    position: absolute;
    top: 75px;
    z-index: 1000;
  }
  .essentials-steps-container .scroll-arrow.left {
    left: -10px;
  }
  .essentials-steps-container .scroll-arrow.right {
    right: -10px;
  }
  .essentials-steps-container .essentials-arrow-holder {
    background-color: #ffffff;
  }
  .step {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .essentials-steps-container .scroll-arrow {
    top: 50px;
  }
  .essentials-steps-container .essentials-arrow-holder {
    height: 38px;
    width: 38px;
  }
  .essentials-steps-container .essentials-arrow-holder .ess-arrow {
    top: -18px;
    width: 22px !important;
    height: 22px !important;
  }
  .essentials-steps-container .steps-tab-wrapper .step-content {
    max-width: 100%;
    left: 7px;
  }
  .essentials-steps-container .steps-tab-wrapper .step-title {
    margin: auto;
    margin-bottom: 1.5rem;
    display: block;
    width: 75%;
    font-size: 1.5rem;
  }
  .essentials-steps-container .steps-tab-wrapper .step-text {
    width: 100%;
  }
  .essentials-steps-container .steps-tab-wrapper .bot-left-container {
    width: 100%;
  }
  .essentials-steps-container .steps-tab-wrapper .img-disp {
    float: none !important;
    margin: 0 !important;
    width: 100%;
  }
  .essentials-steps-container .steps-tab-wrapper .img-disp.left {
    margin: 0;
  }
  .essentials-steps-container .steps-tab-wrapper .img-disp.right {
    margin: 0;
  }
  .essentials-steps-container .steps-tab-wrapper .img-disp .step-img {
    display: block;
    margin: auto;
    margin-bottom: 1.5rem;
  }
}
.mission-main-holder {
  padding-bottom: 25px;
}
.mission-main-holder .col-md-6 {
  margin-bottom: 20px;
}
.mission-main-holder .mission-container {
  position: relative;
  width: 100%;
  border: 1px solid #f0f0f6;
  border-radius: 8px;
  background-color: white;
}
.mission-main-holder .mission-container.back-side {
  height: 50%;
  font-size: 1rem;
  padding: 15px;
}
.mission-main-holder .mission-container.home-page {
  border: none;
}
.mission-main-holder .mission-container.home-challenge {
  padding-bottom: 68% !important;
}
.mission-main-holder .mission-container.home-quiz {
  padding-bottom: 5%;
}
.mission-main-holder .mission-back-title {
  font-size: 1.25rem;
}
.mission-main-holder .mission-background-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 8px 8px 0px 0px;
}
.mission-main-holder .mission-background-img.challenge-promo-img {
  padding-bottom: 0%;
  height: 100%;
  border-radius: 8px 8px 8px 8px;
}
.mission-main-holder .transparent-badge-displayer {
  background-color: rgba(255, 255, 255, 0.47);
  border-radius: 8px 0px 0px 8px;
  padding: 1.25% 0%;
  position: absolute;
  right: 0;
  top: 15px;
}
.mission-main-holder .transparent-badge-displayer .badge-row {
  width: 100%;
  position: relative;
}
.mission-main-holder .mission-circular-badge-icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 40.25px;
  padding-bottom: 40.25px;
  margin: 0px 0px 0px 10px;
  display: inline-block;
  position: relative;
}
.mission-main-holder .mission-circular-badge-icon.home-page {
  width: 65%;
  padding-bottom: 65%;
}
.mission-main-holder .mission-circular-badge-icon:last-child {
  margin: 0px 10px 0px 10px;
}
.mission-main-holder .mission-desc {
  width: 70%;
  /* position: absolute; */
  /* left: 0; */
  /* bottom: 0; */
  /* margin-bottom: 5px; */
  /* margin-left: 10px; */
  padding-top: 56.25%;
  padding-bottom: 2.5%;
  padding-left: 2.5%;
}
.mission-main-holder .mission-desc h3 {
  display: block;
  color: black;
  margin: 2.5% 0 0 0;
}
.mission-main-holder .mission-desc p {
  display: block;
  color: #9ba1b3;
  font-weight: 300;
  margin: 0;
}
.mission-main-holder .missions-ttw {
  width: 100% !important;
  display: block;
  position: relative;
  margin-top: 5%;
}
.mission-main-holder .missions-iatw {
  width: 21%;
  text-align: center;
  /* font-size: 12px; */
  border: none;
  /* margin-right: 1.25%; */
  position: relative;
}
.mission-main-holder .missions-iatw p {
  color: #1ea182;
  min-height: 20px !important;
}
.mission-main-holder .missions-iatw.home-page-iatw {
  width: 16%;
  bottom: 0px;
}
.mission-main-holder .missions-iatw:last-child {
  float: right;
}
.mission-main-holder .missions-tphi {
  width: 40% !important;
  padding-bottom: 40% !important;
  margin: auto;
}
/*.flip-container {
   perspective: 1000px;
}
// .flip-container:hover .flipper
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;

  // &.mission-front {
  //   padding-bottom: 71%;
  // }
  //
  // &.mission-back {
  //   padding-bottom: 37%;
  // }
  // &.challenge-front {
  //   padding-bottom: 80%;
  // }
  // &.challenge-back {
  //   padding-bottom: 108%;
  // }
  // &.quiz-front {
  //   padding-bottom: 30%;
  // }
  // &.quiz-back {
  //   padding-bottom: 22%;
  // }
//padding-bottom: 57%;
//height: 0px;
}

.flipper {
transition: 0.6s;
transform-style: preserve-3d;

position: relative;

  &.home-page {
    margin-left: 2%;
    margin-right: 2%;
  }
}

.front, .back {
backface-visibility: hidden;

// position: absolute;
// top: 0;
// left: 0;
}

.front {
z-index: 2;
transform: rotateY(0deg);
}

.back {
transform: rotateY(180deg);
}

.dont-show {
  display: none !important;
}

.flip-arrow {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  z-index: 10;
  width: 35px;
  padding-bottom: 35px;
  background-image: url(../imgs/flip-arrow.svg);
  outline: 0;
  margin: 0px 2.5% 2.5% 0px;
  bottom: 0;
  right: 0;

  &.back-side {
    bottom: auto;
    top: 10px;
    right: 0px;
    width: 35px;
    padding-bottom: 35px;
  }
}
*/
/**********************************
 * Flip Container CSS - Thank you David Walsh for IE friendly CSS
 **********************************/
.flip-container {
  perspective: 1000px;
  transform-style: preserve-3d;
  /* hide back of pane during swap */
  /* IE Friendly Flipping */
  /* Generic Flipper Content */
}
.flip-container.widget {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.flip-container .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.flip-container .front,
.flip-container .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.flip-container .front .container-out,
.flip-container .back .container-out {
  margin: 0;
}
.flip-container.widget .front,
.flip-container.widget .back {
  height: 100vh;
}
.flip-container.widget.flipped .front {
  display: none;
}
.flip-container.flipped .front {
  transform: rotateY(180deg);
}
.flip-container.flipped .back {
  transform: rotateY(0deg);
}
.flip-container .front {
  z-index: 2;
  transform: rotateY(0deg);
}
.flip-container .back {
  transform: rotateY(-180deg);
}
.flip-container .flip-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
.flip-container .flip-img.widget {
  height: calc(100vh - 81px);
}
.flip-container .flip-title {
  width: 85%;
  display: inline-block;
  margin: 0;
}
.flip-container .flip-title h2 {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  margin: 0;
}
.flip-container .flip-title h3 {
  margin: 0;
}
.flip-container .flip-title .main-heading {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.flip-container .flip-heading {
  margin: 0;
}
.flip-container .flip-icon {
  display: inline-block;
  /*float: right;
    position: relative;
    top: 9px;*/
  position: absolute;
  top: 1.65rem;
  right: 0.75rem;
  z-index: 3;
}
.flip-container .flip-icon img {
  height: 30px;
}
.flip-container .container-in {
  position: relative;
  padding: 1rem;
  margin: 0;
}
.flip-container .back-title {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 400;
}
.flip-container .body-content {
  margin: 1.5rem 0 1rem;
}
.flip-container .body-content p {
  padding-bottom: .5rem;
}
/***********************************************
  AWARDS
***********************************************/
@keyframes awards-pop-in {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes awards-pop-out {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(0.1);
  }
}
.completed-animation::-webkit-scrollbar {
  display: none;
}
.completed-animation {
  height: 100vh;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #ffc411;
  margin-top: 0;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 4000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 400ms ease;
}
.completed-animation.active {
  visibility: visible;
  opacity: 1;
}
.completed-animation .ugc-post-tools {
  position: relative;
  z-index: 10;
  top: 200px;
  margin: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 800ms ease;
  max-width: 400px;
  display: inline-block;
}
.completed-animation .ugc-post-tools.active {
  opacity: 1;
  visibility: visible;
}
.completed-animation .ugc-post-tools p {
  text-shadow: 0px 0px 32px #ffc411;
}
.completed-animation .ugc-post-tools button {
  text-transform: uppercase;
  min-width: 160px;
  margin-bottom: 0.5rem;
}
.completed-animation.join-challenge .completed-animation-circle {
  display: none;
}
.completed-animation.join-challenge .completed-animation-circle.active {
  display: block;
}
.completed-animation .add-level {
  background-image: url('../imgs/AWESOME_SAUCE.svg');
  background-color: #ffbe00;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 40vw;
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  border-radius: 0;
  top: 0;
}
.completed-animation .add-level .scalable-level-copy {
  margin-top: 1rem;
}
.completed-animation .add-level .scalable-level-copy h1 {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  font-weight: 100;
  text-transform: none;
  letter-spacing: normal;
  color: #fff;
  font-size: 13vw;
  max-width: 80vw;
  text-align: center;
  line-height: 12vw;
  margin: 6vw auto;
}
.completed-animation .add-level .scalable-level-copy p {
  width: 100%;
  max-width: 50vw;
  margin: auto;
  font-size: 4vw;
  font-weight: 100;
  margin-bottom: 4vw;
}
.completed-animation .add-level .scalable-level-copy h2 {
  font: 400 1rem sofia-pro-soft, Verdana, Geneva, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: normal;
  width: 50vw;
  margin: auto;
  font-size: 8vw;
  font-weight: 300;
  color: #fff;
}
.completed-animation .add-action-not-allowed {
  position: absolute;
}
.completed-animation .add-action-not-allowed h2 {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
}
.completed-animation .add-level,
.completed-animation .add-action-not-allowed {
  opacity: 0;
  visibility: hidden;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  transition: opacity 400ms ease;
}
.completed-animation .add-level div,
.completed-animation .add-action-not-allowed div {
  margin: auto;
}
.completed-animation .add-level.active,
.completed-animation .add-action-not-allowed.active {
  visibility: visible;
  opacity: 1;
  /*animation-name: awards-pop-in;
        animation-duration: 500ms;*/
}
.completed-animation .add-level.pop-out,
.completed-animation .add-action-not-allowed.pop-out {
  opacity: 0;
  /*animation-name: awards-pop-out;
        animation-duration: 300ms;*/
}
.completed-animation-circle {
  width: 200px;
  height: 200px;
  position: relative;
  top: -200px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 800ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.completed-animation-circle.active {
  transform: translate(0, 50%);
  top: 0;
}
.completed-animation-circle .welcome-to-team {
  transform: translateY(-50%);
}
.completed-animation-circle p {
  color: #ffc411;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  font-size: 1.75rem;
  font-weight: 900;
  top: 47%;
  margin: 0;
  transform: translateY(-50%);
}
.completed-animation-elements {
  background-image: url('../imgs/completed_elements.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 420px;
  height: 500px;
  position: absolute;
  top: 90px;
  left: 90px;
  z-index: -1;
  opacity: 0;
  transition: opacity 1600ms ease;
}
.completed-animation-elements.active {
  animation-delay: 1200ms;
  animation: explode-and-spin 1600ms cubic-bezier(0.075, 0.82, 0.165, 1) both;
  opacity: 1;
}
/*.level-up-animation-circle {
  width: 200px;
  height: 200px;
  position: absolute;
  transform: translate(-50%, 50%) scale(4);
  top: 0;
  left: 50%;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 600ms cubic-bezier(0.770, 0.005, 0.690, 1.365);
  opacity: 0;

  &.level-up-image {
    background-image: url('../imgs/Level_Up_Circle.svg');
  }

  &.active {
    transform: translate(-50%, 50%) scale(1);
    opacity: 1;
  }
  .welcome-to-team {
    transform: translateY(-50%);
  }

  p {
    color: @tertiary-color;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 200px;
    font-size: 2rem;
    font-weight: 900;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
  }
}*/
.ugc-image-upload-wrapper {
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.ugc-image-upload-wrapper .secondary-btn {
  border: none;
}
.ugc-image-upload-wrapper button {
  text-transform: uppercase;
  min-width: 150px;
}
.ugc-image-upload-wrapper > input[type="file"] {
  position: absolute;
  top: -40px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.ugc-image-upload-wrapper:hover > input[type="file"] {
  cursor: pointer;
}
.elements-explosion {
  background-image: url(../imgs/completed_elements.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: 5001;
  position: fixed;
  width: 75%;
  height: 75%;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  text-align: center;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.elements-explosion.active {
  animation: explode-and-spin 1600ms cubic-bezier(0.18, 0.89, 0.32, 1.28) both;
  transform-origin: 50% 50%;
  opacity: 1;
  visibility: visible;
}
#awards {
  position: relative;
  z-index: 10;
  top: 80px;
  transition: opacity 400ms ease;
  opacity: 0;
  visibility: hidden;
}
#awards.active {
  opacity: 1;
  visibility: visible;
}
#awards .badge-item {
  display: inline-block !important;
  position: relative;
  margin: 0 1rem 2rem !important;
  vertical-align: top;
  opacity: 0;
  transition: all 400ms ease;
}
#awards .badge-item.show {
  opacity: 1;
  /*animation-name: pop-in;
      animation-duration: 400ms;*/
  animation: pop-in 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
#awards .badge-item p {
  margin-top: 0.5rem;
}
#awards .badge-item .badge-placeholder {
  width: 100px;
  height: 100px;
}
#awards .badge-item .experience-icon {
  position: relative !important;
}
#awards .badge-item .experience-icon-clone {
  position: absolute;
  top: 0;
  left: 0;
}
#awards .badge-item .experience-points {
  transition: all .5s ease;
}
#awards .badge-item .skillbox {
  background-color: #FFF;
  border-radius: 100%;
  border: 2px solid #FFF;
  z-index: 0;
}
#awards .badge-item .skillbox .progress-circle.aspect-content {
  left: -1px !important;
}
#awards .badge-item .skillbox .progress-circle svg {
  border-radius: 100%;
}
#awards .badge-item .skillbox .progress-circle svg #bg {
  stroke: #1ea182 !important;
  opacity: 0.3;
}
#awards .badge-item .skillbox .progress-circle > svg > circle {
  stroke: #1ea182;
  stroke-width: 16.25% !important;
  transition: all .5s ease;
}
.awards-background {
  transition: all .25s ease;
  position: fixed;
  background-color: #000;
  opacity: 0.6;
  margin-top: 58px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3999;
}
.awards-background-header {
  position: fixed;
  top: 0;
  height: 58px;
  left: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.01;
  z-index: 5500;
}
@media screen and (min-width: 1023px) {
  .completed-animation .add-level {
    background-position: 0 10vw;
    background-size: 70%;
  }
  .completed-animation .add-level .scalable-level-copy {
    transform: scale(0.5);
    position: relative;
    top: -70px;
  }
}
.custome-list {
  background: #dfecf6;
  padding: 1rem;
  border-radius: 8px;
}
.custome-list ul {
  padding: 0;
  margin: 0;
}
.custome-list ul li {
  position: relative;
  padding-left: 15px;
  list-style-type: none;
}
.custome-list ul li:after {
  content: '*';
  width: 5px;
  height: 5px;
  display: block;
  color: #1ea182;
  position: absolute;
  left: -2px;
  top: -1px;
  font-size: 1.5rem;
  font-weight: bold;
}
.color-1 {
  color: #1ea182;
}
.color-2 {
  color: #792304;
}
.color-3 {
  color: #af8432;
}
.color-4 {
  color: #de7c04;
}
.color-5 {
  color: #576c31;
}
.color-6 {
  color: #f6884b;
}
.color-7 {
  color: #ff0000;
}
.color-8 {
  color: #3044d3;
}
.color-9 {
  color: #10c548;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction: row;
}
.expanded-recipe .my-profile-container,
.guided-meals-container .my-profile-container {
  width: 100%;
  background-color: #dfecf6;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.expanded-recipe .my-profile-container .upload,
.guided-meals-container .my-profile-container .upload {
  padding: 0;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.expanded-recipe .my-profile-container .profile-image,
.guided-meals-container .my-profile-container .profile-image {
  width: 50px;
  height: 50px;
}
.expanded-recipe .my-profile-container .copy,
.guided-meals-container .my-profile-container .copy {
  width: 150px;
}
.expanded-recipe .my-profile-container .copy h4,
.guided-meals-container .my-profile-container .copy h4 {
  margin: 0;
}
.expanded-recipe .my-profile-container .copy p,
.guided-meals-container .my-profile-container .copy p {
  margin: 8px 0;
}
a.button-length-link-padding.primary-btn.md-button.backbtn {
  position: absolute;
  left: 0;
  min-width: auto;
  padding: 16px 19px;
}
